package ch.randm.playsit.frontend.admin.page

import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.frontend.Admin
import ch.randm.playsit.frontend.admin.State
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement.Base
import com.raquo.waypoint.Router

case class DashboardPage($state: Var[State]) extends AuthedPage with LinkedPage {

  override val path: String  = ""
  override val title: String = "Dashboard"
  override val icon: String  = "speedometer"

  override protected def authedRender(token: Token, router: Router[Page]): Base =
    div(
      className := "container",
      div(
        cls := "row",
        div(
          cls := "card text-center col-4 mb-3",
          div(
            cls := "card-body",
            h5(cls := "card-title", "Users"),
            p(cls  := "card-text", "2 since xx"),
            a(
              href := "#",
              cls  := "btn btn-primary",
              onClick.preventDefault.mapToUnit --> { _ => router.pushState(Admin.pages.users) },
              "Manage"
            )
          )
        )
      )
    )

}
