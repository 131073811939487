package ch.randm.playsit.core.model

import cats.Show
import cats.data.Ior
import cats.implicits.toShow

package object common {

  type Persisted[+A] = Ior[Identifier, A]

  object Persisted {
    def apply[A](id: Identifier): Persisted[A]       = Ior.left[Identifier, A](id)
    def apply[A](id: Identifier, a: A): Persisted[A] = Ior.both(id, a)
    def apply[A](a: A): Persisted[A]                 = Ior.right[Identifier, A](a)

    implicit def show[A: Show]: Show[Persisted[A]] = p => s"${p.map(_.show).getOrElse(p.id.show)}"
  }

  implicit class PersistedOps[A](p: Persisted[A]) {

    def id: Identifier =
      p match {
        case Ior.Left(id)    => id
        case Ior.Both(id, _) => id
        case _               => throw new NoSuchElementException("Persisted.Right.id")
      }

    def get: A =
      p match {
        case Ior.Both(_, v) => v
        case Ior.Right(v)   => v
        case _              => throw new NoSuchElementException("Persisted.Left.get")
      }

    def getOrElse(default: A): A =
      p match {
        case Ior.Both(_, v) => v
        case Ior.Right(v)   => v
        case _              => default
      }

    def load[B <: A](a: B): Persisted[A] =
      p match {
        case Ior.Left(id)    => Ior.both(id, a)
        case Ior.Both(id, _) => Ior.both(id, a)
        case _               => Ior.right(a)
      }

    def print(implicit ev: Show[A]): String = Persisted.show[A].show(p)

  }

}
