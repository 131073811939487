package ch.randm.playsit.frontend.util

import cats.implicits.toBifunctorOps
import ch.randm.playsit.core.error.Error
import ch.randm.playsit.core.error.Error.{NotFoundError, ParseError}
import io.circe._
import io.circe.syntax._

object StorageUtil {

  private lazy val storage = org.scalajs.dom.window.localStorage

  def set[A: Encoder](key: String, a: A): Unit = storage.setItem(key, a.asJson.noSpaces)

  def get[A: Decoder](key: String): Either[Error, A] =
    for {
      str  <- Option(storage.getItem(key)).toRight(NotFoundError("LocalStorage item", "key", key))
      json <- parser.parse(str).leftMap(ParseError).leftWiden[Error]
      item <- json.as[A].leftMap(ParseError).leftWiden[Error]
    } yield item

  def delete(key: String): Unit = storage.removeItem(key)

  def clear(): Unit = storage.clear()

}
