package ch.randm.playsit.frontend.component

import cats.implicits.{catsSyntaxOptionId, toShow}
import ch.randm.playsit.core.model.common.Persisted
import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.core.service.AuthorizationService.{Authorization, Operation, Permission, Role}
import ch.randm.playsit.core.util.ClassName
import ch.randm.playsit.frontend.ajax.ProxyFetchStream
import ch.randm.playsit.frontend.util.AjaxUtil
import ch.randm.playsit.json._
import ch.randm.playsit.routing.AuthorizationApi
import com.raquo.laminar.api.L._
import com.raquo.laminar.codecs.StringAsIsCodec
import com.raquo.laminar.nodes.ReactiveHtmlElement
import io.circe.generic.auto._
import routing._

case class PermissionsComponent[A: ClassName](token: Token, selected: Signal[Option[Persisted[A]]]) {

  private val voteBus: EventBus[(Call, Permission)] = new EventBus[(Call, Permission)]

  def render: ReactiveHtmlElement.Base =
    div(
      cls := "mb-3 flex flex-cold",
      button(
        cls                   := "btn btn-outline-secondary",
        typ                   := "button",
        dataAttr("bs-toggle") := "collapse",
        dataAttr("bs-target") := "#permissionCollapse",
        aria.expanded         := true,
        aria.controls         := "permissionCollapse",
        "Permission control"
      ),
      div(
        idAttr                := "permissionCollapse",
        cls                   := "collapse card card-body table-responsive-sm mt-3 ps-0 pe-0",
        table(
          cls := "table table-hover",
          thead(
            tr(
              th(htmlAttr("scope", StringAsIsCodec) := "col", nbsp),
              children <-- selected.map { selected =>
                val targetId = selected.map(_.id)
                Operation.viable(targetId.isDefined)
                  .map(_.toString)
                  .map(th(htmlAttr("scope", StringAsIsCodec) := "col", cls := "text-center", width.px := 100, _))
              }
            )
          ),
          tbody(
            children <-- selected.flatMap { selected =>
              val targetId = selected.map(_.id)
              ProxyFetchStream.expect[List[Authorization]](
                AuthorizationApi.Permissions(ClassName[A], targetId),
                token.some
              ).map(_.groupBy(_.permission.role).map((authorizationElement _).tupled).toSeq)
            }
          ),
          voteBus.events.flatMap {
            case (call, permission) =>
              AjaxUtil.recoverWithToast(ProxyFetchStream.send(call, permission, token.some).mapTo(Toast(
                "success",
                "Success!",
                "Permission saved"
              )))
          } --> Toast.observer
        )
      )
    )

  private def authorizationElement(role: Persisted[Role], auths: List[Authorization]) =
    tr(
      td(role.get.show),
      auths.map { a =>
        td(
          cls := "text-center",
          input(
            cls     := "form-check-input me-1",
            typ     := "checkbox",
            value   := role.id.value.toString,
            idAttr  := s"role${role.get.name}",
            checked := a.isAuthorized,
            onChange.mapToChecked.map(b =>
              (if (b) AuthorizationApi.Grant() else AuthorizationApi.Deny()) -> a.permission
            ) --> voteBus.writer
          )
        )
      }
    )

}
