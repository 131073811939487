package ch.randm.playsit.core.model

import cats.Show
import cats.data.ValidatedNel
import cats.implicits.toFunctorOps
import ch.randm.playsit.core.error.ValidationError
import ch.randm.playsit.core.model.ElectronicAddress.ElectronicAddressType
import ch.randm.playsit.core.model.ElectronicAddress.ElectronicAddressType.Email
import ch.randm.playsit.core.model.common.Validated

/** An electronic address defines a way to reach a [[common.Person]] or [[Venue]] through online media. That may be an
  * email address, social media channel, website and so on.
  */
case class ElectronicAddress(
    addressType: ElectronicAddressType,
    value: String
) extends Validated[ElectronicAddress] {

  override def validate: ValidatedNel[ValidationError, ElectronicAddress] =
    addressType match {
      case Email => validEmail(value).as(this)
      case _     => notEmpty(value, "address").as(this)
    }

}

object ElectronicAddress {

  /** Enum that is used to define what type of address an [[ElectronicAddress]] is.
    */
  sealed trait ElectronicAddressType

  object ElectronicAddressType {
    final case object Phone     extends ElectronicAddressType
    final case object Fax       extends ElectronicAddressType
    final case object Email     extends ElectronicAddressType
    final case object Twitter   extends ElectronicAddressType
    final case object Instagram extends ElectronicAddressType
    final case object Facebook  extends ElectronicAddressType
    final case object Website   extends ElectronicAddressType
    final case object Apple     extends ElectronicAddressType
    final case object Spotify   extends ElectronicAddressType
    final case object YouTube   extends ElectronicAddressType

    val values: Seq[ElectronicAddressType] =
      Seq(Phone, Fax, Email, Twitter, Instagram, Facebook, Website, Apple, Spotify, YouTube)

    def byName(s: String): Option[ElectronicAddressType] = values.find(_.toString == s)
  }

  implicit val show: Show[ElectronicAddress] = e => s"${e.value} (${e.addressType})"

}
