package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.Persisted

/** Used to inform the customer of a possibility to buy tickets for an [[Engagement]].
  *
  * @param amount
  *   Price of the ticket
  * @param currency
  *   Currency of the price
  * @param salePoint
  *   Where the ticket can be bought (online or offline)
  */
case class TicketSale(
    amount: Double,
    currency: TicketSale.Currency,
    salePoint: Either[Persisted[Venue], Persisted[ElectronicAddress]]
)

object TicketSale {

  case class Currency(code: String)

  implicit val show: Show[TicketSale] = t =>
    s"${t.currency.code} ${"%.2f".format(t.amount).replace(".00", ".--")}, ${t.salePoint.fold(_.print, _.print)}"

}
