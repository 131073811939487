package ch.randm.playsit.frontend

import ch.randm.playsit.core.model._
import ch.randm.playsit.core.service.AuthorizationService.{Role, User}
import ch.randm.playsit.frontend.admin.State
import ch.randm.playsit.frontend.admin.form.implicits._
import ch.randm.playsit.frontend.admin.page.error._
import ch.randm.playsit.frontend.admin.page.{CRUDPage, DashboardPage, LoginPage, Page}
import ch.randm.playsit.frontend.ajax.ProxyFetchStream
import ch.randm.playsit.frontend.component.Toast
import ch.randm.playsit.frontend.util.{AjaxUtil, StorageUtil}
import ch.randm.playsit.json._
import ch.randm.playsit.routing.AuthenticationApi
import com.raquo.laminar.api.L._
import com.raquo.waypoint._
import org.scalajs.dom
import formula.DeriveForm.gen
import io.circe.generic.auto._

object Admin {

  private val BasePath        = "/admin"
  private val StorageKeyState = "state"

  private val $state: Var[State] = Var(StorageUtil.get[State](StorageKeyState).getOrElse(State()))

  $state.signal.foreach(StorageUtil.set(StorageKeyState, _))(unsafeWindowOwner)

  private[frontend] object pages {

    val error = Map(
      404 -> _404($state)
    )

    val login     = LoginPage($state)
    val dashboard = DashboardPage($state)

    val roles               = CRUDPage[Role]("people", $state)
    val users               = CRUDPage[User]("person", $state)
    val assets              = CRUDPage[Asset]("file-binary", $state)
    val addresses           = CRUDPage[Address]("house", $state)
    val electronicAddresses = CRUDPage[ElectronicAddress]("link", $state)
    val contacts            = CRUDPage[Contact]("chat-heart", $state)
    val subscribers         = CRUDPage[Subscriber]("envelope-paper-heart", $state)
    val promoters           = CRUDPage[Promoter]("person-vcard", $state)
    val venues              = CRUDPage[Venue]("buildings", $state)
    val ticketSales         = CRUDPage[TicketSale]("ticket-perforated", $state)
    val bandMembers         = CRUDPage[BandMember]("person-badge", $state)
    val artists             = CRUDPage[Artist]("music-note-list", $state)
    val engagements         = CRUDPage[Engagement]("boombox", $state)
    val songs               = CRUDPage[Song]("file-earmark-music", $state)
    val records             = CRUDPage[Record]("vinyl", $state)
    val dynamicContent      = CRUDPage[DynamicContent]("body-text", $state)

    val user     = roles :: users :: Nil
    val data     = assets :: addresses :: electronicAddresses :: artists :: contacts :: dynamicContent :: Nil
    val people   = promoters :: bandMembers :: subscribers :: Nil
    val gigs     = venues :: ticketSales :: engagements :: Nil
    val releases = songs :: records :: Nil
    val all      = login :: dashboard :: user ::: data ::: people ::: releases ::: gigs

  }

  private val router = new Router[Page](
    routes = pages.all.map(p =>
      Route.static(
        p,
        root / Option(p.path).filter(_.nonEmpty).map(_ / endOfSegments).getOrElse(endOfSegments),
        BasePath
      )
    ),
    serializePage = _.path,
    deserializePage = p => pages.all.find(_.path == p).get,
    getPageTitle = p => s"playsit – Admin – ${p.title}",
    routeFallback = _ => pages.error(404)
  )(
    popStateEvents = windowEvents(_.onPopState),
    owner = unsafeWindowOwner
  )

  private val rootElement = div(
    cls := "container-fluid p-0",
    child.maybe <-- $state.signal.map(_.validUser { user =>
      Option(
        headerTag(
          cls := "bg-body-tertiary mb-5",
          navTag(
            cls := "navbar navbar-expand-lg bd-navbar container-xxl sticky-top px-2",
            a(
              cls                   := "navbar-brand",
              href                  := BasePath,
              img(src := "/public/img/logo.png", width.px := 30)
            ),
            button(
              cls                   := "navbar-toggler",
              typ                   := "button",
              dataAttr("bs-toggle") := "collapse",
              dataAttr("bs-target") := "#navbarContent",
              aria.controls         := "navbarContent",
              aria.expanded         := false,
              aria.label            := "Toggle navigation",
              span(className := "navbar-toggler-icon")
            ),
            div(
              cls                   := "collapse navbar-collapse",
              idAttr                := "navbarContent",
              ul(
                cls := "navbar-nav me-auto",
                pages.dashboard.link(router),
                li(
                  cls := "nav-item dropdown",
                  a(
                    cls                   := "nav-link dropdown-toggle",
                    dataAttr("bs-toggle") := "dropdown",
                    href                  := "#",
                    role                  := "button",
                    aria.expanded         := false,
                    i(cls := "bi-people"),
                    nbsp,
                    "User Management"
                  ),
                  ul(
                    cls                   := "dropdown-menu",
                    pages.user.map(_.dropdown(router))
                  )
                ),
                li(
                  cls := "nav-item dropdown",
                  a(
                    cls                   := "nav-link dropdown-toggle",
                    dataAttr("bs-toggle") := "dropdown",
                    href                  := "#",
                    role                  := "button",
                    aria.expanded         := false,
                    i(cls := "bi-database"),
                    nbsp,
                    "Data"
                  ),
                  ul(
                    cls                   := "dropdown-menu",
                    pages.data.map(_.dropdown(router))
                  )
                ),
                li(
                  cls := "nav-item dropdown",
                  a(
                    cls                   := "nav-link dropdown-toggle",
                    dataAttr("bs-toggle") := "dropdown",
                    href                  := "#",
                    role                  := "button",
                    aria.expanded         := false,
                    i(cls := "bi-people"),
                    nbsp,
                    "People"
                  ),
                  ul(
                    cls                   := "dropdown-menu",
                    pages.people.map(_.dropdown(router))
                  )
                ),
                li(
                  cls := "nav-item dropdown",
                  a(
                    cls                   := "nav-link dropdown-toggle",
                    dataAttr("bs-toggle") := "dropdown",
                    href                  := "#",
                    role                  := "button",
                    aria.expanded         := false,
                    i(cls := "bi-music-note"),
                    nbsp,
                    "Gigs"
                  ),
                  ul(
                    cls                   := "dropdown-menu",
                    pages.gigs.map(_.dropdown(router))
                  )
                ),
                li(
                  cls := "nav-item dropdown",
                  a(
                    cls                   := "nav-link dropdown-toggle",
                    dataAttr("bs-toggle") := "dropdown",
                    href                  := "#",
                    role                  := "button",
                    aria.expanded         := false,
                    i(cls := "bi-music-note"),
                    nbsp,
                    "Releases"
                  ),
                  ul(
                    cls                   := "dropdown-menu",
                    pages.releases.map(_.dropdown(router))
                  )
                )
              ),
              div(
                cls := "d-flex",
                div(
                  cls := "nav-item dropdown",
                  button(
                    cls                   := "btn btn-link nav-link py-2 px-0 px-lg-2 dropdown-toggle d-flex align-items-center",
                    dataAttr("bs-toggle") := "dropdown",
                    href                  := "#",
                    role                  := "button",
                    aria.expanded         := false,
                    i(cls := "bi-person-gear")
                  ),
                  ul(
                    cls                   := "dropdown-menu dropdown-menu-end",
                    li(h6(cls := "dropdown-header", s"Welcome, ${user.name}")),
                    li(
                      a(
                        cls  := "dropdown-item",
                        href := BasePath,
                        onClick.preventDefault.flatMapStream { _ =>
                          AjaxUtil.recoverWithToast(
                            ProxyFetchStream.run(AuthenticationApi.Logout(), $state.now().token)
                              .mapTo(Toast(
                                "success",
                                "Success!",
                                "You have been logged out."
                              ))
                          )
                        } --> $state.updater { (s: State, t: Toast) =>
                          Toast.observer.onNext(t)
                          router.pushState(pages.login)
                          s.copy(token = None)
                        },
                        i(cls := "bi-door-open"),
                        nbsp,
                        "Logout"
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    }(None)),
    child <-- router.currentPageSignal.map(_.render(router)),
    div(cls := "toast-container position-fixed bottom-0 end-0 p-3")
  )

  def init(appNode: dom.Element): Unit = {
    // Dummy-load the NPM modules
    typings.bootstrap.bootstrapRequire

    // Set color theme
    dom.document.documentElement.setAttribute(
      "data-bs-theme",
      if (dom.window.matchMedia("(prefers-color-scheme: dark)").matches) "dark" else "light"
    )

    renderOnDomContentLoaded(appNode, rootElement)
  }

}
