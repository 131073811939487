package ch.randm.playsit.core.model

import cats.Show
import cats.implicits._
import cats.data.ValidatedNel
import ch.randm.playsit.core.error.ValidationError
import ch.randm.playsit.core.model.common.{Text, Validated}

final case class Contact(name: String, email: String, message: Text) extends Validated[Contact] {

  override def validate: ValidatedNel[ValidationError, Contact] =
    (notEmpty(name, "name"), validEmail(email), notEmpty(message, "message")).mapN(Contact.apply)

}

object Contact {

  implicit val show: Show[Contact] = c => s"${c.name} (${c.email}): ${c.message.show}"

}
