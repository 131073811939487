package ch.randm.playsit.frontend.admin

import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.core.service.AuthorizationService.User

import java.time.Instant

case class State(token: Option[Token] = None, user: Option[User] = None) {

  private val tokenIsExpired: Boolean       = token.forall(_.expires.isBefore(Instant.now()))
  private val userHasBackendAccess: Boolean = !user.forall(_.roles.map(_.get.name).contains("Anonymous"))

  def validToken[R](allowed: Token => R)(notAllowed: => R): R =
    token.filter(_ => !tokenIsExpired && userHasBackendAccess).map(allowed).getOrElse(notAllowed)

  def validUser[R](allowed: User => R)(notAllowed: => R): R =
    user.filter(_ => !tokenIsExpired && userHasBackendAccess).map(allowed).getOrElse(notAllowed)

}
