package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.{Persisted, Text}

import scala.concurrent.duration.FiniteDuration
import scala.jdk.DurationConverters.ScalaDurationOps

/** A recorded song.
  *
  * @param number
  *   The order in which the song appears on it's record
  * @param title
  *   Name of the Song
  * @param duration
  *   Play time
  * @param lyrics
  *   Text that is sung in this Song
  * @param file
  *   An Asset containing the digital representation of the song (to listen/download)
  * @param links
  *   Electronic locations where the song can be obtained
  */
case class Song(
    number: Int,
    title: String,
    duration: FiniteDuration,
    lyrics: Option[Text],
    file: Option[Persisted[Asset]],
    links: List[Persisted[ElectronicAddress]]
)

object Song {

  implicit val show: Show[Song] =
    s => s"${s.number}. ${s.title} (${s.duration.toJava.toString().substring(2).toLowerCase})"

}
