package ch.randm.playsit.frontend.element

import ch.randm.playsit.core.model.common.Text
import ch.randm.playsit.core.model.common.Text.TextType
import com.raquo.laminar.DomApi
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement
import laika.api.Transformer
import laika.format.{HTML, Markdown}

case class TextElement(text: Text) {

  private lazy val htmlString: String =
    text.typ match {
      case TextType.Markdown => Transformer.from(Markdown).to(HTML).build.transform(text.value).toOption.getOrElse("")
      case TextType.Html     => text.value
      case _                 => s"<span>${text.value.replace("\n", "<br>")}</span>"
    }

  def render: ReactiveElement.Base = foreignHtmlElement(DomApi.unsafeParseHtmlString(s"<div>$htmlString</div>"))

}
