package ch.randm.playsit.core.model

import cats.Show
import cats.data.ValidatedNel
import cats.implicits.toFunctorOps
import ch.randm.playsit.core.error.ValidationError
import ch.randm.playsit.core.model.ElectronicAddress.ElectronicAddressType
import ch.randm.playsit.core.model.common.{Persisted, Person, Validated}

case class Subscriber(
    override val firstName: String,
    override val lastName: String,
    override val address: Option[Persisted[Address]],
    override val electronicAddresses: List[Persisted[ElectronicAddress]]
) extends Person with Validated[Subscriber] {

  lazy val email: Option[String] =
    electronicAddresses.find(_.get.addressType == ElectronicAddressType.Email).map(_.get.value)

  override def validate: ValidatedNel[ValidationError, Subscriber] =
    notMissing(email, "email").andThen(validEmail(_)).as(this)

}

object Subscriber {

  implicit val show: Show[Subscriber] = s =>
    s"${s.firstName} ${s.lastName} ${s.email.mkString}"

}
