package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.{Persisted, Person}

/** A Promoter is a [[ch.randm.playsit.core.model.common.Person]] that is tasked with helping out an
  * [[ch.randm.playsit.core.model.Artist]] in some way or another.
  *
  * @param firstName
  *   First name(s)
  * @param lastName
  *   Last name(s)
  * @param address
  *   Geographical location
  * @param electronicAddresses
  *   A list of electronic addresses
  */
case class Promoter(
    firstName: String,
    lastName: String,
    address: Option[Persisted[Address]],
    electronicAddresses: List[Persisted[ElectronicAddress]]
) extends Person

object Promoter {

  implicit val show: Show[Promoter] = p =>
    s"${p.firstName} ${p.lastName}${p.address.map(a => s", ${a.print}").mkString}"

}
