package typings.tomSelect

import org.scalablytyped.runtime.Instantiable0
import org.scalablytyped.runtime.StringDictionary
import org.scalajs.dom.HTMLDivElement
import org.scalajs.dom.HTMLInputElement
import org.scalajs.dom.KeyboardEvent
import org.scalajs.dom.MouseEvent
import typings.std.IterableIterator
import typings.tomSelect.distTypesContribMicropluginMod.TPluginHash
import typings.tomSelect.distTypesContribMicropluginMod.TPluginItem
import typings.tomSelect.distTypesContribMicropluginMod.TPlugins
import typings.tomSelect.distTypesContribMicropluginMod.TSettings
import typings.tomSelect.distTypesTypesCoreMod.RecursivePartial
import typings.tomSelect.distTypesTypesCoreMod.TomCreate
import typings.tomSelect.distTypesTypesCoreMod.TomCreateFilter
import typings.tomSelect.distTypesTypesCoreMod.TomLoadCallback
import typings.tomSelect.distTypesTypesCoreMod.TomOption
import typings.tomSelect.distTypesTypesCoreMod.TomTemplate
import typings.tomSelect.distTypesTypesCoreMod.TomTemplateNull
import typings.tomSelect.mod.default
import org.scalablytyped.runtime.StObject
import scala.scalajs.js
import scala.scalajs.js.`|`
import scala.scalajs.js.annotation.{JSGlobalScope, JSGlobal, JSImport, JSName, JSBracketAccess}

object anon {
  
  @js.native
  trait Conjunction extends StObject {
    
    var conjunction: String = js.native
    
    var fields: js.Array[String] = js.native
    
    var nesting: Boolean = js.native
    
    var sort: String | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify imported_types.SortFn */ Any) | (js.Array[
        /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify imported_types.Sort */ Any
      ]) = js.native
  }
  object Conjunction {
    
    @scala.inline
    def apply(
      conjunction: String,
      fields: js.Array[String],
      nesting: Boolean,
      sort: String | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify imported_types.SortFn */ Any) | (js.Array[
          /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify imported_types.Sort */ Any
        ])
    ): Conjunction = {
      val __obj = js.Dynamic.literal(conjunction = conjunction.asInstanceOf[js.Any], fields = fields.asInstanceOf[js.Any], nesting = nesting.asInstanceOf[js.Any], sort = sort.asInstanceOf[js.Any])
      __obj.asInstanceOf[Conjunction]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: Conjunction] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setConjunction(value: String): Self = StObject.set(x, "conjunction", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFields(value: js.Array[String]): Self = StObject.set(x, "fields", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFieldsVarargs(value: String*): Self = StObject.set(x, "fields", js.Array(value :_*))
      
      @scala.inline
      def setNesting(value: Boolean): Self = StObject.set(x, "nesting", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSort(
        value: String | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify imported_types.SortFn */ Any) | (js.Array[
              /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify imported_types.Sort */ Any
            ])
      ): Self = StObject.set(x, "sort", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortVarargs(
        value: (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify imported_types.Sort */ Any)*
      ): Self = StObject.set(x, "sort", js.Array(value :_*))
    }
  }
  
  @js.native
  trait Dictx
    extends StObject
       with /* x */ StringDictionary[Any] {
    
    /**
      * Initializes the listed plugins (with options).
      * Acceptable formats:
      *
      * List (without options):
      *   ['a', 'b', 'c']
      *
      * List (with options):
      *   [{'name': 'a', options: {}}, {'name': 'b', options: {}}]
      *
      * Hash (with options):
      *   {'a': { ... }, 'b': { ... }, 'c': { ... }}
      *
      * @param {array|object} plugins
      */
    def initializePlugins(plugins: js.Array[String | TPluginItem]): Unit = js.native
    def initializePlugins(plugins: TPluginHash): Unit = js.native
    
    def loadPlugin(name: String): Unit = js.native
    
    var plugins: TPlugins = js.native
    
    /**
      * Initializes a plugin.
      *
      */
    def require(name: String): Any = js.native
  }
  
  @js.native
  trait Instantiable
    extends StObject
       with /* x */ StringDictionary[Any]
       with Instantiable0[Dictx] {
    
    /**
      * Registers a plugin.
      *
      * @param {function} fn
      */
    def define(name: String, fn: js.ThisFunction1[/* this */ Any, /* settings */ TSettings, Any]): Unit = js.native
  }
  
  @js.native
  trait Length extends StObject {
    
    var length: Double = js.native
    
    var start: Double = js.native
  }
  object Length {
    
    @scala.inline
    def apply(length: Double, start: Double): Length = {
      val __obj = js.Dynamic.literal(length = length.asInstanceOf[js.Any], start = start.asInstanceOf[js.Any])
      __obj.asInstanceOf[Length]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: Length] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setLength(value: Double): Self = StObject.set(x, "length", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setStart(value: Double): Self = StObject.set(x, "start", value.asInstanceOf[js.Any])
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types/core.RecursivePartial<std.Array<string>> */
  @js.native
  trait RecursivePartialArraystri extends StObject {
    
    var concat: js.UndefOr[
        RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]
      ] = js.native
    
    var copyWithin: js.UndefOr[
        RecursivePartial[js.Function2[/* target */ Double, /* start */ Double, this.type]]
      ] = js.native
    
    var entries: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]] = js.native
    
    var every: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            /* is std.Array<string> */ Boolean
          ]
        ]
      ] = js.native
    
    var fill: js.UndefOr[RecursivePartial[js.Function1[/* value */ String, this.type]]] = js.native
    
    var filter: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.Array[String]
          ]
        ]
      ] = js.native
    
    var find: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.ThisFunction3[
              /* this */ Unit, 
              /* value */ String, 
              /* index */ Double, 
              /* obj */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.UndefOr[String]
          ]
        ]
      ] = js.native
    
    var findIndex: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
            Double
          ]
        ]
      ] = js.native
    
    var forEach: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
            Unit
          ]
        ]
      ] = js.native
    
    var indexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var join: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var keys: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[Double]]]] = js.native
    
    var lastIndexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var length: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var map: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            js.Array[Any]
          ]
        ]
      ] = js.native
    
    var pop: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var push: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var reduce: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reduceRight: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reverse: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var shift: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var slice: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var some: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            Boolean
          ]
        ]
      ] = js.native
    
    var sort: js.UndefOr[RecursivePartial[js.Function0[this.type]]] = js.native
    
    var splice: js.UndefOr[RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]] = js.native
    
    @JSName("toLocaleString")
    var toLocaleString_FRecursivePartialArraystri: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    @JSName("toString")
    var toString_FRecursivePartialArraystri: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var unshift: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var values: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[String]]]] = js.native
  }
  object RecursivePartialArraystri {
    
    @scala.inline
    def apply(): RecursivePartialArraystri = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialArraystri]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialArraystri] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setConcat(value: RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]): Self = StObject.set(x, "concat", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setConcatUndefined: Self = StObject.set(x, "concat", js.undefined)
      
      @scala.inline
      def setCopyWithin(
        value: RecursivePartial[js.Function2[/* target */ Double, /* start */ Double, RecursivePartialArraystri]]
      ): Self = StObject.set(x, "copyWithin", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyWithinUndefined: Self = StObject.set(x, "copyWithin", js.undefined)
      
      @scala.inline
      def setEntries(value: RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]): Self = StObject.set(x, "entries", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEntriesUndefined: Self = StObject.set(x, "entries", js.undefined)
      
      @scala.inline
      def setEvery(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                /* is std.Array<string> */ Boolean
              ]
            ]
      ): Self = StObject.set(x, "every", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEveryUndefined: Self = StObject.set(x, "every", js.undefined)
      
      @scala.inline
      def setFill(value: RecursivePartial[js.Function1[/* value */ String, RecursivePartialArraystri]]): Self = StObject.set(x, "fill", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFillUndefined: Self = StObject.set(x, "fill", js.undefined)
      
      @scala.inline
      def setFilter(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.Array[String]
              ]
            ]
      ): Self = StObject.set(x, "filter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFilterUndefined: Self = StObject.set(x, "filter", js.undefined)
      
      @scala.inline
      def setFind(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.ThisFunction3[
                  /* this */ Unit, 
                  /* value */ String, 
                  /* index */ Double, 
                  /* obj */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.UndefOr[String]
              ]
            ]
      ): Self = StObject.set(x, "find", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndex(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
                Double
              ]
            ]
      ): Self = StObject.set(x, "findIndex", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndexUndefined: Self = StObject.set(x, "findIndex", js.undefined)
      
      @scala.inline
      def setFindUndefined: Self = StObject.set(x, "find", js.undefined)
      
      @scala.inline
      def setForEach(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
                Unit
              ]
            ]
      ): Self = StObject.set(x, "forEach", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setForEachUndefined: Self = StObject.set(x, "forEach", js.undefined)
      
      @scala.inline
      def setIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "indexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setIndexOfUndefined: Self = StObject.set(x, "indexOf", js.undefined)
      
      @scala.inline
      def setJoin(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "join", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setJoinUndefined: Self = StObject.set(x, "join", js.undefined)
      
      @scala.inline
      def setKeys(value: RecursivePartial[js.Function0[IterableIterator[Double]]]): Self = StObject.set(x, "keys", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setKeysUndefined: Self = StObject.set(x, "keys", js.undefined)
      
      @scala.inline
      def setLastIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "lastIndexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLastIndexOfUndefined: Self = StObject.set(x, "lastIndexOf", js.undefined)
      
      @scala.inline
      def setLength(value: RecursivePartial[Double]): Self = StObject.set(x, "length", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLengthUndefined: Self = StObject.set(x, "length", js.undefined)
      
      @scala.inline
      def setMap(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                js.Array[Any]
              ]
            ]
      ): Self = StObject.set(x, "map", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMapUndefined: Self = StObject.set(x, "map", js.undefined)
      
      @scala.inline
      def setPop(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "pop", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPopUndefined: Self = StObject.set(x, "pop", js.undefined)
      
      @scala.inline
      def setPush(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "push", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPushUndefined: Self = StObject.set(x, "push", js.undefined)
      
      @scala.inline
      def setReduce(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduce", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRight(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduceRight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRightUndefined: Self = StObject.set(x, "reduceRight", js.undefined)
      
      @scala.inline
      def setReduceUndefined: Self = StObject.set(x, "reduce", js.undefined)
      
      @scala.inline
      def setReverse(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "reverse", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReverseUndefined: Self = StObject.set(x, "reverse", js.undefined)
      
      @scala.inline
      def setShift(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "shift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShiftUndefined: Self = StObject.set(x, "shift", js.undefined)
      
      @scala.inline
      def setSlice(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "slice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSliceUndefined: Self = StObject.set(x, "slice", js.undefined)
      
      @scala.inline
      def setSome(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                Boolean
              ]
            ]
      ): Self = StObject.set(x, "some", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSomeUndefined: Self = StObject.set(x, "some", js.undefined)
      
      @scala.inline
      def setSort(value: RecursivePartial[js.Function0[RecursivePartialArraystri]]): Self = StObject.set(x, "sort", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortUndefined: Self = StObject.set(x, "sort", js.undefined)
      
      @scala.inline
      def setSplice(value: RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]): Self = StObject.set(x, "splice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSpliceUndefined: Self = StObject.set(x, "splice", js.undefined)
      
      @scala.inline
      def setToLocaleString(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toLocaleString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToLocaleStringUndefined: Self = StObject.set(x, "toLocaleString", js.undefined)
      
      @scala.inline
      def setToString_(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToString_Undefined: Self = StObject.set(x, "toString", js.undefined)
      
      @scala.inline
      def setUnshift(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "unshift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setUnshiftUndefined: Self = StObject.set(x, "unshift", js.undefined)
      
      @scala.inline
      def setValues(value: RecursivePartial[js.Function0[IterableIterator[String]]]): Self = StObject.set(x, "values", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValuesUndefined: Self = StObject.set(x, "values", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types/core.RecursivePartial<std.Array<string>> */
  @js.native
  trait RecursivePartialArraystriConcat extends StObject {
    
    var concat: js.UndefOr[
        RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]
      ] = js.native
    
    var copyWithin: js.UndefOr[RecursivePartial[js.Function2[/* target */ Double, /* start */ Double, default]]] = js.native
    
    var entries: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]] = js.native
    
    var every: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            /* is std.Array<string> */ Boolean
          ]
        ]
      ] = js.native
    
    var fill: js.UndefOr[RecursivePartial[js.Function1[/* value */ String, default]]] = js.native
    
    var filter: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.Array[String]
          ]
        ]
      ] = js.native
    
    var find: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.ThisFunction3[
              /* this */ Unit, 
              /* value */ String, 
              /* index */ Double, 
              /* obj */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.UndefOr[String]
          ]
        ]
      ] = js.native
    
    var findIndex: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
            Double
          ]
        ]
      ] = js.native
    
    var forEach: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
            Unit
          ]
        ]
      ] = js.native
    
    var indexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var join: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var keys: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[Double]]]] = js.native
    
    var lastIndexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var length: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var map: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            js.Array[Any]
          ]
        ]
      ] = js.native
    
    var pop: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var push: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var reduce: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reduceRight: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reverse: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var shift: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var slice: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var some: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            Boolean
          ]
        ]
      ] = js.native
    
    var sort: js.UndefOr[RecursivePartial[js.Function0[default]]] = js.native
    
    var splice: js.UndefOr[RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]] = js.native
    
    @JSName("toLocaleString")
    var toLocaleString_FRecursivePartialArraystriConcat: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    @JSName("toString")
    var toString_FRecursivePartialArraystriConcat: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var unshift: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var values: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[String]]]] = js.native
  }
  object RecursivePartialArraystriConcat {
    
    @scala.inline
    def apply(): RecursivePartialArraystriConcat = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialArraystriConcat]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialArraystriConcat] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setConcat(value: RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]): Self = StObject.set(x, "concat", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setConcatUndefined: Self = StObject.set(x, "concat", js.undefined)
      
      @scala.inline
      def setCopyWithin(value: RecursivePartial[js.Function2[/* target */ Double, /* start */ Double, default]]): Self = StObject.set(x, "copyWithin", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyWithinUndefined: Self = StObject.set(x, "copyWithin", js.undefined)
      
      @scala.inline
      def setEntries(value: RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]): Self = StObject.set(x, "entries", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEntriesUndefined: Self = StObject.set(x, "entries", js.undefined)
      
      @scala.inline
      def setEvery(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                /* is std.Array<string> */ Boolean
              ]
            ]
      ): Self = StObject.set(x, "every", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEveryUndefined: Self = StObject.set(x, "every", js.undefined)
      
      @scala.inline
      def setFill(value: RecursivePartial[js.Function1[/* value */ String, default]]): Self = StObject.set(x, "fill", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFillUndefined: Self = StObject.set(x, "fill", js.undefined)
      
      @scala.inline
      def setFilter(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.Array[String]
              ]
            ]
      ): Self = StObject.set(x, "filter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFilterUndefined: Self = StObject.set(x, "filter", js.undefined)
      
      @scala.inline
      def setFind(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.ThisFunction3[
                  /* this */ Unit, 
                  /* value */ String, 
                  /* index */ Double, 
                  /* obj */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.UndefOr[String]
              ]
            ]
      ): Self = StObject.set(x, "find", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndex(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
                Double
              ]
            ]
      ): Self = StObject.set(x, "findIndex", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndexUndefined: Self = StObject.set(x, "findIndex", js.undefined)
      
      @scala.inline
      def setFindUndefined: Self = StObject.set(x, "find", js.undefined)
      
      @scala.inline
      def setForEach(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
                Unit
              ]
            ]
      ): Self = StObject.set(x, "forEach", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setForEachUndefined: Self = StObject.set(x, "forEach", js.undefined)
      
      @scala.inline
      def setIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "indexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setIndexOfUndefined: Self = StObject.set(x, "indexOf", js.undefined)
      
      @scala.inline
      def setJoin(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "join", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setJoinUndefined: Self = StObject.set(x, "join", js.undefined)
      
      @scala.inline
      def setKeys(value: RecursivePartial[js.Function0[IterableIterator[Double]]]): Self = StObject.set(x, "keys", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setKeysUndefined: Self = StObject.set(x, "keys", js.undefined)
      
      @scala.inline
      def setLastIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "lastIndexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLastIndexOfUndefined: Self = StObject.set(x, "lastIndexOf", js.undefined)
      
      @scala.inline
      def setLength(value: RecursivePartial[Double]): Self = StObject.set(x, "length", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLengthUndefined: Self = StObject.set(x, "length", js.undefined)
      
      @scala.inline
      def setMap(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                js.Array[Any]
              ]
            ]
      ): Self = StObject.set(x, "map", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMapUndefined: Self = StObject.set(x, "map", js.undefined)
      
      @scala.inline
      def setPop(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "pop", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPopUndefined: Self = StObject.set(x, "pop", js.undefined)
      
      @scala.inline
      def setPush(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "push", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPushUndefined: Self = StObject.set(x, "push", js.undefined)
      
      @scala.inline
      def setReduce(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduce", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRight(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduceRight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRightUndefined: Self = StObject.set(x, "reduceRight", js.undefined)
      
      @scala.inline
      def setReduceUndefined: Self = StObject.set(x, "reduce", js.undefined)
      
      @scala.inline
      def setReverse(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "reverse", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReverseUndefined: Self = StObject.set(x, "reverse", js.undefined)
      
      @scala.inline
      def setShift(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "shift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShiftUndefined: Self = StObject.set(x, "shift", js.undefined)
      
      @scala.inline
      def setSlice(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "slice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSliceUndefined: Self = StObject.set(x, "slice", js.undefined)
      
      @scala.inline
      def setSome(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                Boolean
              ]
            ]
      ): Self = StObject.set(x, "some", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSomeUndefined: Self = StObject.set(x, "some", js.undefined)
      
      @scala.inline
      def setSort(value: RecursivePartial[js.Function0[default]]): Self = StObject.set(x, "sort", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortUndefined: Self = StObject.set(x, "sort", js.undefined)
      
      @scala.inline
      def setSplice(value: RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]): Self = StObject.set(x, "splice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSpliceUndefined: Self = StObject.set(x, "splice", js.undefined)
      
      @scala.inline
      def setToLocaleString(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toLocaleString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToLocaleStringUndefined: Self = StObject.set(x, "toLocaleString", js.undefined)
      
      @scala.inline
      def setToString_(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToString_Undefined: Self = StObject.set(x, "toString", js.undefined)
      
      @scala.inline
      def setUnshift(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "unshift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setUnshiftUndefined: Self = StObject.set(x, "unshift", js.undefined)
      
      @scala.inline
      def setValues(value: RecursivePartial[js.Function0[IterableIterator[String]]]): Self = StObject.set(x, "values", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValuesUndefined: Self = StObject.set(x, "values", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types/core.RecursivePartial<std.Array<string>> */
  @js.native
  trait RecursivePartialArraystriCopyWithin extends StObject {
    
    var concat: js.UndefOr[
        RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]
      ] = js.native
    
    var copyWithin: js.UndefOr[
        RecursivePartial[
          js.Function2[
            /* target */ Double, 
            /* start */ Double, 
            typings.tomSelect.distTypesTomSelectMod.default
          ]
        ]
      ] = js.native
    
    var entries: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]] = js.native
    
    var every: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            /* is std.Array<string> */ Boolean
          ]
        ]
      ] = js.native
    
    var fill: js.UndefOr[
        RecursivePartial[
          js.Function1[/* value */ String, typings.tomSelect.distTypesTomSelectMod.default]
        ]
      ] = js.native
    
    var filter: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.Array[String]
          ]
        ]
      ] = js.native
    
    var find: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.ThisFunction3[
              /* this */ Unit, 
              /* value */ String, 
              /* index */ Double, 
              /* obj */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.UndefOr[String]
          ]
        ]
      ] = js.native
    
    var findIndex: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
            Double
          ]
        ]
      ] = js.native
    
    var forEach: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
            Unit
          ]
        ]
      ] = js.native
    
    var indexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var join: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var keys: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[Double]]]] = js.native
    
    var lastIndexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var length: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var map: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            js.Array[Any]
          ]
        ]
      ] = js.native
    
    var pop: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var push: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var reduce: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reduceRight: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reverse: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var shift: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var slice: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var some: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            Boolean
          ]
        ]
      ] = js.native
    
    var sort: js.UndefOr[RecursivePartial[js.Function0[typings.tomSelect.distTypesTomSelectMod.default]]] = js.native
    
    var splice: js.UndefOr[RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]] = js.native
    
    @JSName("toLocaleString")
    var toLocaleString_FRecursivePartialArraystriCopyWithin: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    @JSName("toString")
    var toString_FRecursivePartialArraystriCopyWithin: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var unshift: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var values: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[String]]]] = js.native
  }
  object RecursivePartialArraystriCopyWithin {
    
    @scala.inline
    def apply(): RecursivePartialArraystriCopyWithin = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialArraystriCopyWithin]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialArraystriCopyWithin] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setConcat(value: RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]): Self = StObject.set(x, "concat", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setConcatUndefined: Self = StObject.set(x, "concat", js.undefined)
      
      @scala.inline
      def setCopyWithin(
        value: RecursivePartial[
              js.Function2[
                /* target */ Double, 
                /* start */ Double, 
                typings.tomSelect.distTypesTomSelectMod.default
              ]
            ]
      ): Self = StObject.set(x, "copyWithin", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyWithinUndefined: Self = StObject.set(x, "copyWithin", js.undefined)
      
      @scala.inline
      def setEntries(value: RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]): Self = StObject.set(x, "entries", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEntriesUndefined: Self = StObject.set(x, "entries", js.undefined)
      
      @scala.inline
      def setEvery(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                /* is std.Array<string> */ Boolean
              ]
            ]
      ): Self = StObject.set(x, "every", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEveryUndefined: Self = StObject.set(x, "every", js.undefined)
      
      @scala.inline
      def setFill(
        value: RecursivePartial[
              js.Function1[/* value */ String, typings.tomSelect.distTypesTomSelectMod.default]
            ]
      ): Self = StObject.set(x, "fill", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFillUndefined: Self = StObject.set(x, "fill", js.undefined)
      
      @scala.inline
      def setFilter(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.Array[String]
              ]
            ]
      ): Self = StObject.set(x, "filter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFilterUndefined: Self = StObject.set(x, "filter", js.undefined)
      
      @scala.inline
      def setFind(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.ThisFunction3[
                  /* this */ Unit, 
                  /* value */ String, 
                  /* index */ Double, 
                  /* obj */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.UndefOr[String]
              ]
            ]
      ): Self = StObject.set(x, "find", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndex(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
                Double
              ]
            ]
      ): Self = StObject.set(x, "findIndex", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndexUndefined: Self = StObject.set(x, "findIndex", js.undefined)
      
      @scala.inline
      def setFindUndefined: Self = StObject.set(x, "find", js.undefined)
      
      @scala.inline
      def setForEach(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
                Unit
              ]
            ]
      ): Self = StObject.set(x, "forEach", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setForEachUndefined: Self = StObject.set(x, "forEach", js.undefined)
      
      @scala.inline
      def setIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "indexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setIndexOfUndefined: Self = StObject.set(x, "indexOf", js.undefined)
      
      @scala.inline
      def setJoin(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "join", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setJoinUndefined: Self = StObject.set(x, "join", js.undefined)
      
      @scala.inline
      def setKeys(value: RecursivePartial[js.Function0[IterableIterator[Double]]]): Self = StObject.set(x, "keys", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setKeysUndefined: Self = StObject.set(x, "keys", js.undefined)
      
      @scala.inline
      def setLastIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "lastIndexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLastIndexOfUndefined: Self = StObject.set(x, "lastIndexOf", js.undefined)
      
      @scala.inline
      def setLength(value: RecursivePartial[Double]): Self = StObject.set(x, "length", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLengthUndefined: Self = StObject.set(x, "length", js.undefined)
      
      @scala.inline
      def setMap(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                js.Array[Any]
              ]
            ]
      ): Self = StObject.set(x, "map", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMapUndefined: Self = StObject.set(x, "map", js.undefined)
      
      @scala.inline
      def setPop(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "pop", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPopUndefined: Self = StObject.set(x, "pop", js.undefined)
      
      @scala.inline
      def setPush(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "push", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPushUndefined: Self = StObject.set(x, "push", js.undefined)
      
      @scala.inline
      def setReduce(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduce", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRight(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduceRight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRightUndefined: Self = StObject.set(x, "reduceRight", js.undefined)
      
      @scala.inline
      def setReduceUndefined: Self = StObject.set(x, "reduce", js.undefined)
      
      @scala.inline
      def setReverse(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "reverse", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReverseUndefined: Self = StObject.set(x, "reverse", js.undefined)
      
      @scala.inline
      def setShift(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "shift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShiftUndefined: Self = StObject.set(x, "shift", js.undefined)
      
      @scala.inline
      def setSlice(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "slice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSliceUndefined: Self = StObject.set(x, "slice", js.undefined)
      
      @scala.inline
      def setSome(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                Boolean
              ]
            ]
      ): Self = StObject.set(x, "some", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSomeUndefined: Self = StObject.set(x, "some", js.undefined)
      
      @scala.inline
      def setSort(value: RecursivePartial[js.Function0[typings.tomSelect.distTypesTomSelectMod.default]]): Self = StObject.set(x, "sort", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortUndefined: Self = StObject.set(x, "sort", js.undefined)
      
      @scala.inline
      def setSplice(value: RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]): Self = StObject.set(x, "splice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSpliceUndefined: Self = StObject.set(x, "splice", js.undefined)
      
      @scala.inline
      def setToLocaleString(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toLocaleString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToLocaleStringUndefined: Self = StObject.set(x, "toLocaleString", js.undefined)
      
      @scala.inline
      def setToString_(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToString_Undefined: Self = StObject.set(x, "toString", js.undefined)
      
      @scala.inline
      def setUnshift(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "unshift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setUnshiftUndefined: Self = StObject.set(x, "unshift", js.undefined)
      
      @scala.inline
      def setValues(value: RecursivePartial[js.Function0[IterableIterator[String]]]): Self = StObject.set(x, "values", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValuesUndefined: Self = StObject.set(x, "values", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types/core.RecursivePartial<std.Array<string>> */
  @js.native
  trait RecursivePartialArraystriEntries extends StObject {
    
    var concat: js.UndefOr[
        RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]
      ] = js.native
    
    var copyWithin: js.UndefOr[
        RecursivePartial[
          js.Function2[
            /* target */ Double, 
            /* start */ Double, 
            typings.tomSelect.distTypesTomSelectDotpopularMod.default
          ]
        ]
      ] = js.native
    
    var entries: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]] = js.native
    
    var every: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            /* is std.Array<string> */ Boolean
          ]
        ]
      ] = js.native
    
    var fill: js.UndefOr[
        RecursivePartial[
          js.Function1[/* value */ String, typings.tomSelect.distTypesTomSelectDotpopularMod.default]
        ]
      ] = js.native
    
    var filter: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[
              /* value */ String, 
              /* index */ Double, 
              /* array */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.Array[String]
          ]
        ]
      ] = js.native
    
    var find: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.ThisFunction3[
              /* this */ Unit, 
              /* value */ String, 
              /* index */ Double, 
              /* obj */ js.Array[String], 
              /* is string */ Boolean
            ], 
            js.UndefOr[String]
          ]
        ]
      ] = js.native
    
    var findIndex: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
            Double
          ]
        ]
      ] = js.native
    
    var forEach: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
            Unit
          ]
        ]
      ] = js.native
    
    var indexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var join: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var keys: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[Double]]]] = js.native
    
    var lastIndexOf: js.UndefOr[RecursivePartial[js.Function1[/* searchElement */ String, Double]]] = js.native
    
    var length: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var map: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            js.Array[Any]
          ]
        ]
      ] = js.native
    
    var pop: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var push: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var reduce: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reduceRight: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* callbackfn */ js.Function4[
              /* previousValue */ String, 
              /* currentValue */ String, 
              /* currentIndex */ Double, 
              /* array */ js.Array[String], 
              String
            ], 
            String
          ]
        ]
      ] = js.native
    
    var reverse: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var shift: js.UndefOr[RecursivePartial[js.Function0[js.UndefOr[String]]]] = js.native
    
    var slice: js.UndefOr[RecursivePartial[js.Function0[js.Array[String]]]] = js.native
    
    var some: js.UndefOr[
        RecursivePartial[
          js.Function1[
            /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
            Boolean
          ]
        ]
      ] = js.native
    
    var sort: js.UndefOr[
        RecursivePartial[js.Function0[typings.tomSelect.distTypesTomSelectDotpopularMod.default]]
      ] = js.native
    
    var splice: js.UndefOr[RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]] = js.native
    
    @JSName("toLocaleString")
    var toLocaleString_FRecursivePartialArraystriEntries: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    @JSName("toString")
    var toString_FRecursivePartialArraystriEntries: js.UndefOr[RecursivePartial[js.Function0[String]]] = js.native
    
    var unshift: js.UndefOr[RecursivePartial[js.Function1[/* repeated */ String, Double]]] = js.native
    
    var values: js.UndefOr[RecursivePartial[js.Function0[IterableIterator[String]]]] = js.native
  }
  object RecursivePartialArraystriEntries {
    
    @scala.inline
    def apply(): RecursivePartialArraystriEntries = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialArraystriEntries]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialArraystriEntries] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setConcat(value: RecursivePartial[js.Function1[/* repeated */ js.Array[String], js.Array[String]]]): Self = StObject.set(x, "concat", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setConcatUndefined: Self = StObject.set(x, "concat", js.undefined)
      
      @scala.inline
      def setCopyWithin(
        value: RecursivePartial[
              js.Function2[
                /* target */ Double, 
                /* start */ Double, 
                typings.tomSelect.distTypesTomSelectDotpopularMod.default
              ]
            ]
      ): Self = StObject.set(x, "copyWithin", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyWithinUndefined: Self = StObject.set(x, "copyWithin", js.undefined)
      
      @scala.inline
      def setEntries(value: RecursivePartial[js.Function0[IterableIterator[js.Tuple2[Double, String]]]]): Self = StObject.set(x, "entries", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEntriesUndefined: Self = StObject.set(x, "entries", js.undefined)
      
      @scala.inline
      def setEvery(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                /* is std.Array<string> */ Boolean
              ]
            ]
      ): Self = StObject.set(x, "every", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setEveryUndefined: Self = StObject.set(x, "every", js.undefined)
      
      @scala.inline
      def setFill(
        value: RecursivePartial[
              js.Function1[/* value */ String, typings.tomSelect.distTypesTomSelectDotpopularMod.default]
            ]
      ): Self = StObject.set(x, "fill", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFillUndefined: Self = StObject.set(x, "fill", js.undefined)
      
      @scala.inline
      def setFilter(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[
                  /* value */ String, 
                  /* index */ Double, 
                  /* array */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.Array[String]
              ]
            ]
      ): Self = StObject.set(x, "filter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFilterUndefined: Self = StObject.set(x, "filter", js.undefined)
      
      @scala.inline
      def setFind(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.ThisFunction3[
                  /* this */ Unit, 
                  /* value */ String, 
                  /* index */ Double, 
                  /* obj */ js.Array[String], 
                  /* is string */ Boolean
                ], 
                js.UndefOr[String]
              ]
            ]
      ): Self = StObject.set(x, "find", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndex(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* obj */ js.Array[String], Any], 
                Double
              ]
            ]
      ): Self = StObject.set(x, "findIndex", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFindIndexUndefined: Self = StObject.set(x, "findIndex", js.undefined)
      
      @scala.inline
      def setFindUndefined: Self = StObject.set(x, "find", js.undefined)
      
      @scala.inline
      def setForEach(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Unit], 
                Unit
              ]
            ]
      ): Self = StObject.set(x, "forEach", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setForEachUndefined: Self = StObject.set(x, "forEach", js.undefined)
      
      @scala.inline
      def setIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "indexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setIndexOfUndefined: Self = StObject.set(x, "indexOf", js.undefined)
      
      @scala.inline
      def setJoin(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "join", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setJoinUndefined: Self = StObject.set(x, "join", js.undefined)
      
      @scala.inline
      def setKeys(value: RecursivePartial[js.Function0[IterableIterator[Double]]]): Self = StObject.set(x, "keys", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setKeysUndefined: Self = StObject.set(x, "keys", js.undefined)
      
      @scala.inline
      def setLastIndexOf(value: RecursivePartial[js.Function1[/* searchElement */ String, Double]]): Self = StObject.set(x, "lastIndexOf", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLastIndexOfUndefined: Self = StObject.set(x, "lastIndexOf", js.undefined)
      
      @scala.inline
      def setLength(value: RecursivePartial[Double]): Self = StObject.set(x, "length", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLengthUndefined: Self = StObject.set(x, "length", js.undefined)
      
      @scala.inline
      def setMap(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                js.Array[Any]
              ]
            ]
      ): Self = StObject.set(x, "map", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMapUndefined: Self = StObject.set(x, "map", js.undefined)
      
      @scala.inline
      def setPop(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "pop", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPopUndefined: Self = StObject.set(x, "pop", js.undefined)
      
      @scala.inline
      def setPush(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "push", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPushUndefined: Self = StObject.set(x, "push", js.undefined)
      
      @scala.inline
      def setReduce(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduce", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRight(
        value: RecursivePartial[
              js.Function1[
                /* callbackfn */ js.Function4[
                  /* previousValue */ String, 
                  /* currentValue */ String, 
                  /* currentIndex */ Double, 
                  /* array */ js.Array[String], 
                  String
                ], 
                String
              ]
            ]
      ): Self = StObject.set(x, "reduceRight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReduceRightUndefined: Self = StObject.set(x, "reduceRight", js.undefined)
      
      @scala.inline
      def setReduceUndefined: Self = StObject.set(x, "reduce", js.undefined)
      
      @scala.inline
      def setReverse(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "reverse", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setReverseUndefined: Self = StObject.set(x, "reverse", js.undefined)
      
      @scala.inline
      def setShift(value: RecursivePartial[js.Function0[js.UndefOr[String]]]): Self = StObject.set(x, "shift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShiftUndefined: Self = StObject.set(x, "shift", js.undefined)
      
      @scala.inline
      def setSlice(value: RecursivePartial[js.Function0[js.Array[String]]]): Self = StObject.set(x, "slice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSliceUndefined: Self = StObject.set(x, "slice", js.undefined)
      
      @scala.inline
      def setSome(
        value: RecursivePartial[
              js.Function1[
                /* predicate */ js.Function3[/* value */ String, /* index */ Double, /* array */ js.Array[String], Any], 
                Boolean
              ]
            ]
      ): Self = StObject.set(x, "some", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSomeUndefined: Self = StObject.set(x, "some", js.undefined)
      
      @scala.inline
      def setSort(value: RecursivePartial[js.Function0[typings.tomSelect.distTypesTomSelectDotpopularMod.default]]): Self = StObject.set(x, "sort", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortUndefined: Self = StObject.set(x, "sort", js.undefined)
      
      @scala.inline
      def setSplice(value: RecursivePartial[js.Function1[/* start */ Double, js.Array[String]]]): Self = StObject.set(x, "splice", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSpliceUndefined: Self = StObject.set(x, "splice", js.undefined)
      
      @scala.inline
      def setToLocaleString(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toLocaleString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToLocaleStringUndefined: Self = StObject.set(x, "toLocaleString", js.undefined)
      
      @scala.inline
      def setToString_(value: RecursivePartial[js.Function0[String]]): Self = StObject.set(x, "toString", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setToString_Undefined: Self = StObject.set(x, "toString", js.undefined)
      
      @scala.inline
      def setUnshift(value: RecursivePartial[js.Function1[/* repeated */ String, Double]]): Self = StObject.set(x, "unshift", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setUnshiftUndefined: Self = StObject.set(x, "unshift", js.undefined)
      
      @scala.inline
      def setValues(value: RecursivePartial[js.Function0[IterableIterator[String]]]): Self = StObject.set(x, "values", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValuesUndefined: Self = StObject.set(x, "values", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types.RecursivePartial<tom-select.tom-select/dist/types/types.TomSettings> */
  @js.native
  trait RecursivePartialTomSettin extends StObject {
    
    var addPrecedence: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var allowEmptyOption: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var closeAfterSelect: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var controlClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var controlInput: js.UndefOr[RecursivePartial[String | HTMLInputElement]] = js.native
    
    var copyClassesToDropdown: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var create: js.UndefOr[RecursivePartial[Boolean | TomCreate]] = js.native
    
    var createFilter: js.UndefOr[RecursivePartial[js.RegExp | String | TomCreateFilter]] = js.native
    
    var createOnBlur: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var dataAttr: js.UndefOr[RecursivePartial[String]] = js.native
    
    var delimiter: js.UndefOr[RecursivePartial[String]] = js.native
    
    var diacritics: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var disabledField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownContentClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownParent: js.UndefOr[RecursivePartial[String]] = js.native
    
    var duplicates: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var firstUrl: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Any]]] = js.native
    
    var hidePlaceholder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var hideSelected: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var highlight: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var itemClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var items: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[String]]]] = js.native
    
    var labelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var load: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]
      ] = js.native
    
    var loadThrottle: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var loadingClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var lockOptgroupOrder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var maxItems: js.UndefOr[RecursivePartial[Null | Double]] = js.native
    
    var maxOptions: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var mode: js.UndefOr[RecursivePartial[String]] = js.native
    
    var nesting: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var onBlur: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onChange: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onDelete: js.UndefOr[
        RecursivePartial[
          js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
        ]
      ] = js.native
    
    var onDropdownClose: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onDropdownOpen: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onFocus: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onInitialize: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onItemAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onItemRemove: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onLoad: js.UndefOr[
        RecursivePartial[
          js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
        ]
      ] = js.native
    
    var onOptionAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionGroupClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onOptionRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onType: js.UndefOr[RecursivePartial[js.Function1[/* str */ String, Unit]]] = js.native
    
    var openOnFocus: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var optgroupField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupLabelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupValueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroups: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var optionClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var options: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var persist: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var placeholder: js.UndefOr[RecursivePartial[String]] = js.native
    
    var plugins: js.UndefOr[RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]] = js.native
    
    var preload: js.UndefOr[RecursivePartial[Boolean | String]] = js.native
    
    var render: js.UndefOr[RecursivePartialTomTempla] = js.native
    
    var score: js.UndefOr[
        RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]
      ] = js.native
    
    var searchConjunction: js.UndefOr[RecursivePartial[String]] = js.native
    
    var searchField: js.UndefOr[RecursivePartialArraystri] = js.native
    
    var selectOnTab: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var shouldLoad: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Boolean]]] = js.native
    
    var shouldLoadMore: js.UndefOr[RecursivePartial[js.Function0[Boolean]]] = js.native
    
    var shouldOpen: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var sortField: js.UndefOr[
        RecursivePartial[
          String | (js.Array[
            /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
          ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
        ]
      ] = js.native
    
    var splitOn: js.UndefOr[RecursivePartial[js.RegExp | String]] = js.native
    
    var valueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var wrapperClass: js.UndefOr[RecursivePartial[String]] = js.native
  }
  object RecursivePartialTomSettin {
    
    @scala.inline
    def apply(): RecursivePartialTomSettin = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialTomSettin]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialTomSettin] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setAddPrecedence(value: RecursivePartial[Boolean]): Self = StObject.set(x, "addPrecedence", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAddPrecedenceUndefined: Self = StObject.set(x, "addPrecedence", js.undefined)
      
      @scala.inline
      def setAllowEmptyOption(value: RecursivePartial[Boolean]): Self = StObject.set(x, "allowEmptyOption", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAllowEmptyOptionUndefined: Self = StObject.set(x, "allowEmptyOption", js.undefined)
      
      @scala.inline
      def setCloseAfterSelect(value: RecursivePartial[Boolean]): Self = StObject.set(x, "closeAfterSelect", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCloseAfterSelectUndefined: Self = StObject.set(x, "closeAfterSelect", js.undefined)
      
      @scala.inline
      def setControlClass(value: RecursivePartial[String]): Self = StObject.set(x, "controlClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlClassUndefined: Self = StObject.set(x, "controlClass", js.undefined)
      
      @scala.inline
      def setControlInput(value: RecursivePartial[String | HTMLInputElement]): Self = StObject.set(x, "controlInput", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlInputUndefined: Self = StObject.set(x, "controlInput", js.undefined)
      
      @scala.inline
      def setCopyClassesToDropdown(value: RecursivePartial[Boolean]): Self = StObject.set(x, "copyClassesToDropdown", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyClassesToDropdownUndefined: Self = StObject.set(x, "copyClassesToDropdown", js.undefined)
      
      @scala.inline
      def setCreate(value: RecursivePartial[Boolean | TomCreate]): Self = StObject.set(x, "create", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilter(value: RecursivePartial[js.RegExp | String | TomCreateFilter]): Self = StObject.set(x, "createFilter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilterUndefined: Self = StObject.set(x, "createFilter", js.undefined)
      
      @scala.inline
      def setCreateOnBlur(value: RecursivePartial[Boolean]): Self = StObject.set(x, "createOnBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateOnBlurUndefined: Self = StObject.set(x, "createOnBlur", js.undefined)
      
      @scala.inline
      def setCreateUndefined: Self = StObject.set(x, "create", js.undefined)
      
      @scala.inline
      def setDataAttr(value: RecursivePartial[String]): Self = StObject.set(x, "dataAttr", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDataAttrUndefined: Self = StObject.set(x, "dataAttr", js.undefined)
      
      @scala.inline
      def setDelimiter(value: RecursivePartial[String]): Self = StObject.set(x, "delimiter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDelimiterUndefined: Self = StObject.set(x, "delimiter", js.undefined)
      
      @scala.inline
      def setDiacritics(value: RecursivePartial[Boolean]): Self = StObject.set(x, "diacritics", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDiacriticsUndefined: Self = StObject.set(x, "diacritics", js.undefined)
      
      @scala.inline
      def setDisabledField(value: RecursivePartial[String]): Self = StObject.set(x, "disabledField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDisabledFieldUndefined: Self = StObject.set(x, "disabledField", js.undefined)
      
      @scala.inline
      def setDropdownClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownClassUndefined: Self = StObject.set(x, "dropdownClass", js.undefined)
      
      @scala.inline
      def setDropdownContentClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownContentClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownContentClassUndefined: Self = StObject.set(x, "dropdownContentClass", js.undefined)
      
      @scala.inline
      def setDropdownParent(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownParent", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownParentUndefined: Self = StObject.set(x, "dropdownParent", js.undefined)
      
      @scala.inline
      def setDuplicates(value: RecursivePartial[Boolean]): Self = StObject.set(x, "duplicates", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDuplicatesUndefined: Self = StObject.set(x, "duplicates", js.undefined)
      
      @scala.inline
      def setFirstUrl(value: RecursivePartial[js.Function1[/* query */ String, Any]]): Self = StObject.set(x, "firstUrl", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFirstUrlUndefined: Self = StObject.set(x, "firstUrl", js.undefined)
      
      @scala.inline
      def setHidePlaceholder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hidePlaceholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHidePlaceholderUndefined: Self = StObject.set(x, "hidePlaceholder", js.undefined)
      
      @scala.inline
      def setHideSelected(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hideSelected", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHideSelectedUndefined: Self = StObject.set(x, "hideSelected", js.undefined)
      
      @scala.inline
      def setHighlight(value: RecursivePartial[Boolean]): Self = StObject.set(x, "highlight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHighlightUndefined: Self = StObject.set(x, "highlight", js.undefined)
      
      @scala.inline
      def setItemClass(value: RecursivePartial[String]): Self = StObject.set(x, "itemClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemClassUndefined: Self = StObject.set(x, "itemClass", js.undefined)
      
      @scala.inline
      def setItems(value: RecursivePartial[js.UndefOr[js.Array[String]]]): Self = StObject.set(x, "items", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemsUndefined: Self = StObject.set(x, "items", js.undefined)
      
      @scala.inline
      def setLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "labelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLabelFieldUndefined: Self = StObject.set(x, "labelField", js.undefined)
      
      @scala.inline
      def setLoad(value: RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]): Self = StObject.set(x, "load", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottle(value: RecursivePartial[Double]): Self = StObject.set(x, "loadThrottle", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottleUndefined: Self = StObject.set(x, "loadThrottle", js.undefined)
      
      @scala.inline
      def setLoadUndefined: Self = StObject.set(x, "load", js.undefined)
      
      @scala.inline
      def setLoadingClass(value: RecursivePartial[String]): Self = StObject.set(x, "loadingClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadingClassUndefined: Self = StObject.set(x, "loadingClass", js.undefined)
      
      @scala.inline
      def setLockOptgroupOrder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "lockOptgroupOrder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLockOptgroupOrderUndefined: Self = StObject.set(x, "lockOptgroupOrder", js.undefined)
      
      @scala.inline
      def setMaxItems(value: RecursivePartial[Null | Double]): Self = StObject.set(x, "maxItems", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxItemsUndefined: Self = StObject.set(x, "maxItems", js.undefined)
      
      @scala.inline
      def setMaxOptions(value: RecursivePartial[Double]): Self = StObject.set(x, "maxOptions", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxOptionsUndefined: Self = StObject.set(x, "maxOptions", js.undefined)
      
      @scala.inline
      def setMode(value: RecursivePartial[String]): Self = StObject.set(x, "mode", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setModeUndefined: Self = StObject.set(x, "mode", js.undefined)
      
      @scala.inline
      def setNesting(value: RecursivePartial[Boolean]): Self = StObject.set(x, "nesting", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setNestingUndefined: Self = StObject.set(x, "nesting", js.undefined)
      
      @scala.inline
      def setOnBlur(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnBlurUndefined: Self = StObject.set(x, "onBlur", js.undefined)
      
      @scala.inline
      def setOnChange(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onChange", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnChangeUndefined: Self = StObject.set(x, "onChange", js.undefined)
      
      @scala.inline
      def setOnClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnClearUndefined: Self = StObject.set(x, "onClear", js.undefined)
      
      @scala.inline
      def setOnDelete(
        value: RecursivePartial[
              js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
            ]
      ): Self = StObject.set(x, "onDelete", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDeleteUndefined: Self = StObject.set(x, "onDelete", js.undefined)
      
      @scala.inline
      def setOnDropdownClose(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownClose", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownCloseUndefined: Self = StObject.set(x, "onDropdownClose", js.undefined)
      
      @scala.inline
      def setOnDropdownOpen(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownOpenUndefined: Self = StObject.set(x, "onDropdownOpen", js.undefined)
      
      @scala.inline
      def setOnFocus(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnFocusUndefined: Self = StObject.set(x, "onFocus", js.undefined)
      
      @scala.inline
      def setOnInitialize(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onInitialize", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnInitializeUndefined: Self = StObject.set(x, "onInitialize", js.undefined)
      
      @scala.inline
      def setOnItemAdd(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemAddUndefined: Self = StObject.set(x, "onItemAdd", js.undefined)
      
      @scala.inline
      def setOnItemRemove(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemRemoveUndefined: Self = StObject.set(x, "onItemRemove", js.undefined)
      
      @scala.inline
      def setOnLoad(
        value: RecursivePartial[
              js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
            ]
      ): Self = StObject.set(x, "onLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnLoadUndefined: Self = StObject.set(x, "onLoad", js.undefined)
      
      @scala.inline
      def setOnOptionAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionAddUndefined: Self = StObject.set(x, "onOptionAdd", js.undefined)
      
      @scala.inline
      def setOnOptionClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionClearUndefined: Self = StObject.set(x, "onOptionClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionGroupAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupAddUndefined: Self = StObject.set(x, "onOptionGroupAdd", js.undefined)
      
      @scala.inline
      def setOnOptionGroupClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionGroupClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupClearUndefined: Self = StObject.set(x, "onOptionGroupClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionGroupRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupRemoveUndefined: Self = StObject.set(x, "onOptionGroupRemove", js.undefined)
      
      @scala.inline
      def setOnOptionRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionRemoveUndefined: Self = StObject.set(x, "onOptionRemove", js.undefined)
      
      @scala.inline
      def setOnType(value: RecursivePartial[js.Function1[/* str */ String, Unit]]): Self = StObject.set(x, "onType", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnTypeUndefined: Self = StObject.set(x, "onType", js.undefined)
      
      @scala.inline
      def setOpenOnFocus(value: RecursivePartial[Boolean]): Self = StObject.set(x, "openOnFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOpenOnFocusUndefined: Self = StObject.set(x, "openOnFocus", js.undefined)
      
      @scala.inline
      def setOptgroupField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupFieldUndefined: Self = StObject.set(x, "optgroupField", js.undefined)
      
      @scala.inline
      def setOptgroupLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupLabelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupLabelFieldUndefined: Self = StObject.set(x, "optgroupLabelField", js.undefined)
      
      @scala.inline
      def setOptgroupValueField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupValueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupValueFieldUndefined: Self = StObject.set(x, "optgroupValueField", js.undefined)
      
      @scala.inline
      def setOptgroups(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "optgroups", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupsUndefined: Self = StObject.set(x, "optgroups", js.undefined)
      
      @scala.inline
      def setOptionClass(value: RecursivePartial[String]): Self = StObject.set(x, "optionClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionClassUndefined: Self = StObject.set(x, "optionClass", js.undefined)
      
      @scala.inline
      def setOptions(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "options", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionsUndefined: Self = StObject.set(x, "options", js.undefined)
      
      @scala.inline
      def setPersist(value: RecursivePartial[Boolean]): Self = StObject.set(x, "persist", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPersistUndefined: Self = StObject.set(x, "persist", js.undefined)
      
      @scala.inline
      def setPlaceholder(value: RecursivePartial[String]): Self = StObject.set(x, "placeholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPlaceholderUndefined: Self = StObject.set(x, "placeholder", js.undefined)
      
      @scala.inline
      def setPlugins(value: RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]): Self = StObject.set(x, "plugins", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPluginsUndefined: Self = StObject.set(x, "plugins", js.undefined)
      
      @scala.inline
      def setPreload(value: RecursivePartial[Boolean | String]): Self = StObject.set(x, "preload", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPreloadUndefined: Self = StObject.set(x, "preload", js.undefined)
      
      @scala.inline
      def setRender(value: RecursivePartialTomTempla): Self = StObject.set(x, "render", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setRenderUndefined: Self = StObject.set(x, "render", js.undefined)
      
      @scala.inline
      def setScore(value: RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]): Self = StObject.set(x, "score", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setScoreUndefined: Self = StObject.set(x, "score", js.undefined)
      
      @scala.inline
      def setSearchConjunction(value: RecursivePartial[String]): Self = StObject.set(x, "searchConjunction", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchConjunctionUndefined: Self = StObject.set(x, "searchConjunction", js.undefined)
      
      @scala.inline
      def setSearchField(value: RecursivePartialArraystri): Self = StObject.set(x, "searchField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchFieldUndefined: Self = StObject.set(x, "searchField", js.undefined)
      
      @scala.inline
      def setSelectOnTab(value: RecursivePartial[Boolean]): Self = StObject.set(x, "selectOnTab", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSelectOnTabUndefined: Self = StObject.set(x, "selectOnTab", js.undefined)
      
      @scala.inline
      def setShouldLoad(value: RecursivePartial[js.Function1[/* query */ String, Boolean]]): Self = StObject.set(x, "shouldLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMore(value: RecursivePartial[js.Function0[Boolean]]): Self = StObject.set(x, "shouldLoadMore", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMoreUndefined: Self = StObject.set(x, "shouldLoadMore", js.undefined)
      
      @scala.inline
      def setShouldLoadUndefined: Self = StObject.set(x, "shouldLoad", js.undefined)
      
      @scala.inline
      def setShouldOpen(value: RecursivePartial[Boolean]): Self = StObject.set(x, "shouldOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldOpenUndefined: Self = StObject.set(x, "shouldOpen", js.undefined)
      
      @scala.inline
      def setSortField(
        value: RecursivePartial[
              String | (js.Array[
                /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
              ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
            ]
      ): Self = StObject.set(x, "sortField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortFieldUndefined: Self = StObject.set(x, "sortField", js.undefined)
      
      @scala.inline
      def setSplitOn(value: RecursivePartial[js.RegExp | String]): Self = StObject.set(x, "splitOn", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSplitOnUndefined: Self = StObject.set(x, "splitOn", js.undefined)
      
      @scala.inline
      def setValueField(value: RecursivePartial[String]): Self = StObject.set(x, "valueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValueFieldUndefined: Self = StObject.set(x, "valueField", js.undefined)
      
      @scala.inline
      def setWrapperClass(value: RecursivePartial[String]): Self = StObject.set(x, "wrapperClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setWrapperClassUndefined: Self = StObject.set(x, "wrapperClass", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types.RecursivePartial<tom-select.tom-select/dist/types/types.TomSettings> */
  @js.native
  trait RecursivePartialTomSettinAddPrecedence extends StObject {
    
    var addPrecedence: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var allowEmptyOption: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var closeAfterSelect: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var controlClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var controlInput: js.UndefOr[RecursivePartial[String | HTMLInputElement]] = js.native
    
    var copyClassesToDropdown: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var create: js.UndefOr[RecursivePartial[Boolean | TomCreate]] = js.native
    
    var createFilter: js.UndefOr[RecursivePartial[js.RegExp | String | TomCreateFilter]] = js.native
    
    var createOnBlur: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var dataAttr: js.UndefOr[RecursivePartial[String]] = js.native
    
    var delimiter: js.UndefOr[RecursivePartial[String]] = js.native
    
    var diacritics: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var disabledField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownContentClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownParent: js.UndefOr[RecursivePartial[String]] = js.native
    
    var duplicates: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var firstUrl: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Any]]] = js.native
    
    var hidePlaceholder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var hideSelected: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var highlight: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var itemClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var items: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[String]]]] = js.native
    
    var labelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var load: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]
      ] = js.native
    
    var loadThrottle: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var loadingClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var lockOptgroupOrder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var maxItems: js.UndefOr[RecursivePartial[Null | Double]] = js.native
    
    var maxOptions: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var mode: js.UndefOr[RecursivePartial[String]] = js.native
    
    var nesting: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var onBlur: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onChange: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onDelete: js.UndefOr[
        RecursivePartial[
          js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
        ]
      ] = js.native
    
    var onDropdownClose: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onDropdownOpen: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onFocus: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onInitialize: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onItemAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onItemRemove: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onLoad: js.UndefOr[
        RecursivePartial[
          js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
        ]
      ] = js.native
    
    var onOptionAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionGroupClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onOptionRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onType: js.UndefOr[RecursivePartial[js.Function1[/* str */ String, Unit]]] = js.native
    
    var openOnFocus: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var optgroupField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupLabelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupValueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroups: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var optionClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var options: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var persist: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var placeholder: js.UndefOr[RecursivePartial[String]] = js.native
    
    var plugins: js.UndefOr[RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]] = js.native
    
    var preload: js.UndefOr[RecursivePartial[Boolean | String]] = js.native
    
    var render: js.UndefOr[RecursivePartialTomTempla] = js.native
    
    var score: js.UndefOr[
        RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]
      ] = js.native
    
    var searchConjunction: js.UndefOr[RecursivePartial[String]] = js.native
    
    var searchField: js.UndefOr[RecursivePartialArraystriConcat] = js.native
    
    var selectOnTab: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var shouldLoad: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Boolean]]] = js.native
    
    var shouldLoadMore: js.UndefOr[RecursivePartial[js.Function0[Boolean]]] = js.native
    
    var shouldOpen: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var sortField: js.UndefOr[
        RecursivePartial[
          String | (js.Array[
            /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
          ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
        ]
      ] = js.native
    
    var splitOn: js.UndefOr[RecursivePartial[js.RegExp | String]] = js.native
    
    var valueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var wrapperClass: js.UndefOr[RecursivePartial[String]] = js.native
  }
  object RecursivePartialTomSettinAddPrecedence {
    
    @scala.inline
    def apply(): RecursivePartialTomSettinAddPrecedence = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialTomSettinAddPrecedence]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialTomSettinAddPrecedence] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setAddPrecedence(value: RecursivePartial[Boolean]): Self = StObject.set(x, "addPrecedence", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAddPrecedenceUndefined: Self = StObject.set(x, "addPrecedence", js.undefined)
      
      @scala.inline
      def setAllowEmptyOption(value: RecursivePartial[Boolean]): Self = StObject.set(x, "allowEmptyOption", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAllowEmptyOptionUndefined: Self = StObject.set(x, "allowEmptyOption", js.undefined)
      
      @scala.inline
      def setCloseAfterSelect(value: RecursivePartial[Boolean]): Self = StObject.set(x, "closeAfterSelect", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCloseAfterSelectUndefined: Self = StObject.set(x, "closeAfterSelect", js.undefined)
      
      @scala.inline
      def setControlClass(value: RecursivePartial[String]): Self = StObject.set(x, "controlClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlClassUndefined: Self = StObject.set(x, "controlClass", js.undefined)
      
      @scala.inline
      def setControlInput(value: RecursivePartial[String | HTMLInputElement]): Self = StObject.set(x, "controlInput", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlInputUndefined: Self = StObject.set(x, "controlInput", js.undefined)
      
      @scala.inline
      def setCopyClassesToDropdown(value: RecursivePartial[Boolean]): Self = StObject.set(x, "copyClassesToDropdown", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyClassesToDropdownUndefined: Self = StObject.set(x, "copyClassesToDropdown", js.undefined)
      
      @scala.inline
      def setCreate(value: RecursivePartial[Boolean | TomCreate]): Self = StObject.set(x, "create", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilter(value: RecursivePartial[js.RegExp | String | TomCreateFilter]): Self = StObject.set(x, "createFilter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilterUndefined: Self = StObject.set(x, "createFilter", js.undefined)
      
      @scala.inline
      def setCreateOnBlur(value: RecursivePartial[Boolean]): Self = StObject.set(x, "createOnBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateOnBlurUndefined: Self = StObject.set(x, "createOnBlur", js.undefined)
      
      @scala.inline
      def setCreateUndefined: Self = StObject.set(x, "create", js.undefined)
      
      @scala.inline
      def setDataAttr(value: RecursivePartial[String]): Self = StObject.set(x, "dataAttr", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDataAttrUndefined: Self = StObject.set(x, "dataAttr", js.undefined)
      
      @scala.inline
      def setDelimiter(value: RecursivePartial[String]): Self = StObject.set(x, "delimiter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDelimiterUndefined: Self = StObject.set(x, "delimiter", js.undefined)
      
      @scala.inline
      def setDiacritics(value: RecursivePartial[Boolean]): Self = StObject.set(x, "diacritics", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDiacriticsUndefined: Self = StObject.set(x, "diacritics", js.undefined)
      
      @scala.inline
      def setDisabledField(value: RecursivePartial[String]): Self = StObject.set(x, "disabledField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDisabledFieldUndefined: Self = StObject.set(x, "disabledField", js.undefined)
      
      @scala.inline
      def setDropdownClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownClassUndefined: Self = StObject.set(x, "dropdownClass", js.undefined)
      
      @scala.inline
      def setDropdownContentClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownContentClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownContentClassUndefined: Self = StObject.set(x, "dropdownContentClass", js.undefined)
      
      @scala.inline
      def setDropdownParent(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownParent", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownParentUndefined: Self = StObject.set(x, "dropdownParent", js.undefined)
      
      @scala.inline
      def setDuplicates(value: RecursivePartial[Boolean]): Self = StObject.set(x, "duplicates", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDuplicatesUndefined: Self = StObject.set(x, "duplicates", js.undefined)
      
      @scala.inline
      def setFirstUrl(value: RecursivePartial[js.Function1[/* query */ String, Any]]): Self = StObject.set(x, "firstUrl", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFirstUrlUndefined: Self = StObject.set(x, "firstUrl", js.undefined)
      
      @scala.inline
      def setHidePlaceholder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hidePlaceholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHidePlaceholderUndefined: Self = StObject.set(x, "hidePlaceholder", js.undefined)
      
      @scala.inline
      def setHideSelected(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hideSelected", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHideSelectedUndefined: Self = StObject.set(x, "hideSelected", js.undefined)
      
      @scala.inline
      def setHighlight(value: RecursivePartial[Boolean]): Self = StObject.set(x, "highlight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHighlightUndefined: Self = StObject.set(x, "highlight", js.undefined)
      
      @scala.inline
      def setItemClass(value: RecursivePartial[String]): Self = StObject.set(x, "itemClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemClassUndefined: Self = StObject.set(x, "itemClass", js.undefined)
      
      @scala.inline
      def setItems(value: RecursivePartial[js.UndefOr[js.Array[String]]]): Self = StObject.set(x, "items", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemsUndefined: Self = StObject.set(x, "items", js.undefined)
      
      @scala.inline
      def setLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "labelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLabelFieldUndefined: Self = StObject.set(x, "labelField", js.undefined)
      
      @scala.inline
      def setLoad(value: RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]): Self = StObject.set(x, "load", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottle(value: RecursivePartial[Double]): Self = StObject.set(x, "loadThrottle", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottleUndefined: Self = StObject.set(x, "loadThrottle", js.undefined)
      
      @scala.inline
      def setLoadUndefined: Self = StObject.set(x, "load", js.undefined)
      
      @scala.inline
      def setLoadingClass(value: RecursivePartial[String]): Self = StObject.set(x, "loadingClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadingClassUndefined: Self = StObject.set(x, "loadingClass", js.undefined)
      
      @scala.inline
      def setLockOptgroupOrder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "lockOptgroupOrder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLockOptgroupOrderUndefined: Self = StObject.set(x, "lockOptgroupOrder", js.undefined)
      
      @scala.inline
      def setMaxItems(value: RecursivePartial[Null | Double]): Self = StObject.set(x, "maxItems", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxItemsUndefined: Self = StObject.set(x, "maxItems", js.undefined)
      
      @scala.inline
      def setMaxOptions(value: RecursivePartial[Double]): Self = StObject.set(x, "maxOptions", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxOptionsUndefined: Self = StObject.set(x, "maxOptions", js.undefined)
      
      @scala.inline
      def setMode(value: RecursivePartial[String]): Self = StObject.set(x, "mode", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setModeUndefined: Self = StObject.set(x, "mode", js.undefined)
      
      @scala.inline
      def setNesting(value: RecursivePartial[Boolean]): Self = StObject.set(x, "nesting", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setNestingUndefined: Self = StObject.set(x, "nesting", js.undefined)
      
      @scala.inline
      def setOnBlur(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnBlurUndefined: Self = StObject.set(x, "onBlur", js.undefined)
      
      @scala.inline
      def setOnChange(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onChange", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnChangeUndefined: Self = StObject.set(x, "onChange", js.undefined)
      
      @scala.inline
      def setOnClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnClearUndefined: Self = StObject.set(x, "onClear", js.undefined)
      
      @scala.inline
      def setOnDelete(
        value: RecursivePartial[
              js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
            ]
      ): Self = StObject.set(x, "onDelete", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDeleteUndefined: Self = StObject.set(x, "onDelete", js.undefined)
      
      @scala.inline
      def setOnDropdownClose(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownClose", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownCloseUndefined: Self = StObject.set(x, "onDropdownClose", js.undefined)
      
      @scala.inline
      def setOnDropdownOpen(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownOpenUndefined: Self = StObject.set(x, "onDropdownOpen", js.undefined)
      
      @scala.inline
      def setOnFocus(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnFocusUndefined: Self = StObject.set(x, "onFocus", js.undefined)
      
      @scala.inline
      def setOnInitialize(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onInitialize", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnInitializeUndefined: Self = StObject.set(x, "onInitialize", js.undefined)
      
      @scala.inline
      def setOnItemAdd(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemAddUndefined: Self = StObject.set(x, "onItemAdd", js.undefined)
      
      @scala.inline
      def setOnItemRemove(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemRemoveUndefined: Self = StObject.set(x, "onItemRemove", js.undefined)
      
      @scala.inline
      def setOnLoad(
        value: RecursivePartial[
              js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
            ]
      ): Self = StObject.set(x, "onLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnLoadUndefined: Self = StObject.set(x, "onLoad", js.undefined)
      
      @scala.inline
      def setOnOptionAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionAddUndefined: Self = StObject.set(x, "onOptionAdd", js.undefined)
      
      @scala.inline
      def setOnOptionClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionClearUndefined: Self = StObject.set(x, "onOptionClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionGroupAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupAddUndefined: Self = StObject.set(x, "onOptionGroupAdd", js.undefined)
      
      @scala.inline
      def setOnOptionGroupClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionGroupClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupClearUndefined: Self = StObject.set(x, "onOptionGroupClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionGroupRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupRemoveUndefined: Self = StObject.set(x, "onOptionGroupRemove", js.undefined)
      
      @scala.inline
      def setOnOptionRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionRemoveUndefined: Self = StObject.set(x, "onOptionRemove", js.undefined)
      
      @scala.inline
      def setOnType(value: RecursivePartial[js.Function1[/* str */ String, Unit]]): Self = StObject.set(x, "onType", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnTypeUndefined: Self = StObject.set(x, "onType", js.undefined)
      
      @scala.inline
      def setOpenOnFocus(value: RecursivePartial[Boolean]): Self = StObject.set(x, "openOnFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOpenOnFocusUndefined: Self = StObject.set(x, "openOnFocus", js.undefined)
      
      @scala.inline
      def setOptgroupField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupFieldUndefined: Self = StObject.set(x, "optgroupField", js.undefined)
      
      @scala.inline
      def setOptgroupLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupLabelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupLabelFieldUndefined: Self = StObject.set(x, "optgroupLabelField", js.undefined)
      
      @scala.inline
      def setOptgroupValueField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupValueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupValueFieldUndefined: Self = StObject.set(x, "optgroupValueField", js.undefined)
      
      @scala.inline
      def setOptgroups(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "optgroups", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupsUndefined: Self = StObject.set(x, "optgroups", js.undefined)
      
      @scala.inline
      def setOptionClass(value: RecursivePartial[String]): Self = StObject.set(x, "optionClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionClassUndefined: Self = StObject.set(x, "optionClass", js.undefined)
      
      @scala.inline
      def setOptions(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "options", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionsUndefined: Self = StObject.set(x, "options", js.undefined)
      
      @scala.inline
      def setPersist(value: RecursivePartial[Boolean]): Self = StObject.set(x, "persist", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPersistUndefined: Self = StObject.set(x, "persist", js.undefined)
      
      @scala.inline
      def setPlaceholder(value: RecursivePartial[String]): Self = StObject.set(x, "placeholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPlaceholderUndefined: Self = StObject.set(x, "placeholder", js.undefined)
      
      @scala.inline
      def setPlugins(value: RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]): Self = StObject.set(x, "plugins", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPluginsUndefined: Self = StObject.set(x, "plugins", js.undefined)
      
      @scala.inline
      def setPreload(value: RecursivePartial[Boolean | String]): Self = StObject.set(x, "preload", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPreloadUndefined: Self = StObject.set(x, "preload", js.undefined)
      
      @scala.inline
      def setRender(value: RecursivePartialTomTempla): Self = StObject.set(x, "render", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setRenderUndefined: Self = StObject.set(x, "render", js.undefined)
      
      @scala.inline
      def setScore(value: RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]): Self = StObject.set(x, "score", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setScoreUndefined: Self = StObject.set(x, "score", js.undefined)
      
      @scala.inline
      def setSearchConjunction(value: RecursivePartial[String]): Self = StObject.set(x, "searchConjunction", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchConjunctionUndefined: Self = StObject.set(x, "searchConjunction", js.undefined)
      
      @scala.inline
      def setSearchField(value: RecursivePartialArraystriConcat): Self = StObject.set(x, "searchField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchFieldUndefined: Self = StObject.set(x, "searchField", js.undefined)
      
      @scala.inline
      def setSelectOnTab(value: RecursivePartial[Boolean]): Self = StObject.set(x, "selectOnTab", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSelectOnTabUndefined: Self = StObject.set(x, "selectOnTab", js.undefined)
      
      @scala.inline
      def setShouldLoad(value: RecursivePartial[js.Function1[/* query */ String, Boolean]]): Self = StObject.set(x, "shouldLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMore(value: RecursivePartial[js.Function0[Boolean]]): Self = StObject.set(x, "shouldLoadMore", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMoreUndefined: Self = StObject.set(x, "shouldLoadMore", js.undefined)
      
      @scala.inline
      def setShouldLoadUndefined: Self = StObject.set(x, "shouldLoad", js.undefined)
      
      @scala.inline
      def setShouldOpen(value: RecursivePartial[Boolean]): Self = StObject.set(x, "shouldOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldOpenUndefined: Self = StObject.set(x, "shouldOpen", js.undefined)
      
      @scala.inline
      def setSortField(
        value: RecursivePartial[
              String | (js.Array[
                /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
              ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
            ]
      ): Self = StObject.set(x, "sortField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortFieldUndefined: Self = StObject.set(x, "sortField", js.undefined)
      
      @scala.inline
      def setSplitOn(value: RecursivePartial[js.RegExp | String]): Self = StObject.set(x, "splitOn", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSplitOnUndefined: Self = StObject.set(x, "splitOn", js.undefined)
      
      @scala.inline
      def setValueField(value: RecursivePartial[String]): Self = StObject.set(x, "valueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValueFieldUndefined: Self = StObject.set(x, "valueField", js.undefined)
      
      @scala.inline
      def setWrapperClass(value: RecursivePartial[String]): Self = StObject.set(x, "wrapperClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setWrapperClassUndefined: Self = StObject.set(x, "wrapperClass", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types.RecursivePartial<tom-select.tom-select/dist/types/types.TomSettings> */
  @js.native
  trait RecursivePartialTomSettinAllowEmptyOption extends StObject {
    
    var addPrecedence: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var allowEmptyOption: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var closeAfterSelect: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var controlClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var controlInput: js.UndefOr[RecursivePartial[String | HTMLInputElement]] = js.native
    
    var copyClassesToDropdown: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var create: js.UndefOr[RecursivePartial[Boolean | TomCreate]] = js.native
    
    var createFilter: js.UndefOr[RecursivePartial[js.RegExp | String | TomCreateFilter]] = js.native
    
    var createOnBlur: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var dataAttr: js.UndefOr[RecursivePartial[String]] = js.native
    
    var delimiter: js.UndefOr[RecursivePartial[String]] = js.native
    
    var diacritics: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var disabledField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownContentClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownParent: js.UndefOr[RecursivePartial[String]] = js.native
    
    var duplicates: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var firstUrl: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Any]]] = js.native
    
    var hidePlaceholder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var hideSelected: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var highlight: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var itemClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var items: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[String]]]] = js.native
    
    var labelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var load: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]
      ] = js.native
    
    var loadThrottle: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var loadingClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var lockOptgroupOrder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var maxItems: js.UndefOr[RecursivePartial[Null | Double]] = js.native
    
    var maxOptions: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var mode: js.UndefOr[RecursivePartial[String]] = js.native
    
    var nesting: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var onBlur: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onChange: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onDelete: js.UndefOr[
        RecursivePartial[
          js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
        ]
      ] = js.native
    
    var onDropdownClose: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onDropdownOpen: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onFocus: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onInitialize: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onItemAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onItemRemove: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onLoad: js.UndefOr[
        RecursivePartial[
          js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
        ]
      ] = js.native
    
    var onOptionAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionGroupClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onOptionRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onType: js.UndefOr[RecursivePartial[js.Function1[/* str */ String, Unit]]] = js.native
    
    var openOnFocus: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var optgroupField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupLabelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupValueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroups: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var optionClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var options: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var persist: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var placeholder: js.UndefOr[RecursivePartial[String]] = js.native
    
    var plugins: js.UndefOr[RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]] = js.native
    
    var preload: js.UndefOr[RecursivePartial[Boolean | String]] = js.native
    
    var render: js.UndefOr[RecursivePartialTomTempla] = js.native
    
    var score: js.UndefOr[
        RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]
      ] = js.native
    
    var searchConjunction: js.UndefOr[RecursivePartial[String]] = js.native
    
    var searchField: js.UndefOr[RecursivePartialArraystriCopyWithin] = js.native
    
    var selectOnTab: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var shouldLoad: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Boolean]]] = js.native
    
    var shouldLoadMore: js.UndefOr[RecursivePartial[js.Function0[Boolean]]] = js.native
    
    var shouldOpen: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var sortField: js.UndefOr[
        RecursivePartial[
          String | (js.Array[
            /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
          ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
        ]
      ] = js.native
    
    var splitOn: js.UndefOr[RecursivePartial[js.RegExp | String]] = js.native
    
    var valueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var wrapperClass: js.UndefOr[RecursivePartial[String]] = js.native
  }
  object RecursivePartialTomSettinAllowEmptyOption {
    
    @scala.inline
    def apply(): RecursivePartialTomSettinAllowEmptyOption = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialTomSettinAllowEmptyOption]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialTomSettinAllowEmptyOption] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setAddPrecedence(value: RecursivePartial[Boolean]): Self = StObject.set(x, "addPrecedence", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAddPrecedenceUndefined: Self = StObject.set(x, "addPrecedence", js.undefined)
      
      @scala.inline
      def setAllowEmptyOption(value: RecursivePartial[Boolean]): Self = StObject.set(x, "allowEmptyOption", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAllowEmptyOptionUndefined: Self = StObject.set(x, "allowEmptyOption", js.undefined)
      
      @scala.inline
      def setCloseAfterSelect(value: RecursivePartial[Boolean]): Self = StObject.set(x, "closeAfterSelect", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCloseAfterSelectUndefined: Self = StObject.set(x, "closeAfterSelect", js.undefined)
      
      @scala.inline
      def setControlClass(value: RecursivePartial[String]): Self = StObject.set(x, "controlClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlClassUndefined: Self = StObject.set(x, "controlClass", js.undefined)
      
      @scala.inline
      def setControlInput(value: RecursivePartial[String | HTMLInputElement]): Self = StObject.set(x, "controlInput", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlInputUndefined: Self = StObject.set(x, "controlInput", js.undefined)
      
      @scala.inline
      def setCopyClassesToDropdown(value: RecursivePartial[Boolean]): Self = StObject.set(x, "copyClassesToDropdown", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyClassesToDropdownUndefined: Self = StObject.set(x, "copyClassesToDropdown", js.undefined)
      
      @scala.inline
      def setCreate(value: RecursivePartial[Boolean | TomCreate]): Self = StObject.set(x, "create", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilter(value: RecursivePartial[js.RegExp | String | TomCreateFilter]): Self = StObject.set(x, "createFilter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilterUndefined: Self = StObject.set(x, "createFilter", js.undefined)
      
      @scala.inline
      def setCreateOnBlur(value: RecursivePartial[Boolean]): Self = StObject.set(x, "createOnBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateOnBlurUndefined: Self = StObject.set(x, "createOnBlur", js.undefined)
      
      @scala.inline
      def setCreateUndefined: Self = StObject.set(x, "create", js.undefined)
      
      @scala.inline
      def setDataAttr(value: RecursivePartial[String]): Self = StObject.set(x, "dataAttr", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDataAttrUndefined: Self = StObject.set(x, "dataAttr", js.undefined)
      
      @scala.inline
      def setDelimiter(value: RecursivePartial[String]): Self = StObject.set(x, "delimiter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDelimiterUndefined: Self = StObject.set(x, "delimiter", js.undefined)
      
      @scala.inline
      def setDiacritics(value: RecursivePartial[Boolean]): Self = StObject.set(x, "diacritics", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDiacriticsUndefined: Self = StObject.set(x, "diacritics", js.undefined)
      
      @scala.inline
      def setDisabledField(value: RecursivePartial[String]): Self = StObject.set(x, "disabledField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDisabledFieldUndefined: Self = StObject.set(x, "disabledField", js.undefined)
      
      @scala.inline
      def setDropdownClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownClassUndefined: Self = StObject.set(x, "dropdownClass", js.undefined)
      
      @scala.inline
      def setDropdownContentClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownContentClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownContentClassUndefined: Self = StObject.set(x, "dropdownContentClass", js.undefined)
      
      @scala.inline
      def setDropdownParent(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownParent", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownParentUndefined: Self = StObject.set(x, "dropdownParent", js.undefined)
      
      @scala.inline
      def setDuplicates(value: RecursivePartial[Boolean]): Self = StObject.set(x, "duplicates", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDuplicatesUndefined: Self = StObject.set(x, "duplicates", js.undefined)
      
      @scala.inline
      def setFirstUrl(value: RecursivePartial[js.Function1[/* query */ String, Any]]): Self = StObject.set(x, "firstUrl", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFirstUrlUndefined: Self = StObject.set(x, "firstUrl", js.undefined)
      
      @scala.inline
      def setHidePlaceholder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hidePlaceholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHidePlaceholderUndefined: Self = StObject.set(x, "hidePlaceholder", js.undefined)
      
      @scala.inline
      def setHideSelected(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hideSelected", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHideSelectedUndefined: Self = StObject.set(x, "hideSelected", js.undefined)
      
      @scala.inline
      def setHighlight(value: RecursivePartial[Boolean]): Self = StObject.set(x, "highlight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHighlightUndefined: Self = StObject.set(x, "highlight", js.undefined)
      
      @scala.inline
      def setItemClass(value: RecursivePartial[String]): Self = StObject.set(x, "itemClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemClassUndefined: Self = StObject.set(x, "itemClass", js.undefined)
      
      @scala.inline
      def setItems(value: RecursivePartial[js.UndefOr[js.Array[String]]]): Self = StObject.set(x, "items", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemsUndefined: Self = StObject.set(x, "items", js.undefined)
      
      @scala.inline
      def setLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "labelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLabelFieldUndefined: Self = StObject.set(x, "labelField", js.undefined)
      
      @scala.inline
      def setLoad(value: RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]): Self = StObject.set(x, "load", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottle(value: RecursivePartial[Double]): Self = StObject.set(x, "loadThrottle", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottleUndefined: Self = StObject.set(x, "loadThrottle", js.undefined)
      
      @scala.inline
      def setLoadUndefined: Self = StObject.set(x, "load", js.undefined)
      
      @scala.inline
      def setLoadingClass(value: RecursivePartial[String]): Self = StObject.set(x, "loadingClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadingClassUndefined: Self = StObject.set(x, "loadingClass", js.undefined)
      
      @scala.inline
      def setLockOptgroupOrder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "lockOptgroupOrder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLockOptgroupOrderUndefined: Self = StObject.set(x, "lockOptgroupOrder", js.undefined)
      
      @scala.inline
      def setMaxItems(value: RecursivePartial[Null | Double]): Self = StObject.set(x, "maxItems", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxItemsUndefined: Self = StObject.set(x, "maxItems", js.undefined)
      
      @scala.inline
      def setMaxOptions(value: RecursivePartial[Double]): Self = StObject.set(x, "maxOptions", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxOptionsUndefined: Self = StObject.set(x, "maxOptions", js.undefined)
      
      @scala.inline
      def setMode(value: RecursivePartial[String]): Self = StObject.set(x, "mode", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setModeUndefined: Self = StObject.set(x, "mode", js.undefined)
      
      @scala.inline
      def setNesting(value: RecursivePartial[Boolean]): Self = StObject.set(x, "nesting", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setNestingUndefined: Self = StObject.set(x, "nesting", js.undefined)
      
      @scala.inline
      def setOnBlur(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnBlurUndefined: Self = StObject.set(x, "onBlur", js.undefined)
      
      @scala.inline
      def setOnChange(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onChange", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnChangeUndefined: Self = StObject.set(x, "onChange", js.undefined)
      
      @scala.inline
      def setOnClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnClearUndefined: Self = StObject.set(x, "onClear", js.undefined)
      
      @scala.inline
      def setOnDelete(
        value: RecursivePartial[
              js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
            ]
      ): Self = StObject.set(x, "onDelete", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDeleteUndefined: Self = StObject.set(x, "onDelete", js.undefined)
      
      @scala.inline
      def setOnDropdownClose(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownClose", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownCloseUndefined: Self = StObject.set(x, "onDropdownClose", js.undefined)
      
      @scala.inline
      def setOnDropdownOpen(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownOpenUndefined: Self = StObject.set(x, "onDropdownOpen", js.undefined)
      
      @scala.inline
      def setOnFocus(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnFocusUndefined: Self = StObject.set(x, "onFocus", js.undefined)
      
      @scala.inline
      def setOnInitialize(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onInitialize", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnInitializeUndefined: Self = StObject.set(x, "onInitialize", js.undefined)
      
      @scala.inline
      def setOnItemAdd(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemAddUndefined: Self = StObject.set(x, "onItemAdd", js.undefined)
      
      @scala.inline
      def setOnItemRemove(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemRemoveUndefined: Self = StObject.set(x, "onItemRemove", js.undefined)
      
      @scala.inline
      def setOnLoad(
        value: RecursivePartial[
              js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
            ]
      ): Self = StObject.set(x, "onLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnLoadUndefined: Self = StObject.set(x, "onLoad", js.undefined)
      
      @scala.inline
      def setOnOptionAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionAddUndefined: Self = StObject.set(x, "onOptionAdd", js.undefined)
      
      @scala.inline
      def setOnOptionClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionClearUndefined: Self = StObject.set(x, "onOptionClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionGroupAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupAddUndefined: Self = StObject.set(x, "onOptionGroupAdd", js.undefined)
      
      @scala.inline
      def setOnOptionGroupClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionGroupClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupClearUndefined: Self = StObject.set(x, "onOptionGroupClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionGroupRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupRemoveUndefined: Self = StObject.set(x, "onOptionGroupRemove", js.undefined)
      
      @scala.inline
      def setOnOptionRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionRemoveUndefined: Self = StObject.set(x, "onOptionRemove", js.undefined)
      
      @scala.inline
      def setOnType(value: RecursivePartial[js.Function1[/* str */ String, Unit]]): Self = StObject.set(x, "onType", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnTypeUndefined: Self = StObject.set(x, "onType", js.undefined)
      
      @scala.inline
      def setOpenOnFocus(value: RecursivePartial[Boolean]): Self = StObject.set(x, "openOnFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOpenOnFocusUndefined: Self = StObject.set(x, "openOnFocus", js.undefined)
      
      @scala.inline
      def setOptgroupField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupFieldUndefined: Self = StObject.set(x, "optgroupField", js.undefined)
      
      @scala.inline
      def setOptgroupLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupLabelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupLabelFieldUndefined: Self = StObject.set(x, "optgroupLabelField", js.undefined)
      
      @scala.inline
      def setOptgroupValueField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupValueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupValueFieldUndefined: Self = StObject.set(x, "optgroupValueField", js.undefined)
      
      @scala.inline
      def setOptgroups(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "optgroups", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupsUndefined: Self = StObject.set(x, "optgroups", js.undefined)
      
      @scala.inline
      def setOptionClass(value: RecursivePartial[String]): Self = StObject.set(x, "optionClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionClassUndefined: Self = StObject.set(x, "optionClass", js.undefined)
      
      @scala.inline
      def setOptions(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "options", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionsUndefined: Self = StObject.set(x, "options", js.undefined)
      
      @scala.inline
      def setPersist(value: RecursivePartial[Boolean]): Self = StObject.set(x, "persist", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPersistUndefined: Self = StObject.set(x, "persist", js.undefined)
      
      @scala.inline
      def setPlaceholder(value: RecursivePartial[String]): Self = StObject.set(x, "placeholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPlaceholderUndefined: Self = StObject.set(x, "placeholder", js.undefined)
      
      @scala.inline
      def setPlugins(value: RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]): Self = StObject.set(x, "plugins", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPluginsUndefined: Self = StObject.set(x, "plugins", js.undefined)
      
      @scala.inline
      def setPreload(value: RecursivePartial[Boolean | String]): Self = StObject.set(x, "preload", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPreloadUndefined: Self = StObject.set(x, "preload", js.undefined)
      
      @scala.inline
      def setRender(value: RecursivePartialTomTempla): Self = StObject.set(x, "render", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setRenderUndefined: Self = StObject.set(x, "render", js.undefined)
      
      @scala.inline
      def setScore(value: RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]): Self = StObject.set(x, "score", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setScoreUndefined: Self = StObject.set(x, "score", js.undefined)
      
      @scala.inline
      def setSearchConjunction(value: RecursivePartial[String]): Self = StObject.set(x, "searchConjunction", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchConjunctionUndefined: Self = StObject.set(x, "searchConjunction", js.undefined)
      
      @scala.inline
      def setSearchField(value: RecursivePartialArraystriCopyWithin): Self = StObject.set(x, "searchField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchFieldUndefined: Self = StObject.set(x, "searchField", js.undefined)
      
      @scala.inline
      def setSelectOnTab(value: RecursivePartial[Boolean]): Self = StObject.set(x, "selectOnTab", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSelectOnTabUndefined: Self = StObject.set(x, "selectOnTab", js.undefined)
      
      @scala.inline
      def setShouldLoad(value: RecursivePartial[js.Function1[/* query */ String, Boolean]]): Self = StObject.set(x, "shouldLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMore(value: RecursivePartial[js.Function0[Boolean]]): Self = StObject.set(x, "shouldLoadMore", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMoreUndefined: Self = StObject.set(x, "shouldLoadMore", js.undefined)
      
      @scala.inline
      def setShouldLoadUndefined: Self = StObject.set(x, "shouldLoad", js.undefined)
      
      @scala.inline
      def setShouldOpen(value: RecursivePartial[Boolean]): Self = StObject.set(x, "shouldOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldOpenUndefined: Self = StObject.set(x, "shouldOpen", js.undefined)
      
      @scala.inline
      def setSortField(
        value: RecursivePartial[
              String | (js.Array[
                /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
              ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
            ]
      ): Self = StObject.set(x, "sortField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortFieldUndefined: Self = StObject.set(x, "sortField", js.undefined)
      
      @scala.inline
      def setSplitOn(value: RecursivePartial[js.RegExp | String]): Self = StObject.set(x, "splitOn", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSplitOnUndefined: Self = StObject.set(x, "splitOn", js.undefined)
      
      @scala.inline
      def setValueField(value: RecursivePartial[String]): Self = StObject.set(x, "valueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValueFieldUndefined: Self = StObject.set(x, "valueField", js.undefined)
      
      @scala.inline
      def setWrapperClass(value: RecursivePartial[String]): Self = StObject.set(x, "wrapperClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setWrapperClassUndefined: Self = StObject.set(x, "wrapperClass", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types.RecursivePartial<tom-select.tom-select/dist/types/types.TomSettings> */
  @js.native
  trait RecursivePartialTomSettinCloseAfterSelect extends StObject {
    
    var addPrecedence: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var allowEmptyOption: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var closeAfterSelect: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var controlClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var controlInput: js.UndefOr[RecursivePartial[String | HTMLInputElement]] = js.native
    
    var copyClassesToDropdown: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var create: js.UndefOr[RecursivePartial[Boolean | TomCreate]] = js.native
    
    var createFilter: js.UndefOr[RecursivePartial[js.RegExp | String | TomCreateFilter]] = js.native
    
    var createOnBlur: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var dataAttr: js.UndefOr[RecursivePartial[String]] = js.native
    
    var delimiter: js.UndefOr[RecursivePartial[String]] = js.native
    
    var diacritics: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var disabledField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownContentClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var dropdownParent: js.UndefOr[RecursivePartial[String]] = js.native
    
    var duplicates: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var firstUrl: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Any]]] = js.native
    
    var hidePlaceholder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var hideSelected: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var highlight: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var itemClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var items: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[String]]]] = js.native
    
    var labelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var load: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]
      ] = js.native
    
    var loadThrottle: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var loadingClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var lockOptgroupOrder: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var maxItems: js.UndefOr[RecursivePartial[Null | Double]] = js.native
    
    var maxOptions: js.UndefOr[RecursivePartial[Double]] = js.native
    
    var mode: js.UndefOr[RecursivePartial[String]] = js.native
    
    var nesting: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var onBlur: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onChange: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onDelete: js.UndefOr[
        RecursivePartial[
          js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
        ]
      ] = js.native
    
    var onDropdownClose: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onDropdownOpen: js.UndefOr[RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]] = js.native
    
    var onFocus: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onInitialize: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onItemAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onItemRemove: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ] = js.native
    
    var onLoad: js.UndefOr[
        RecursivePartial[
          js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
        ]
      ] = js.native
    
    var onOptionAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupAdd: js.UndefOr[
        RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]
      ] = js.native
    
    var onOptionGroupClear: js.UndefOr[RecursivePartial[js.Function0[Unit]]] = js.native
    
    var onOptionGroupRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onOptionRemove: js.UndefOr[RecursivePartial[js.Function1[/* value */ String | Double, Unit]]] = js.native
    
    var onType: js.UndefOr[RecursivePartial[js.Function1[/* str */ String, Unit]]] = js.native
    
    var openOnFocus: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var optgroupField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupLabelField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroupValueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var optgroups: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var optionClass: js.UndefOr[RecursivePartial[String]] = js.native
    
    var options: js.UndefOr[RecursivePartial[js.UndefOr[js.Array[Any]]]] = js.native
    
    var persist: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var placeholder: js.UndefOr[RecursivePartial[String]] = js.native
    
    var plugins: js.UndefOr[RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]] = js.native
    
    var preload: js.UndefOr[RecursivePartial[Boolean | String]] = js.native
    
    var render: js.UndefOr[RecursivePartialTomTempla] = js.native
    
    var score: js.UndefOr[
        RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]
      ] = js.native
    
    var searchConjunction: js.UndefOr[RecursivePartial[String]] = js.native
    
    var searchField: js.UndefOr[RecursivePartialArraystriEntries] = js.native
    
    var selectOnTab: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var shouldLoad: js.UndefOr[RecursivePartial[js.Function1[/* query */ String, Boolean]]] = js.native
    
    var shouldLoadMore: js.UndefOr[RecursivePartial[js.Function0[Boolean]]] = js.native
    
    var shouldOpen: js.UndefOr[RecursivePartial[Boolean]] = js.native
    
    var sortField: js.UndefOr[
        RecursivePartial[
          String | (js.Array[
            /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
          ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
        ]
      ] = js.native
    
    var splitOn: js.UndefOr[RecursivePartial[js.RegExp | String]] = js.native
    
    var valueField: js.UndefOr[RecursivePartial[String]] = js.native
    
    var wrapperClass: js.UndefOr[RecursivePartial[String]] = js.native
  }
  object RecursivePartialTomSettinCloseAfterSelect {
    
    @scala.inline
    def apply(): RecursivePartialTomSettinCloseAfterSelect = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialTomSettinCloseAfterSelect]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialTomSettinCloseAfterSelect] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setAddPrecedence(value: RecursivePartial[Boolean]): Self = StObject.set(x, "addPrecedence", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAddPrecedenceUndefined: Self = StObject.set(x, "addPrecedence", js.undefined)
      
      @scala.inline
      def setAllowEmptyOption(value: RecursivePartial[Boolean]): Self = StObject.set(x, "allowEmptyOption", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setAllowEmptyOptionUndefined: Self = StObject.set(x, "allowEmptyOption", js.undefined)
      
      @scala.inline
      def setCloseAfterSelect(value: RecursivePartial[Boolean]): Self = StObject.set(x, "closeAfterSelect", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCloseAfterSelectUndefined: Self = StObject.set(x, "closeAfterSelect", js.undefined)
      
      @scala.inline
      def setControlClass(value: RecursivePartial[String]): Self = StObject.set(x, "controlClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlClassUndefined: Self = StObject.set(x, "controlClass", js.undefined)
      
      @scala.inline
      def setControlInput(value: RecursivePartial[String | HTMLInputElement]): Self = StObject.set(x, "controlInput", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setControlInputUndefined: Self = StObject.set(x, "controlInput", js.undefined)
      
      @scala.inline
      def setCopyClassesToDropdown(value: RecursivePartial[Boolean]): Self = StObject.set(x, "copyClassesToDropdown", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCopyClassesToDropdownUndefined: Self = StObject.set(x, "copyClassesToDropdown", js.undefined)
      
      @scala.inline
      def setCreate(value: RecursivePartial[Boolean | TomCreate]): Self = StObject.set(x, "create", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilter(value: RecursivePartial[js.RegExp | String | TomCreateFilter]): Self = StObject.set(x, "createFilter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateFilterUndefined: Self = StObject.set(x, "createFilter", js.undefined)
      
      @scala.inline
      def setCreateOnBlur(value: RecursivePartial[Boolean]): Self = StObject.set(x, "createOnBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setCreateOnBlurUndefined: Self = StObject.set(x, "createOnBlur", js.undefined)
      
      @scala.inline
      def setCreateUndefined: Self = StObject.set(x, "create", js.undefined)
      
      @scala.inline
      def setDataAttr(value: RecursivePartial[String]): Self = StObject.set(x, "dataAttr", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDataAttrUndefined: Self = StObject.set(x, "dataAttr", js.undefined)
      
      @scala.inline
      def setDelimiter(value: RecursivePartial[String]): Self = StObject.set(x, "delimiter", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDelimiterUndefined: Self = StObject.set(x, "delimiter", js.undefined)
      
      @scala.inline
      def setDiacritics(value: RecursivePartial[Boolean]): Self = StObject.set(x, "diacritics", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDiacriticsUndefined: Self = StObject.set(x, "diacritics", js.undefined)
      
      @scala.inline
      def setDisabledField(value: RecursivePartial[String]): Self = StObject.set(x, "disabledField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDisabledFieldUndefined: Self = StObject.set(x, "disabledField", js.undefined)
      
      @scala.inline
      def setDropdownClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownClassUndefined: Self = StObject.set(x, "dropdownClass", js.undefined)
      
      @scala.inline
      def setDropdownContentClass(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownContentClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownContentClassUndefined: Self = StObject.set(x, "dropdownContentClass", js.undefined)
      
      @scala.inline
      def setDropdownParent(value: RecursivePartial[String]): Self = StObject.set(x, "dropdownParent", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownParentUndefined: Self = StObject.set(x, "dropdownParent", js.undefined)
      
      @scala.inline
      def setDuplicates(value: RecursivePartial[Boolean]): Self = StObject.set(x, "duplicates", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDuplicatesUndefined: Self = StObject.set(x, "duplicates", js.undefined)
      
      @scala.inline
      def setFirstUrl(value: RecursivePartial[js.Function1[/* query */ String, Any]]): Self = StObject.set(x, "firstUrl", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setFirstUrlUndefined: Self = StObject.set(x, "firstUrl", js.undefined)
      
      @scala.inline
      def setHidePlaceholder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hidePlaceholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHidePlaceholderUndefined: Self = StObject.set(x, "hidePlaceholder", js.undefined)
      
      @scala.inline
      def setHideSelected(value: RecursivePartial[Boolean]): Self = StObject.set(x, "hideSelected", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHideSelectedUndefined: Self = StObject.set(x, "hideSelected", js.undefined)
      
      @scala.inline
      def setHighlight(value: RecursivePartial[Boolean]): Self = StObject.set(x, "highlight", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setHighlightUndefined: Self = StObject.set(x, "highlight", js.undefined)
      
      @scala.inline
      def setItemClass(value: RecursivePartial[String]): Self = StObject.set(x, "itemClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemClassUndefined: Self = StObject.set(x, "itemClass", js.undefined)
      
      @scala.inline
      def setItems(value: RecursivePartial[js.UndefOr[js.Array[String]]]): Self = StObject.set(x, "items", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemsUndefined: Self = StObject.set(x, "items", js.undefined)
      
      @scala.inline
      def setLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "labelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLabelFieldUndefined: Self = StObject.set(x, "labelField", js.undefined)
      
      @scala.inline
      def setLoad(value: RecursivePartial[js.Function2[/* value */ String, /* callback */ TomLoadCallback, Unit]]): Self = StObject.set(x, "load", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottle(value: RecursivePartial[Double]): Self = StObject.set(x, "loadThrottle", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadThrottleUndefined: Self = StObject.set(x, "loadThrottle", js.undefined)
      
      @scala.inline
      def setLoadUndefined: Self = StObject.set(x, "load", js.undefined)
      
      @scala.inline
      def setLoadingClass(value: RecursivePartial[String]): Self = StObject.set(x, "loadingClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadingClassUndefined: Self = StObject.set(x, "loadingClass", js.undefined)
      
      @scala.inline
      def setLockOptgroupOrder(value: RecursivePartial[Boolean]): Self = StObject.set(x, "lockOptgroupOrder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLockOptgroupOrderUndefined: Self = StObject.set(x, "lockOptgroupOrder", js.undefined)
      
      @scala.inline
      def setMaxItems(value: RecursivePartial[Null | Double]): Self = StObject.set(x, "maxItems", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxItemsUndefined: Self = StObject.set(x, "maxItems", js.undefined)
      
      @scala.inline
      def setMaxOptions(value: RecursivePartial[Double]): Self = StObject.set(x, "maxOptions", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setMaxOptionsUndefined: Self = StObject.set(x, "maxOptions", js.undefined)
      
      @scala.inline
      def setMode(value: RecursivePartial[String]): Self = StObject.set(x, "mode", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setModeUndefined: Self = StObject.set(x, "mode", js.undefined)
      
      @scala.inline
      def setNesting(value: RecursivePartial[Boolean]): Self = StObject.set(x, "nesting", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setNestingUndefined: Self = StObject.set(x, "nesting", js.undefined)
      
      @scala.inline
      def setOnBlur(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onBlur", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnBlurUndefined: Self = StObject.set(x, "onBlur", js.undefined)
      
      @scala.inline
      def setOnChange(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onChange", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnChangeUndefined: Self = StObject.set(x, "onChange", js.undefined)
      
      @scala.inline
      def setOnClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnClearUndefined: Self = StObject.set(x, "onClear", js.undefined)
      
      @scala.inline
      def setOnDelete(
        value: RecursivePartial[
              js.Function2[/* values */ js.Array[String], /* evt */ KeyboardEvent | MouseEvent, Boolean]
            ]
      ): Self = StObject.set(x, "onDelete", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDeleteUndefined: Self = StObject.set(x, "onDelete", js.undefined)
      
      @scala.inline
      def setOnDropdownClose(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownClose", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownCloseUndefined: Self = StObject.set(x, "onDropdownClose", js.undefined)
      
      @scala.inline
      def setOnDropdownOpen(value: RecursivePartial[js.Function1[/* dropdown */ HTMLDivElement, Unit]]): Self = StObject.set(x, "onDropdownOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnDropdownOpenUndefined: Self = StObject.set(x, "onDropdownOpen", js.undefined)
      
      @scala.inline
      def setOnFocus(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnFocusUndefined: Self = StObject.set(x, "onFocus", js.undefined)
      
      @scala.inline
      def setOnInitialize(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onInitialize", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnInitializeUndefined: Self = StObject.set(x, "onInitialize", js.undefined)
      
      @scala.inline
      def setOnItemAdd(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemAddUndefined: Self = StObject.set(x, "onItemAdd", js.undefined)
      
      @scala.inline
      def setOnItemRemove(
        value: RecursivePartial[js.Function2[/* value */ String | Double, /* item */ HTMLDivElement, Unit]]
      ): Self = StObject.set(x, "onItemRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnItemRemoveUndefined: Self = StObject.set(x, "onItemRemove", js.undefined)
      
      @scala.inline
      def setOnLoad(
        value: RecursivePartial[
              js.Function2[/* options */ js.Array[TomOption], /* optgroups */ js.Array[TomOption], Unit]
            ]
      ): Self = StObject.set(x, "onLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnLoadUndefined: Self = StObject.set(x, "onLoad", js.undefined)
      
      @scala.inline
      def setOnOptionAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionAddUndefined: Self = StObject.set(x, "onOptionAdd", js.undefined)
      
      @scala.inline
      def setOnOptionClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionClearUndefined: Self = StObject.set(x, "onOptionClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupAdd(value: RecursivePartial[js.Function2[/* value */ String | Double, /* data */ TomOption, Unit]]): Self = StObject.set(x, "onOptionGroupAdd", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupAddUndefined: Self = StObject.set(x, "onOptionGroupAdd", js.undefined)
      
      @scala.inline
      def setOnOptionGroupClear(value: RecursivePartial[js.Function0[Unit]]): Self = StObject.set(x, "onOptionGroupClear", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupClearUndefined: Self = StObject.set(x, "onOptionGroupClear", js.undefined)
      
      @scala.inline
      def setOnOptionGroupRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionGroupRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionGroupRemoveUndefined: Self = StObject.set(x, "onOptionGroupRemove", js.undefined)
      
      @scala.inline
      def setOnOptionRemove(value: RecursivePartial[js.Function1[/* value */ String | Double, Unit]]): Self = StObject.set(x, "onOptionRemove", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnOptionRemoveUndefined: Self = StObject.set(x, "onOptionRemove", js.undefined)
      
      @scala.inline
      def setOnType(value: RecursivePartial[js.Function1[/* str */ String, Unit]]): Self = StObject.set(x, "onType", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOnTypeUndefined: Self = StObject.set(x, "onType", js.undefined)
      
      @scala.inline
      def setOpenOnFocus(value: RecursivePartial[Boolean]): Self = StObject.set(x, "openOnFocus", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOpenOnFocusUndefined: Self = StObject.set(x, "openOnFocus", js.undefined)
      
      @scala.inline
      def setOptgroupField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupFieldUndefined: Self = StObject.set(x, "optgroupField", js.undefined)
      
      @scala.inline
      def setOptgroupLabelField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupLabelField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupLabelFieldUndefined: Self = StObject.set(x, "optgroupLabelField", js.undefined)
      
      @scala.inline
      def setOptgroupValueField(value: RecursivePartial[String]): Self = StObject.set(x, "optgroupValueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupValueFieldUndefined: Self = StObject.set(x, "optgroupValueField", js.undefined)
      
      @scala.inline
      def setOptgroups(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "optgroups", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupsUndefined: Self = StObject.set(x, "optgroups", js.undefined)
      
      @scala.inline
      def setOptionClass(value: RecursivePartial[String]): Self = StObject.set(x, "optionClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionClassUndefined: Self = StObject.set(x, "optionClass", js.undefined)
      
      @scala.inline
      def setOptions(value: RecursivePartial[js.UndefOr[js.Array[Any]]]): Self = StObject.set(x, "options", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionsUndefined: Self = StObject.set(x, "options", js.undefined)
      
      @scala.inline
      def setPersist(value: RecursivePartial[Boolean]): Self = StObject.set(x, "persist", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPersistUndefined: Self = StObject.set(x, "persist", js.undefined)
      
      @scala.inline
      def setPlaceholder(value: RecursivePartial[String]): Self = StObject.set(x, "placeholder", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPlaceholderUndefined: Self = StObject.set(x, "placeholder", js.undefined)
      
      @scala.inline
      def setPlugins(value: RecursivePartial[(js.Array[String | TPluginItem]) | TPluginHash]): Self = StObject.set(x, "plugins", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPluginsUndefined: Self = StObject.set(x, "plugins", js.undefined)
      
      @scala.inline
      def setPreload(value: RecursivePartial[Boolean | String]): Self = StObject.set(x, "preload", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setPreloadUndefined: Self = StObject.set(x, "preload", js.undefined)
      
      @scala.inline
      def setRender(value: RecursivePartialTomTempla): Self = StObject.set(x, "render", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setRenderUndefined: Self = StObject.set(x, "render", js.undefined)
      
      @scala.inline
      def setScore(value: RecursivePartial[js.UndefOr[js.Function1[/* query */ String, js.Function0[Any]]]]): Self = StObject.set(x, "score", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setScoreUndefined: Self = StObject.set(x, "score", js.undefined)
      
      @scala.inline
      def setSearchConjunction(value: RecursivePartial[String]): Self = StObject.set(x, "searchConjunction", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchConjunctionUndefined: Self = StObject.set(x, "searchConjunction", js.undefined)
      
      @scala.inline
      def setSearchField(value: RecursivePartialArraystriEntries): Self = StObject.set(x, "searchField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSearchFieldUndefined: Self = StObject.set(x, "searchField", js.undefined)
      
      @scala.inline
      def setSelectOnTab(value: RecursivePartial[Boolean]): Self = StObject.set(x, "selectOnTab", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSelectOnTabUndefined: Self = StObject.set(x, "selectOnTab", js.undefined)
      
      @scala.inline
      def setShouldLoad(value: RecursivePartial[js.Function1[/* query */ String, Boolean]]): Self = StObject.set(x, "shouldLoad", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMore(value: RecursivePartial[js.Function0[Boolean]]): Self = StObject.set(x, "shouldLoadMore", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldLoadMoreUndefined: Self = StObject.set(x, "shouldLoadMore", js.undefined)
      
      @scala.inline
      def setShouldLoadUndefined: Self = StObject.set(x, "shouldLoad", js.undefined)
      
      @scala.inline
      def setShouldOpen(value: RecursivePartial[Boolean]): Self = StObject.set(x, "shouldOpen", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setShouldOpenUndefined: Self = StObject.set(x, "shouldOpen", js.undefined)
      
      @scala.inline
      def setSortField(
        value: RecursivePartial[
              String | (js.Array[
                /* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.Sort */ Any
              ]) | (/* import warning: transforms.QualifyReferences#resolveTypeRef many Couldn't qualify SifterTypes.SortFn */ Any)
            ]
      ): Self = StObject.set(x, "sortField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSortFieldUndefined: Self = StObject.set(x, "sortField", js.undefined)
      
      @scala.inline
      def setSplitOn(value: RecursivePartial[js.RegExp | String]): Self = StObject.set(x, "splitOn", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setSplitOnUndefined: Self = StObject.set(x, "splitOn", js.undefined)
      
      @scala.inline
      def setValueField(value: RecursivePartial[String]): Self = StObject.set(x, "valueField", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setValueFieldUndefined: Self = StObject.set(x, "valueField", js.undefined)
      
      @scala.inline
      def setWrapperClass(value: RecursivePartial[String]): Self = StObject.set(x, "wrapperClass", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setWrapperClassUndefined: Self = StObject.set(x, "wrapperClass", js.undefined)
    }
  }
  
  /* Inlined tom-select.tom-select/dist/types/types/core.RecursivePartial<tom-select.tom-select/dist/types/types.TomTemplates> */
  @js.native
  trait RecursivePartialTomTempla extends StObject {
    
    var dropdown: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
    
    var item: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
    
    var loading: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
    
    var loading_more: js.UndefOr[RecursivePartial[TomTemplateNull]] = js.native
    
    var no_more_results: js.UndefOr[RecursivePartial[TomTemplateNull]] = js.native
    
    var no_results: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
    
    var not_loading: js.UndefOr[RecursivePartial[TomTemplateNull]] = js.native
    
    var optgroup: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
    
    var optgroup_header: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
    
    var option: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
    
    var option_create: js.UndefOr[RecursivePartial[TomTemplate]] = js.native
  }
  object RecursivePartialTomTempla {
    
    @scala.inline
    def apply(): RecursivePartialTomTempla = {
      val __obj = js.Dynamic.literal()
      __obj.asInstanceOf[RecursivePartialTomTempla]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: RecursivePartialTomTempla] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setDropdown(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "dropdown", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setDropdownUndefined: Self = StObject.set(x, "dropdown", js.undefined)
      
      @scala.inline
      def setItem(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "item", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setItemUndefined: Self = StObject.set(x, "item", js.undefined)
      
      @scala.inline
      def setLoading(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "loading", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoadingUndefined: Self = StObject.set(x, "loading", js.undefined)
      
      @scala.inline
      def setLoading_more(value: RecursivePartial[TomTemplateNull]): Self = StObject.set(x, "loading_more", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setLoading_moreUndefined: Self = StObject.set(x, "loading_more", js.undefined)
      
      @scala.inline
      def setNo_more_results(value: RecursivePartial[TomTemplateNull]): Self = StObject.set(x, "no_more_results", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setNo_more_resultsUndefined: Self = StObject.set(x, "no_more_results", js.undefined)
      
      @scala.inline
      def setNo_results(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "no_results", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setNo_resultsUndefined: Self = StObject.set(x, "no_results", js.undefined)
      
      @scala.inline
      def setNot_loading(value: RecursivePartial[TomTemplateNull]): Self = StObject.set(x, "not_loading", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setNot_loadingUndefined: Self = StObject.set(x, "not_loading", js.undefined)
      
      @scala.inline
      def setOptgroup(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "optgroup", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroupUndefined: Self = StObject.set(x, "optgroup", js.undefined)
      
      @scala.inline
      def setOptgroup_header(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "optgroup_header", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptgroup_headerUndefined: Self = StObject.set(x, "optgroup_header", js.undefined)
      
      @scala.inline
      def setOption(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "option", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOptionUndefined: Self = StObject.set(x, "option", js.undefined)
      
      @scala.inline
      def setOption_create(value: RecursivePartial[TomTemplate]): Self = StObject.set(x, "option_create", value.asInstanceOf[js.Any])
      
      @scala.inline
      def setOption_createUndefined: Self = StObject.set(x, "option_create", js.undefined)
    }
  }
  
  @js.native
  trait Value extends StObject {
    
    var value: String = js.native
  }
  object Value {
    
    @scala.inline
    def apply(value: String): Value = {
      val __obj = js.Dynamic.literal(value = value.asInstanceOf[js.Any])
      __obj.asInstanceOf[Value]
    }
    
    @scala.inline
    implicit class MutableBuilder[Self <: Value] (val x: Self) extends AnyVal {
      
      @scala.inline
      def setValue(value: String): Self = StObject.set(x, "value", value.asInstanceOf[js.Any])
    }
  }
}
