package ch.randm.playsit.frontend

import cats.implicits._
import ch.randm.playsit.core.model.{DynamicContent, Engagement}
import ch.randm.playsit.core.model.common._
import ch.randm.playsit.frontend.ajax.ProxyFetchStream
import ch.randm.playsit.frontend.component._
import ch.randm.playsit.frontend.element._
import ch.randm.playsit.json._
import ch.randm.playsit.routing.{DynamicContentApi, EngagementApi}
import com.raquo.laminar.api.L._
import io.circe.generic.auto._
import org.scalajs.dom

object App {

  private lazy val carousel =
    div(
      idAttr              := "homeCarousel",
      className           := "carousel slide carousel-fade carousel-background sticky-top",
      div(className := "carousel-inner", CarouselElement.carouselElements.map(_.render)),
      dataAttr("bs-ride") := "carousel"
    )

  private lazy val navigation =
    navTag(
      className := "nav-masthead navbar navbar-expand-sm p-3 sticky-top",
      div(
        className := "container-fluid",
        a(
          href                  := "#",
          className             := "navbar-brand pe-3",
          img(src := "/public/img/logo.png", alt := "Jar — Swiss Offbeat Music", width := "80px")
        ),
        button(
          typ                   := "button",
          className             := "navbar-toggler",
          dataAttr("bs-toggle") := "offcanvas",
          dataAttr("bs-target") := "#offcanvasNavbar",
          aria.controls         := "offcanvasNavbar",
          aria.expanded         := false,
          aria.label            := "Toggle navigation",
          span(className := "navbar-toggler-icon")
        ),
        div(
          idAttr                := "offcanvasNavbar",
          className             := "offcanvas offcanvas-end",
          div(
            cls := "offcanvas-header",
            h5(cls                   := "offcanvas-title", idAttr := "offcanvasNavbarLabel", "Navigation"),
            button(
              tpe                    := "button",
              cls                    := "btn-close",
              dataAttr("bs-dismiss") := "offcanvas",
              aria.label             := "Close navigation"
            )
          ),
          div(
            cls := "offcanvas-body",
            ul(
              cls := "navbar-nav justify-content-end flex-grow-1 pe-3",
              NavigationElement.navigationEntries.map(_.render)
            )
          )
        )
      )
    )

  private lazy val gigs =
    div(
      idAttr    := "gigs",
      className := "container py-5",
      div(
        idAttr    := "accordionExample",
        className := "accordion",
        children <-- ProxyFetchStream
          .expect[List[Persisted[Engagement]]](EngagementApi.Future(None, None))
          .recover { case t => dom.console.error(t); Nil.some }
          .map(_.zipWithIndex.map { case (v, i) => EngagementElement(v, i == 0).render })
          .map {
            case Nil => List(div(
                cls  := "alert alert-primary text-center",
                role := "alert",
                i(className := "bi-info-circle-fill"),
                nbsp,
                "More concerts will be announced soon! If you want to book us, please use the ",
                a(href      := "#contact", "contact form"),
                " below. Cheers! \uD83C\uDF7B"
              ))
            case l   => l
          }
          .startWith(List(div(
            cls := "text-center",
            div(cls := "spinner-border", role := "status", span(cls := "visually-hidden", "Loading..."))
          )))
      )
    )

  private lazy val social =
    div(
      idAttr    := "social",
      className := "container py-5",
      div(
        cls := "row justify-content-center",
        SocialLinkElement.socialLinks.map(_.render)
      )
    )

  private lazy val rootElement =
    div(
      className := "h-100",
      carousel,
      div(className := "fade-background"),
      headerTag(
        span(className := "position-absolute trigger"),
        navigation
      ),
      mainTag(
        child <-- ProxyFetchStream.expect[Persisted[DynamicContent]](DynamicContentApi.ReadByKey("teaser")).map(c =>
          TextElement(c.get.content).render
        ),
        gigs,
        SubscribeComponent.render,
        ContactComponent.render,
        social
      ),
      div(className := "toast-container position-fixed bottom-0 end-0 p-3")
    )

  def init(appNode: dom.Element): Unit = {
    // Dummy-load the NPM modules
    typings.bootstrap.bootstrapRequire
    typings.leaflet.leafletRequire

    renderOnDomContentLoaded(appNode, rootElement)
  }

}
