package ch.randm.playsit.frontend.admin.form

import cats.Show
import cats.implicits.catsSyntaxOptionId
import com.raquo.airstream.ownership.ManualOwner
import com.raquo.laminar.api.L._
import formula._
import org.scalablytyped.runtime.StringDictionary
import typings.tomSelect.anon.RecursivePartialTomSettinAddPrecedence
import typings.tomSelect.mod.{default => TomSelect}

import scala.util.{Random, Try}

abstract class TomSelector[A] {

  protected val $values: Var[List[A]]  = Var(Nil)
  protected val $value: Var[Option[A]] = Var(None)

  lazy val optionalSelector: Form[Option[A]] =
    Form.Input.make[Option[A]] {
      config =>
        lazy val random    = Random.alphanumeric.take(8).mkString
        lazy val tomSelect = new TomSelect(
          s"#selector-$random",
          RecursivePartialTomSettinAddPrecedence()
        )

        val var0 = Form.FormVar.make(Option.empty[A])
        val node = select(
          config.modifiers,
          additionalModifiers,
          idAttr := s"selector-$random",
          // When changing the selection, set the new value in `var0`
          onInput.mapToValue.compose(_.withCurrentValueOf($values)) --> Observer[(String, List[A])] {
            case (idx, options) => var0.set(Try(options(idx.toInt)).toOption)
          },
          // When changing the selection, set the new value on the select element
          inContext { el =>
            def set(idx: Int): Unit = {
              el.ref.value = idx.toString
              tomSelect.addItem(idx.toString)
            }

            def clear(): Unit = {
              el.ref.value = ""
              tomSelect.clear()
            }

            var0.signal.map(_.value).withCurrentValueOf($values) --> Observer[(Option[A], List[A])] {
              case (Some(a), options) =>
                val idx = options.indexOf(a)
                if (idx >= 0) set(idx)
                else {
                  // When the value is updated, we need may need to wait for it to be available
                  implicit val owner: ManualOwner = new ManualOwner
                  $values.signal.changes.take(1).foreach { opts =>
                    val idx = opts.indexOf(a)
                    if (idx >= 0) set(idx)
                    owner.killSubscriptions()
                  }
                  ()
                }
              case (None, _)          => clear()
            }
          },
          // Render all the options
          option(value(""), "Please select..."),
          children <-- $values.signal.splitByIndex {
            case (i, v, _) => option(value(i.toString), render(v))
          },
          $values.signal --> { v =>
            tomSelect.clear()
            tomSelect.clearOptions()
            v.zipWithIndex.foreach {
              case (v, i) => tomSelect.addOption(StringDictionary("value" -> i, "text" -> render(v)))
            }
          },
          $value.signal --> { v => var0.set(v) }
        )
        FormValue(var0, node)
    }

  lazy val selector: Form[A] = optionalSelector.validate(_.isDefined, "Invalid value").xmap(_.get)(_.some)

  protected def render(p: A): String

  protected def additionalModifiers: Mod[HtmlElement] = Seq(commentNode())

}

object TomSelector {

  def apply[A](f: A => String, init: List[A] = Nil): TomSelector[A] =
    new TomSelector[A] {
      override protected val $values: Var[List[A]] = Var(init)
      override protected def render(p: A): String  = f(p)
    }

  def apply[A: Show](init: List[A]): TomSelector[A] = apply(Show[A].show, init)

  def apply[A: Show]: TomSelector[A] = Show[A].show(_)

}
