package ch.randm.playsit.frontend.util

import io.circe.{Decoder, Json}
import io.circe.syntax._
import org.scalajs.dom
import org.scalajs.dom.{html, HTMLFormElement}

object FormUtil {

  trait FormData[A] {
    def parse(form: dom.HTMLFormElement): Either[Throwable, A]
  }

  object FormData {

    def apply[A](implicit ev: FormData[A]): FormData[A] = ev

    implicit def gen[A: Decoder]: FormData[A] = form => jsonFormData.parse(form).flatMap(_.as[A])

    implicit val jsonFormData: FormData[Json] = (form: HTMLFormElement) =>
      Right(Json.fromFields(form.querySelectorAll("input,textarea").toList.flatMap {
        case f: html.Input if f.`type` == "checkbox" => Some(f.name -> f.checked.asJson)
        case f: html.Input if f.`type` == "radio"    => Some(f.name -> f.checked.asJson)
        case f: html.Input                           => Some(f.name -> f.value.asJson)
        case f: html.TextArea                        => Some(f.name -> f.value.asJson)
        case _                                       => None
      }))

    implicit val singleValueFormData: FormData[String] = (form: HTMLFormElement) =>
      form.querySelectorAll("input,textarea").headOption.flatMap {
        case f: html.Input    => Some(f.value)
        case f: html.TextArea => Some(f.value)
        case _                => None
      }.toRight(new IllegalStateException("FormData[String] on form with no text input"))

  }

}
