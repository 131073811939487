package ch.randm.playsit

import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.config.Id
import ch.randm.playsit.core.error.ValidationError
import ch.randm.playsit.core.model.ElectronicAddress.ElectronicAddressType
import ch.randm.playsit.core.model.Engagement.TimeType
import ch.randm.playsit.core.model.TicketSale.Currency
import ch.randm.playsit.core.model.common.Text.TextType
import ch.randm.playsit.core.model.common.{Identifier, Persisted}
import ch.randm.playsit.core.service.AuthenticationService.Token.TokenType
import ch.randm.playsit.core.service.AuthorizationService.{Operation, Permission, Role}
import ch.randm.playsit.core.util.ClassName
import io.circe._
import io.circe.generic.auto._
import io.circe.syntax._

import scala.concurrent.duration.FiniteDuration
import scala.jdk.DurationConverters._

package object json {

  implicit val durationEncoder: Encoder[FiniteDuration] = Encoder.encodeDuration.contramap(_.toJava)
  implicit val durationDecoder: Decoder[FiniteDuration] = Decoder.decodeDuration.map(_.toScala)

  implicit val currencyEncoder: Encoder[Currency] = Encoder.encodeString.contramap(_.code)
  implicit val currencyDecoder: Decoder[Currency] = Decoder.decodeString.map(Currency)

  implicit val textTypeEncoder: Encoder[TextType] = Encoder.encodeString.contramap(_.toString)
  implicit val textTypeDecoder: Decoder[TextType] = Decoder.decodeString.flatMap(enumDecoder(TextType.byName))

  implicit val electronicAddressTypeEncoder: Encoder[ElectronicAddressType] = Encoder.encodeString.contramap(_.toString)
  implicit val electronicAddressTypeDecoder: Decoder[ElectronicAddressType] =
    Decoder.decodeString.flatMap(enumDecoder(ElectronicAddressType.byName))

  implicit val tokenTypeEncoder: Encoder[TokenType] = Encoder.encodeString.contramap(_.toString)
  implicit val tokenTypeDecoder: Decoder[TokenType] = Decoder.decodeString.flatMap(enumDecoder(TokenType.byName))

  implicit val timeTypeEncoder: KeyEncoder[TimeType] = KeyEncoder[String].contramap(_.toString)
  implicit val timeTypeDecoder: KeyDecoder[TimeType] = KeyDecoder.instance(TimeType.byName)

  implicit val operationEncoder: Encoder[Operation] = Encoder.encodeString.contramap(_.toString)
  implicit val operationDecoder: Decoder[Operation] = Decoder.decodeString.flatMap(enumDecoder(Operation.byName))

  // TODO: Are both needed?
  implicit def classNameEncoder[A]: Encoder[ClassName[A]] = _.fullClassName.asJson
  implicit def classNameDecoder[A]: Decoder[ClassName[A]] = _.as[String].map(ClassName[A])
  implicit val classNameEncoder2: Encoder[ClassName[_]]   = _.fullClassName.asJson
  implicit val classNameDecoder2: Decoder[ClassName[_]]   = _.as[String].map(ClassName.apply)

  // TODO: Auto codec for `Permission`
  implicit val permissionEncoder: Encoder[Permission] =
    a =>
      Json.obj(
        "role"        -> a.role.asJson,
        "targetClass" -> a.targetClass.asJson,
        "targetId"    -> a.targetId.asJson,
        "operation"   -> a.operation.asJson
      )

  implicit val permissionDecoder: Decoder[Permission] =
    c =>
      for {
        r <- c.downField("role").as[Persisted[Role]]
        t <- c.downField("targetClass").as[ClassName[_]]
        i <- c.downField("targetId").as[Option[Identifier]]
        o <- c.downField("operation").as[Operation]
      } yield Permission(r, t, i, o)

  implicit def identifierEncoder(implicit e: Encoder[Id]): Encoder[Identifier] = _.value.asInstanceOf[Id].asJson
  implicit def identifierDecoder(implicit d: Decoder[Id]): Decoder[Identifier] = _.as[Id].map(Identifier(_))

  implicit def persistedEncoder[A: Encoder]: Encoder[Persisted[A]] =
    a => Json.obj("id" -> a.id.asJson, "value" -> a.map(_.asJson).getOrElse(Json.Null))

  implicit def persistedDecoder[A: Decoder]: Decoder[Persisted[A]] =
    c =>
      for {
        i <- c.downField("id").as[Identifier]
        v <- c.downField("value").as[Option[A]]
        r  = v.fold(Persisted[A](i))(Persisted(i, _))
      } yield r

  implicit def optionalPersistedEncoder[A: Encoder]: Encoder[Option[Persisted[A]]] =
    a => a.map(persistedEncoder[A].apply).getOrElse(Json.Null)
  implicit def optionalPersistedDecoder[A: Decoder]: Decoder[Option[Persisted[A]]] =
    c => Right(persistedDecoder[A].apply(c).map(_.some).getOrElse(None))

  implicit def eitherEncoder[A: Encoder, B: Encoder]: Encoder[Either[A, B]] = Encoder.encodeEither("left", "right")
  implicit def eitherDecoder[A: Decoder, B: Decoder]: Decoder[Either[A, B]] = Decoder.decodeEither("left", "right")

  implicit val validationErrorEncoder: Encoder[ValidationError] =
    e => Json.obj("field" -> e.field.asJson, "message" -> e.message.asJson)

  private def enumDecoder[A: ClassName](f: String => Option[A]): String => Decoder[A] =
    s => c => f(s).toRight(DecodingFailure(s"$s is not a valid '${ClassName[A].objectName}' enum type", c.history))

}
