package ch.randm.playsit.frontend.admin.page

import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.model.common.Persisted
import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.core.util.ClassName
import ch.randm.playsit.frontend.admin.State
import ch.randm.playsit.frontend.admin.form.CRUDSelector
import ch.randm.playsit.frontend.component.PermissionsComponent
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement.Base
import com.raquo.waypoint.Router
import formula._
import typings.bootstrap.{mod => bootstrap}

case class CRUDPage[A: Form: CRUDSelector: ClassName](
    override val icon: String,
    override val $state: Var[State]
) extends AuthedPage with LinkedPage {

  override val title: String = ClassName[A].plural
  override val path: String  = crudSelector.path

  private lazy val crudSelector: CRUDSelector[A]             = CRUDSelector[A]
  private lazy val selector: FormValue[Option[Persisted[A]]] = crudSelector.optionalSelector.build
  private lazy val crudForm: FormValue[A]                    = DeriveForm.build[A]

  private lazy val deleteModal = new bootstrap.Modal("#modalConfirmDelete")

  private val $formValue: Var[Option[A]] = Var(None)
  private val submitBus: EventBus[Any]   = new EventBus[Any]

  override protected def authedRender(token: Token, router: Router[Page]): Base =
    mainTag(
      cls := "container-xxl",
      h1(
        i(cls := s"bi-$icon"),
        nbsp,
        title
      ),
      div(
        className := "mb-3 flex flex-col border-bottom",
        selector.view,
        selector.$value --> { _.map(_.get).foreach(crudForm.set) }
      ),
      form(
        crudForm.view,
        crudForm.$value.map(_.some) --> $formValue.writer,
        PermissionsComponent(token, selector.$value).render,
        div(
          cls := "mt-2 mb-3 flex flex-cold",
          button(
            typ       := "submit",
            className := "btn btn-primary",
            i(cls := "bi-save"),
            nbsp,
            "Save"
          ),
          child.maybe <-- selector.$value.map(_.map { _ =>
            button(
              typ                   := "button",
              className             := "btn btn-danger ms-2",
              dataAttr("bs-toggle") := "modal",
              dataAttr("bs-target") := "#modalConfirmDelete",
              i(cls := "bi-trash"),
              nbsp,
              "Delete"
            )
          })
        ),
        onSubmit.preventDefault --> submitBus,
        submitBus.events
          .withCurrentValueOf($formValue.signal, selector.$value)
          .map {
            case (_, formValue, selected) =>
              val value = formValue.getOrElse(throw new IllegalStateException("Missing value"))
              selected
                .map(a => crudSelector.Update(a.load(value), token.some))
                .getOrElse(crudSelector.Create(value, token.some))
          } --> crudSelector.bus
      ),
      confirmDeleteDialog(token)
    )

  private def confirmDeleteDialog(token: Token) =
    div(
      cls             := "modal fade",
      idAttr          := "modalConfirmDelete",
      tabIndex        := -1,
      aria.labelledBy := "modalConfirmDeleteLabel",
      aria.hidden     := true,
      div(
        cls := "modal-dialog modal-dialog-centered",
        div(
          cls := "modal-content rounded-3 shadow",
          div(
            cls := "modal-body p-4 text-center",
            h5(cls := "mb-0", "Do you really want to delete this entity?"),
            p(cls  := "mb-0", "This action is not reversible.")
          ),
          div(
            cls := "modal-footer flex-nowrap p-0",
            button(
              typ                    := "button",
              cls                    := "btn btn-lg btn-primary fs-6 text-decoration-none col-6 m-0 rounded-top-0 rounded-end-0 border-end",
              dataAttr("bs-dismiss") := "modal",
              strong("Yes, delete"),
              onClick.compose(_.withCurrentValueOf(selector.$value.map(_.map(v =>
                crudSelector.Delete(v.id, token.some)
              )))) --> { t =>
                t._2.foreach(crudSelector.bus.emit)
                deleteModal.hide()
              }
            ),
            button(
              typ                    := "button",
              cls                    := "btn btn-lg btn-danger fs-6 text-decoration-none col-6 m-0 rounded-top-0 rounded-start-0",
              dataAttr("bs-dismiss") := "modal",
              strong("Cancel")
            )
          )
        )
      )
    )

}
