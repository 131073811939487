package ch.randm.playsit.routing

import ch.randm.playsit.core.model.DynamicContent
import routing._

object DynamicContentApi extends CRUDApi[DynamicContent] {

  val ReadByKey = Method.GET / Root :? queryParam[String]("key")

}
