package ch.randm.playsit.core.service

import cats.data.{EitherT, ValidatedNel}
import cats.implicits.{catsSyntaxOptionId, catsSyntaxTuple2Semigroupal, catsSyntaxTuple3Semigroupal}
import cats.{Monad, Show}
import ch.randm.playsit.core.error.Error.AuthorizationError
import ch.randm.playsit.core.error.{Error, ValidationError}
import ch.randm.playsit.core.model.common.{Identifier, Persisted, Validated}
import ch.randm.playsit.core.service.AuthorizationService._
import ch.randm.playsit.core.util.ClassName

trait AuthorizationService[F[_]] {

  /** A `User` can only manage (i.e. cast a `Vote` on a `Permission` for) a `Role`, if that role is the descendant of a
    * role the user is assigned to. This method may be used to retrieve the list of manageable `Role`s for a single
    * `User`.
    *
    * @param user
    *   The user which wants to manage roles
    * @return
    *   A list of manageable roles for the given user
    */
  def getManageableRoles(user: Persisted[User]): EitherT[F, Error, List[Persisted[Role]]]

  /** Creates `Permission`s for every manageable `Role` (see `getManageableRoles`) and viable `Operation`* for a given
    * target class with optional id. For each of those, an `Authorization` will be built whose `Authorization#votes`
    * field only contains the given `user`'s `Vote`s.
    *
    * *) Viability of the operation depends on the `targetId` parameter. If it's set, `Operation.Create` and
    * `Operation.List` must not be viable.
    *
    * @param user
    *   User filter
    * @param targetClass
    *   Target class filter
    * @param targetId
    *   Target id filter for applicable operations
    * @return
    *   A list of authorizations filtered by parameters
    */
  def getManageablePermissions(
      user: Persisted[User],
      targetClass: ClassName[_],
      targetId: Option[Identifier] = None
  ): EitherT[F, Error, List[Authorization]]

  /** Casts a `Vote` for a `User` to grant the given `Permission`. The user's `Role` with the highest level will be used
    * for voting. If there is more than one such `Role`, a `Vote` is cast for each.
    *
    * A vote must not be cast if the `Permission.role` is not manageable (see `getManageableRoles`) by the `User`.
    *
    * @param user
    *   The user which casts the vote
    * @param permission
    *   The permission to grant
    * @return
    *   The cast votes
    */
  def grant(user: Persisted[User], permission: Permission): EitherT[F, Error, Seq[Persisted[Vote]]]

  /** Casts a `Vote` for a `User` to deny the given `Permission`. The user's `Role` with the highest level will be used
    * for voting. If there is more than one such `Role`, a `Vote` is cast for each.
    *
    * A vote must not be cast if the `Permission.role` is not manageable (see `getManageableRoles`) by the user.
    *
    * @param user
    *   The user which casts the vote
    * @param permission
    *   The permission to deny
    * @return
    *   The cast votes
    */
  def deny(user: Persisted[User], permission: Permission): EitherT[F, Error, Seq[Persisted[Vote]]]

  /** Tally all previously cast `Vote`s into an `Authorization` object, based on which access can be granted or denied.
    *
    * @param permission
    *   The permission which is checked
    * @return
    *   Authorization details of the requested resource
    */
  def authorize(permission: Permission): EitherT[F, Error, Authorization]

  /** Same as `authorize`, but raises an `AuthorizationError` if the `Authorization#isAuthorized` flag is `false`.
    *
    * @param permission
    *   The permission which is checked
    * @param M
    *   `Monad` instance for `F`
    * @return
    *   `true` if access to the resource is granted, `false` otherwise
    */
  final def authorizeOrRaise(permission: Permission)(implicit M: Monad[F]): EitherT[F, Error, Authorization] =
    authorize(permission).flatMap(a => EitherT.cond[F](a.isAuthorized, a, authorizationError(permission)))

  private def authorizationError(p: Permission): AuthorizationError =
    AuthorizationError(
      p.operation.toString,
      p.targetClass.singular,
      "id",
      p.targetId.map(_.value.toString).getOrElse("&lt;none&gt;")
    )

}

object AuthorizationService {

  /** @param name
    *   The user name used to authorize with the backend
    * @param password
    *   A very secure and secret string used to authorize the user
    * @param email
    *   The user's primary email address
    * @param roles
    *   List of the user's authorization roles
    */
  case class User(name: String, password: String, email: String, roles: List[Persisted[Role]] = Nil)
      extends Validated[User] {

    override def validate: ValidatedNel[ValidationError, User] =
      (notEmpty(name, "name"), validPassword(password), validEmail(email)).mapN(User(_, _, _, roles))

  }

  object User {
    implicit val show: Show[User] = _.name
  }

  case class Role(name: String, parent: Option[Persisted[Role]], grantedByDefault: List[Operation])
      extends Validated[Role] {

    lazy val level: Int = parent.map(_.map(_.level).getOrElse(0) + 1).getOrElse(0)

    override def validate: ValidatedNel[ValidationError, Role] =
      (notEmpty(name, "name"), notMissing(parent, "parent")).mapN((n, p) => Role(n, p.some, grantedByDefault))

  }

  object Role {

    final object Superuser extends Role("Superuser", None, Operation.viable(false).toList)

    implicit val show: Show[Role] = _.name

  }

  sealed abstract class Operation

  object Operation {
    final case object Create extends Operation
    final case object Read   extends Operation
    final case object List   extends Operation
    final case object Update extends Operation
    final case object Delete extends Operation

    private val values: Seq[Operation] = Seq(Create, Read, List, Update, Delete)

    def byName(s: String): Option[Operation] = values.find(_.toString equalsIgnoreCase s)

    def viable(isTargetSet: Boolean): Seq[Operation] =
      values.filterNot(isTargetSet && Seq(Operation.Create, Operation.List).contains(_))
  }

  final case class Vote(
      permission: Persisted[Permission],
      caster: Persisted[User],
      onBehalfOf: Persisted[Role],
      granted: Boolean
  )

  final case class Permission(
      role: Persisted[Role],
      targetClass: ClassName[_],
      targetId: Option[Identifier],
      operation: Operation
  )

  final case class Authorization(permission: Permission, votes: List[Vote] = Nil) {

    private val preferred: List[Vote] = votes.foldLeft(List.empty[Vote]) { (acc, v) =>
      if (acc.forall(_.onBehalfOf.get.level >= v.onBehalfOf.get.level)) acc :+ v
      else acc
    }

    val yays: Int = preferred.count(_.granted)
    val nays: Int = preferred.length - yays

    val isAuthorized: Boolean =
      (yays == nays && permission.role.get.grantedByDefault.contains(permission.operation)) || yays > nays

  }

}
