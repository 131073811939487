package ch.randm.playsit.frontend.util

import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.error.Error
import ch.randm.playsit.core.error.Error.{NetworkError, ValidationErrors}
import ch.randm.playsit.frontend.component.Toast
import com.raquo.laminar.api.L._

object AjaxUtil {

  def recoverWithToast(toastStream: EventStream[Toast]): EventStream[Toast] =
    recoverWithToast[Toast](toastStream, identity)

  def recoverWithToast[T](toastStream: EventStream[T], set: Toast => T): EventStream[T] =
    toastStream
      .recover {
        case e: ValidationErrors                =>
          set(Toast(
            "info",
            "Bad input!",
            s"The validation failed:<br>" + e.errors.map(e => s"- ${e.field}: ${e.message}").toList.mkString("<br>")
          )).some
        case e: NetworkError if e.status == 401 =>
          set(Toast("info", s"Error 401", "You are not authorized to execute this action.")).some
        case e: NetworkError                    =>
          set(Toast("danger", s"Error ${e.status}", e.reason)).some
        case e: Error                           =>
          set(Toast("danger", "Error!", e.message)).some
        case _                                  =>
          set(Toast("danger", "Error!", "Something went wrong, sorry.")).some
      }

}
