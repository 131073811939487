package ch.randm.playsit.frontend.admin.page.error

import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.frontend.admin.State
import ch.randm.playsit.frontend.admin.page.{AuthedPage, Page}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement.Base
import com.raquo.waypoint.Router

case class _404($state: Var[State]) extends AuthedPage {

  override val path: String  = "/not-found"
  override val title: String = "Not Found"

  override protected def authedRender(token: Token, router: Router[Page]): Base =
    div(
      cls := "container-xxl",
      div(
        cls := "position-absolute top-50 start-50 translate-middle",
        h2(
          i(cls := "bi-bug"),
          nbsp,
          "Not Found"
        )
      )
    )

}
