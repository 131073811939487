package ch.randm.playsit.core.error

import cats.Show

class ValidationError(val field: String, val message: String)

object ValidationError {

  sealed trait FieldType
  final case object Email    extends FieldType
  final case object Password extends FieldType

  case class Missing(override val field: String) extends ValidationError(field, s"Value is missing")
  case class Empty(override val field: String)   extends ValidationError(field, s"Must not be empty")
  case class Invalid(override val field: String, typ: FieldType)
      extends ValidationError(field, s"This is not a valid $typ")

  implicit val show: Show[ValidationError] = _.message

}
