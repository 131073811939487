package ch.randm.playsit.core.model.common

import cats.Show
import ch.randm.playsit.core.model.common.Text.TextType

/** Represents a long string value that may be parsed by a WYSIWYG/Markdown editor and shown formatted in a frontend.
  *
  * @param value
  *   The text
  * @param typ
  *   Format of the text
  */
final case class Text(value: String, typ: TextType = TextType.Plain)

object Text {

  sealed trait TextType

  object TextType {
    case object Plain    extends TextType
    case object Markdown extends TextType
    case object Html     extends TextType

    val values: Seq[TextType] = Seq(Plain, Markdown, Html)

    def byName(s: String): Option[TextType] = values.find(_.toString == s)
  }

  implicit val show: Show[Text] = t => if (t.value.length > 20) t.value.substring(0, 20) + "..." else t.value

}
