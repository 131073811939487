package ch.randm.playsit.core.error

import cats.data.NonEmptyList
import cats.implicits.catsSyntaxOptionId

import java.sql.SQLException

/** Represents an error.
  */
sealed abstract class Error(val message: String, val cause: Option[Throwable] = None)
    extends Throwable(message, cause.orNull)

object Error {

  final case class FatalError(t: Throwable)
      extends Error("A fatal error occurred", t.some)
  final case class UnexpectedError(t: Throwable)
      extends Error("An unexpected error occurred", t.some)
  final case class ServerError(ex: Exception)
      extends Error("An exception occurred", ex.some)
  final case class ExpectedError(reason: String)
      extends Error(s"An expected error occurred: $reason")
  final case class DatabaseError(ex: SQLException)
      extends Error(s"SQL ${ex.getSQLState} (${ex.getErrorCode}): ${ex.getMessage}", ex.some)
  final case class NetworkError(url: String, status: Int, reason: String, t: Option[Throwable] = None)
      extends Error(s"Error when calling $url (HTTP status $status): $reason", t)
  final case class ParseError(ex: Exception)
      extends Error("Parsing failed", ex.some)
  final case class RequestError(field: String, reason: String)
      extends Error(s"Provided $field value is not valid: $reason")
  final case class NotFoundError(kind: String, field: String, value: Any)
      extends Error(s"$kind with $field '$value' not found")
  final case class ValidationErrors(errors: NonEmptyList[ValidationError])
      extends Error(s"Object validation failed; ${errors.map(e => s"${e.field}: ${e.message}").toList.mkString(", ")}")
  final case class AuthenticationError(reason: String, realm: Option[String] = None)
      extends Error(s"Request is unauthorized: $reason. Please authenticate in realm '${realm.getOrElse("<empty>")}'")
  final case class AuthorizationError(operation: String, kind: String, field: String, value: Any)
      extends Error(s"$operation access to $kind with $field '$value' is forbidden")
  final case class EmailError(t: Throwable)
      extends Error("Email sending failed", t.some)

}
