package ch.randm.playsit.frontend.admin.form

import cats.Show
import cats.data.NonEmptyList
import cats.implicits.{catsSyntaxOptionId, toShow}
import ch.randm.playsit.core.model.common.{Identifier, Persisted}
import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.frontend.ajax.CRUDProxyFetchStream
import ch.randm.playsit.frontend.component.Toast
import ch.randm.playsit.frontend.util.AjaxUtil
import com.raquo.laminar.api.L._

class CRUDSelector[A: CRUDProxyFetchStream: Show] extends TomSelector[Persisted[A]] {

  sealed trait Message
  case class Find(size: Long = 20, page: Long = 1, token: Option[Token] = None) extends Message
  case class FindOne(id: Identifier, token: Option[Token] = None)               extends Message
  case class Create(a: A, token: Option[Token] = None)                          extends Message
  case class Update(a: Persisted[A], token: Option[Token] = None)               extends Message
  case class Delete(id: Identifier, token: Option[Token] = None)                extends Message

  private case class ObservationInfo(
      es: List[Persisted[A]],
      sel: Option[Persisted[A]] = None,
      toast: Option[Toast] = None
  )

  private val fetch: CRUDProxyFetchStream[A] = CRUDProxyFetchStream[A]

  val path: String           = fetch.api.Root
  val bus: EventBus[Message] = new EventBus[Message]

  override protected def render(p: Persisted[A]): String = Persisted.show[A].show(p)

  override protected def additionalModifiers: Mod[HtmlElement] =
    Seq(
      bus.events.withCurrentValueOf($values).flatMap {
        case Find(size, page, token) -> _ =>
          AjaxUtil.recoverWithToast(
            fetch.find(size.some, page.some, token).map(ObservationInfo(_)),
            t => ObservationInfo(Nil, None, t.some)
          )
        case FindOne(id, token) -> _      =>
          AjaxUtil.recoverWithToast(
            fetch.findOne(id, token).map(List(_)).map(ObservationInfo(_)),
            t => ObservationInfo(Nil, None, t.some)
          )
        case Create(a, token) -> values   =>
          AjaxUtil.recoverWithToast(
            fetch.create(a, token).map(t =>
              ObservationInfo(
                values :+ t,
                t.some,
                Toast(
                  "success",
                  "Success!",
                  s"The entity with ${t.id.show} was successfully created."
                ).some
              )
            ),
            t => ObservationInfo(values, None, t.some)
          )
        case Update(a, token) -> values   =>
          AjaxUtil.recoverWithToast(
            fetch.update(a, token).mapTo(ObservationInfo(
              replaceElement(values)(a),
              a.some,
              Toast(
                "success",
                "Success!",
                s"The entity with ${a.id.show} was successfully updated."
              ).some
            )),
            t => ObservationInfo(values, None, t.some)
          )
        case Delete(id, token) -> values  =>
          AjaxUtil.recoverWithToast(
            fetch.delete(id, token).mapTo(ObservationInfo(
              deleteElement(values)(id),
              None,
              Toast(
                "success",
                "Success!",
                s"The entity with ${id.show} was successfully deleted."
              ).some
            )),
            t => ObservationInfo(values, None, t.some)
          )
      } --> { oi =>
        NonEmptyList.fromList(oi.es).foreach(nel => $values.writer.onNext(nel.toList))
        $value.writer.onNext(oi.sel)
        oi.toast.foreach(Toast.observer.onNext)
      },
      onMountBind[HtmlElement](_ => Signal.fromValue(Find(size = 100)) --> bus)
    )

  private def replaceElement(values: List[Persisted[A]])(t: Persisted[A]): List[Persisted[A]] =
    values.map {
      case e if e.id == t.id => t
      case e                 => e
    }

  private def deleteElement(values: List[Persisted[A]])(id: Identifier): List[Persisted[A]] =
    values.flatMap {
      case e if e.id == id => None
      case e               => e.some
    }

}

object CRUDSelector {

  def apply[A](implicit supplier: CRUDSelector[A]): CRUDSelector[A] = supplier

  implicit def gen[A: CRUDProxyFetchStream: Show]: CRUDSelector[A] = new CRUDSelector[A]

}
