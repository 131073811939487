package ch.randm.playsit.frontend.ajax

import cats.data.NonEmptyList
import cats.implicits.{catsSyntaxOptionId, toBifunctorOps, toTraverseOps}
import ch.randm.playsit.core.error.Error
import ch.randm.playsit.core.error.Error.{NetworkError, ParseError, ValidationErrors}
import ch.randm.playsit.core.error.ValidationError
import ch.randm.playsit.core.service.AuthenticationService.Token
import com.raquo.airstream.core.EventStream
import com.raquo.airstream.web.FetchStream
import io.circe._
import io.circe.syntax._
import org.scalajs.dom._
import routing.Call

object ProxyFetchStream {

  def run(call: Call, token: Option[Token] = None): EventStream[Unit] =
    FetchStream.withDecoder(decodeUnitResponse)(
      _ => call.method.name.asInstanceOf[HttpMethod],
      "/proxy" + call.asString,
      _.headersAppend(authorizationHeader(token): _*)
    ).map(_ => ())

  def send[In: Encoder](call: Call, data: In, token: Option[Token] = None): EventStream[Unit] =
    FetchStream.withCodec[In, Unit](_.asJson.noSpaces, decodeUnitResponse)(
      _ => call.method.name.asInstanceOf[HttpMethod],
      "/proxy" + call.asString,
      o => {
        o.headersAppend(authorizationHeader(token): _*)
        o.body(data)
      }
    ).map(_ => ())

  def expect[Out: Decoder](call: Call, token: Option[Token] = None): EventStream[Out] =
    FetchStream.withDecoder[Out](decodeResponse[Out])(
      _ => call.method.name.asInstanceOf[HttpMethod],
      "/proxy" + call.asString,
      _.headersAppend(authorizationHeader(token): _*)
    )

  def apply[In: Encoder, Out: Decoder](call: Call, data: In, token: Option[Token] = None): EventStream[Out] =
    FetchStream.withCodec[In, Out](_.asJson.noSpaces, decodeResponse[Out])(
      _ => call.method.name.asInstanceOf[HttpMethod],
      "/proxy" + call.asString,
      o => {
        o.headersAppend(authorizationHeader(token): _*)
        o.body(data)
      }
    )

  private def authorizationHeader(token: Option[Token]): Seq[(String, String)] =
    token.map(t => "Authorization" -> s"Bearer ${t.token}").toSeq

  private def decodeUnitResponse(response: Response): EventStream[Unit] =
    decodeResponse[Unit](response).recover {
      case ParseError(_) => ().some
    }

  private def decodeResponse[Out: Decoder](response: Response): EventStream[Out] =
    EventStream.fromJsPromise(response.text()).flatMap { body =>
      val parsed: Either[Error, Out] =
        if (response.ok) parseOk[Out](body)
        else if (response.status == 400) parseValidations[Out](response)(body)
        else parseError[Out](response)(body)

      EventStream.fromTry(parsed.toTry)
    }

  private def parseOk[Out: Decoder](body: String): Either[Error, Out] =
    parser.parse(body).flatMap(_.as[Out]).leftMap(ParseError)

  private def parseValidations[Out](response: Response)(body: String): Either[Error, Out] = {
    lazy val fallback = parseError[Out](response)(body)
    val cause         = getJsonField[JsonObject](body, "cause")
    if (cause.exists(_("type").contains(Json.fromString("ValidationError")))) {
      NonEmptyList.fromList(cause.flatMap(_("stacktrace")).flatMap(_.asArray).traverse(
        _.toList.traverse(_.as[ValidationError].toOption)
      ).flatten.getOrElse(Nil)).fold(fallback)(nel =>
        Left(ValidationErrors(nel))
      )
    } else fallback
  }

  private def parseError[Out](response: Response)(body: String): Either[Error, Out] =
    Left(NetworkError(response.url, response.status, getJsonField[String](body, "message").getOrElse(body)))

  private def getJsonField[R: Decoder](s: String, key: String): Option[R] =
    for {
      j <- parser.parse(s).toOption
      o <- j.asObject
      f <- o(key)
      r <- f.as[R].toOption
    } yield r

  implicit private val validationErrorDecoder: Decoder[ValidationError] = (c: HCursor) =>
    for {
      field   <- c.downField("field").as[String]
      message <- c.downField("message").as[String]
    } yield new ValidationError(field, message)

}
