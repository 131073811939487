package ch.randm.playsit.frontend.element

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement

case class NavigationElement(link: String, name: String, icon: String, active: Var[String]) {

  def render: ReactiveElement.Base =
    li(
      cls := "nav-item",
      a(
        href := link,
        cls  := "nav-link",
        cls.toggle("active") <-- active.signal.map(_ == link),
        i(className := s"bi-$icon"),
        nbsp,
        name,
        onClick.mapTo(link) --> active
      )
    )

}

object NavigationElement {

  val navigationEntries: List[NavigationElement] = {
    val active: Var[String] = Var("#home")
    List(
      NavigationElement("#home", "Home", "house", active),
      NavigationElement("#gigs", "Gigs", "calendar", active),
      NavigationElement("#subscribe", "Subscribe", "envelope-paper-heart", active),
      NavigationElement("#contact", "Contact", "chat-heart", active),
      NavigationElement("#social", "Social", "link-45deg", active)
    )
  }

}
