package ch.randm.playsit.routing

import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.config.Id
import ch.randm.playsit.core.model.common.Identifier
import ch.randm.playsit.core.service.AuthorizationService.Operation
import ch.randm.playsit.core.util.ClassName
import routing.extractor.{PathExtractor, QueryExtractor}
import routing.util.Show

import java.util.UUID
import scala.util.Try

private object implicits {

  implicit val showId: Show[Identifier] = _.value.asInstanceOf[Id].toString

  implicit val showOperation: Show[Operation] = _.toString

  implicit val showClassName: Show[ClassName[_]] = _.fullClassName

  implicit val pathExtractorId: PathExtractor[Identifier] = s => Try(UUID.fromString(s)).toOption.map(Identifier(_))

  implicit val pathExtractorOperation: PathExtractor[Operation] = Operation.byName

  implicit val pathExtractorClassName: PathExtractor[ClassName[_]] = ClassName(_).some

  implicit val queryExtractorId: QueryExtractor[Identifier] = (key, query) =>
    query.get(key).flatMap(_.headOption).flatMap(s => Try(UUID.fromString(s)).toOption.map(Identifier(_)))

}
