package ch.randm.playsit.frontend.element

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement

case class SocialLinkElement(name: String, icon: String, link: String) {

  def render: ReactiveElement.Base =
    div(
      className := "col-auto",
      a(
        href                     := link,
        target                   := "blank",
        className                := "text-white fs-3",
        title                    := name,
        i(className := s"bi-$icon"),
        dataAttr("bs-toggle")    := "tooltip",
        dataAttr("bs-placement") := "top"
      )
    )

}

object SocialLinkElement {

  val socialLinks: List[SocialLinkElement] = List(
    SocialLinkElement("Bandcamp", "cart2", "https://jaroffbeat.bandcamp.com"),
    SocialLinkElement("YouTube", "youtube", "https://youtube.com/user/jaroffbeat"),
    SocialLinkElement("SoundCloud", "soundwave", "https://soundcloud.com/jaroffbeat"),
    SocialLinkElement("Instagram", "instagram", "https://instagram.com/jaroffbeat"),
    SocialLinkElement("Facebook", "facebook", "https://facebook.com/jaroffbeat")
  )

}
