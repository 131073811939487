package ch.randm.playsit.core.model

import cats.Show

import java.time.ZoneId

/** This class describes the geographical location where a [[Venue]] or [[ch.randm.playsit.core.model.common.Person]]
  * may be located.
  *
  * @param street
  *   Name of the street
  * @param streetNumber
  *   Number of the location at the given street
  * @param streetNumberAddon
  *   Additional information to identify the location (if the number does not suffice)
  * @param location
  *   Name of the town
  * @param postalCode
  *   Postal or ZIP code
  * @param state
  *   State of the location
  * @param country
  *   Country of the location (ISO 3166 alpha-2 country code)
  * @param timeZone
  *   The location's timezone information
  * @param coordinates
  *   Google Map coordinates (x, y)
  */
case class Address(
    street: String,
    streetNumber: Option[Int],
    streetNumberAddon: Option[String],
    location: String,
    postalCode: String,
    state: String,
    country: String,
    timeZone: ZoneId,
    coordinates: (Double, Double)
)

object Address {

  implicit val show: Show[Address] = a =>
    s"${a.street} ${a.streetNumber.mkString}${a.streetNumberAddon.mkString}, ${a.postalCode} ${a.location} ${a.state}"

}
