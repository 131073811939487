package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.{Persisted, Text}

import java.time.LocalDate

/** A record is any kind of compilation of songs that the Artist publishes. That may be a collection of downloads, an EP
  * or LP.
  *
  * @param name
  *   Name of the Record
  * @param releaseDate
  *   Release date
  * @param teaser
  *   A teaser text for description
  * @param songs
  *   Songs that are present on this Record
  * @param links
  *   A electronic location where the Record can be obtained
  * @param cover
  *   The cover image
  * @param artist
  *   The Artist responsible for this production
  */
case class Record(
    name: String,
    releaseDate: LocalDate,
    teaser: Text,
    songs: List[Persisted[Song]],
    links: List[Persisted[ElectronicAddress]],
    artist: Persisted[Artist],
    cover: Option[Persisted[Asset]]
)

object Record {

  implicit val show: Show[Record] = r => s"${r.artist.print} – ${r.name} (${r.releaseDate.getYear})"

}
