package ch.randm.playsit.frontend.admin.page

import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.frontend.Admin
import ch.randm.playsit.frontend.admin.State
import ch.randm.playsit.frontend.component.Toast
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement.Base
import com.raquo.waypoint.Router

trait AuthedPage extends Page {

  val $state: Var[State]

  final override def render(router: Router[Page]): Base = {
    val state = $state.now()
    state.validToken(authedRender(_, router)) {
      Toast.observer.onNext(Toast("danger", "Unauthorized", "You are not allowed to view this page."))
      router.pushState(Admin.pages.login)
      mainTag()
    }
  }

  protected def authedRender(token: Token, router: Router[Page]): Base

}
