package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.{Persisted, Person}

/** A band member is a [[ch.randm.playsit.core.model.common.Person]] responsible to create the art that is distributed
  * by the Artist or Band itself.
  *
  * @param firstName
  *   First name(s)
  * @param lastName
  *   Last name(s)
  * @param address
  *   Geographical location
  * @param electronicAddresses
  *   A list of electronic addresses
  * @param role
  *   The role this member holds within the band
  */
case class BandMember(
    firstName: String,
    lastName: String,
    address: Option[Persisted[Address]],
    electronicAddresses: List[Persisted[ElectronicAddress]],
    role: String
) extends Person

object BandMember {

  implicit val show: Show[BandMember] = b => s"${b.firstName} ${b.lastName} (${b.role})"

}
