package ch.randm.playsit.frontend.admin.page

import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.frontend.Admin
import ch.randm.playsit.frontend.admin.State
import ch.randm.playsit.frontend.ajax.ProxyFetchStream
import ch.randm.playsit.frontend.component.Toast
import ch.randm.playsit.frontend.util.{AjaxUtil, FormUtil}
import ch.randm.playsit.json._
import ch.randm.playsit.routing.AuthenticationApi
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement.Base
import com.raquo.waypoint.Router
import io.circe._
import io.circe.generic.auto._
import org.scalajs.dom

case class LoginPage($state: Var[State]) extends Page {

  override val path: String  = "login"
  override val title: String = "Login"

  private lazy val submitBus = new EventBus[dom.HTMLFormElement]

  override def render(router: Router[Page]): Base =
    div(
      className := "container-xxl",
      form(
        cls         := "position-absolute top-50 start-50 translate-middle text-center",
        minWidth.px := 330,
        img(cls     := "mb-4", src := "/public/img/logo.png", width.px := 72),
        h1(cls      := "h3 mb-3 fw-normal", "Please sign in"),
        div(
          className := "form-floating",
          input(
            idAttr          := "userField",
            className       := "form-control rounded-bottom-0",
            marginBottom.px := -1,
            typ             := "text",
            nameAttr        := "user",
            placeholder     := "User"
          ),
          label(forId       := "userField", "User")
        ),
        div(
          className := "form-floating",
          input(
            idAttr      := "passwordField",
            className   := "form-control rounded-top-0 mb-3",
            typ         := "password",
            nameAttr    := "password",
            placeholder := "Password"
          ),
          label(forId   := "passwordField", "Password")
        ),
        div(
          cls       := "checkbox mb-3",
          label(
            input(typ := "checkbox", nameAttr := "remember-me"),
            nbsp,
            "Remember me"
          )
        ),
        button(
          typ       := "submit",
          className := "w-100 btn btn-lg btn-primary",
          i(cls := "bi-box-arrow-in-right"),
          nbsp,
          "Sign in"
        ),
        inContext(thisNode => onSubmit.preventDefault.mapTo(thisNode.ref) --> submitBus),
        AjaxUtil.recoverWithToast(submitBus.events
          .map(FormUtil.FormData[Json].parse)
          .map(_.fold(throw _, identity))
          .flatMap(ProxyFetchStream[Json, Token](AuthenticationApi.Login(), _))
          .map { t =>
            $state.update(_.copy(token = t.some, user = t.user.get.some))
            router.pushState(Admin.pages.dashboard)
            Toast(
              "success",
              "Success!",
              s"You have been logged in. Welcome, ${t.user.get.name}."
            )
          }) --> Toast.observer
      )
    )

}
