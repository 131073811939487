package ch.randm.playsit.core.model.common

import cats.Show
import cats.kernel.Semigroup

/** The Identifier is used for persistence, where it uniquely identifies a stored entity.
  *
  * @param value
  *   Unique value of the identifier
  */
case class Identifier(value: Any)

object Identifier {

  implicit val semigroup: Semigroup[Identifier] = Semigroup.last

  implicit val show: Show[Identifier] = id => s"ID: ${id.value}"

}
