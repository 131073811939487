package ch.randm.playsit.frontend.admin.form

import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.model.Asset.File
import ch.randm.playsit.core.model.common.Text.TextType
import ch.randm.playsit.core.model.common.{Persisted, Text}
import com.raquo.laminar.api.L._
import formula.Form.FormVar
import formula.{Form, FormValue}
import org.scalajs.dom.Chunk

import java.time.format.DateTimeFormatter
import java.time.{Duration, Instant, LocalDateTime, ZoneId, ZoneOffset}
import java.util.Base64
import scala.concurrent.duration.{FiniteDuration, SECONDS}
import scala.jdk.CollectionConverters._
import scala.jdk.DurationConverters._
import scala.scalajs.js.typedarray.Uint8Array
import scala.util.Try

object implicits {

  private object F {
    def apply[A](implicit form: Form[A]): Form[A] = form
  }

  implicit val textForm: Form[Text] = Form.Input.make { config =>
    val var0    = config.validate(FormVar.make(Text("")))
    val touched = Var(false)

    val node =
      div(
        TextType.values.map { tt =>
          div(
            cls := "form-check form-check-inline",
            input(
              typ      := "radio",
              cls      := "form-check-input",
              nameAttr := tt.toString,
              idAttr   := tt.toString,
              value    := tt.toString,
              controlled(
                checked <-- var0.signal.map(_.value.typ == tt),
                onClick.mapToValue --> { TextType.byName(_).foreach(t => var0.update(_.value.copy(typ = t))) }
              )
            ),
            label(cls  := "form-check-label", forId := tt.toString, tt.toString)
          )
        },
        textArea(
          cls.toggle("is-invalid") <-- var0.signal.combineWithFn(touched.signal) {
            _.warnings.nonEmpty && _
          },
          config.modifiers,
          onInput.mapTo(true) --> touched,
          controlled(
            value <-- var0.signal.map(_.value.value),
            onInput.mapToValue --> { string => var0.update(_.value.copy(value = string)) }
          )
        )
      )

    FormValue(var0, node)
  }

  implicit val durationForm: Form[FiniteDuration] = {
    def extract(s: String): Option[FiniteDuration] = {
      val upper = s.toUpperCase
      Try(Duration.parse(if (!upper.startsWith("PT")) s"PT$upper" else upper).toScala).toOption
    }

    Form
      .string
      .validate(extract(_).isDefined, "Duration is invalid")
      .xmap(extract(_).getOrElse(FiniteDuration(0, SECONDS)))(_.toJava.toString().substring(2).toLowerCase)
  }

  implicit val instantForm: Form[Instant] =
    Form.Input.make { config =>
      val formatter = DateTimeFormatter.ISO_LOCAL_DATE_TIME

      val var0 = FormVar.make(Instant.now)
      val node = input(
        config.copy(inputType = "datetime-local").modifiers,
        `type`("datetime-local"),
        controlled(
          value <-- var0.signal.map(i =>
            LocalDateTime.ofInstant(i.value, ZoneOffset.UTC).withSecond(0).withNano(0).format(formatter)
          ),
          onInput.mapToValue --> { text =>
            val result = LocalDateTime.parse(text, formatter).toInstant(ZoneOffset.UTC)
            var0.set(result)
          }
        )
      )
      FormValue(var0, node)
    }

  implicit val zoneIdForm: Form[ZoneId] =
    TomSelector((z: ZoneId) => z.getId, ZoneId.getAvailableZoneIds.asScala.toList.sorted.map(ZoneId.of))
      .selector
      .default(ZoneId.systemDefault().some)

  implicit val coordinatesForm: Form[(Double, Double)] = {
    def extract(s: String): Option[(Double, Double)] =
      s.split(",").toList.flatMap(_.strip().toDoubleOption) match {
        case h :: t :: _ => (h -> t).some
        case _           => None
      }

    Form
      .string
      .validate(extract(_).isDefined, "Coordinates are invalid")
      .xmap(extract(_).getOrElse(0.0 -> 0.0))(t => s"${t._1}, ${t._2}")
  }

  implicit val fileForm: Form[File] =
    Form.Input.make { config =>
      val var0  = FormVar.make(File("", None, 0L))
      val ready = Var(true)

      val node =
        Seq(
          input(
            config.copy(inputType = "file", helpText = "").modifiers,
            `type`("file"),
            onInput.mapToFiles --> { files =>
              ready.set(false)
              files.headOption.foreach { f =>
                // Reset the name of the file (no data yet)
                var0.set(File(f.name, None, 0L))

                val reader       = f.stream().getReader()
                def read(): Unit = {
                  val _ = reader.read().`then`[Unit] { (c: Chunk[Uint8Array]) =>
                    if (!c.done) {
                      var0.update { v =>
                        val file  = v.value
                        val chunk = c.value.map(_.byteValue).toArray
                        file.copy(
                          base64Data = Base64.getEncoder.encodeToString(file.data :++ chunk).some,
                          size = file.size + c.value.size
                        )
                      }
                      read()
                    } else {
                      ready.set(true)
                    }
                  }
                }

                read()
              }

            }
          ),
          child <-- ready.signal.flatMap { r =>
            if (r) {
              var0.signal.map(_.value).map { file =>
                div(
                  cls("form-text"),
                  s"Selected file: ${file.path} (${file.size / 1000}KB)"
                )
              }
            } else {
              Signal.fromValue(
                div(
                  cls("form-text"),
                  span(cls := "spinner-border spinner-border-sm", role := "status", aria.hidden := true),
                  nbsp,
                  "Uploading..."
                )
              )
            }
          }
        )

      FormValue(var0, node)
    }

  implicit def listForm[A: Form]: Form[List[A]] = Form.Many(F[A])

  implicit def mapForm[A, B](implicit form: Form[(A, B)]): Form[Map[A, B]] = listForm[(A, B)].xmap(_.toMap)(_.toList)

  implicit def optionalPersistedForm[A: CRUDSelector]: Form[Option[Persisted[A]]] = CRUDSelector[A].optionalSelector
  implicit def persistedForm[A: CRUDSelector]: Form[Persisted[A]]                 = CRUDSelector[A].selector

}
