package ch.randm.playsit.routing

import ch.randm.playsit.core.model.common.Identifier
import ch.randm.playsit.core.util.ClassName
import implicits._
import routing._

abstract class CRUDApi[T: ClassName] {

  def Root: String = ClassName[T].dashed

  val Create  = Method.POST / Root
  val Read    = Method.GET / Root / pathVar[Identifier]("id")
  val ReadAll = Method.GET / Root / "all" :? optionalQueryParam[Long]("size") :? optionalQueryParam[Long]("page")
  val Update  = Method.PUT / Root / pathVar[Identifier]("id")
  val Delete  = Method.DELETE / Root / pathVar[Identifier]("id")

}

object CRUDApi {

  def apply[A](implicit ev: CRUDApi[A]): CRUDApi[A] = ev

  implicit def gen[A: ClassName]: CRUDApi[A] = new CRUDApi[A] {}

}
