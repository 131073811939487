package ch.randm.playsit.frontend

import org.scalajs.dom.document

object Main extends App {

  Option(document.querySelector("#app")).foreach(App.init)
  Option(document.querySelector("#admin")).foreach(Admin.init)

}
