package ch.randm.playsit.core.model

import cats.Show
import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.model.common.{Temporal, Text}

import java.time.Instant
import java.util.Base64

/** An asset is a file that was uploaded to the server running this instance of playsit. An asset may be stored on the
  * server's file system, or in a file database (e.g. GridFS from MongoDB).
  *
  * @param name
  *   The name of the file (not technical)
  * @param description
  *   Description of the file
  * @param file
  *   The asset's stored file
  * @param created
  *   Timestamp of creation
  * @param updated
  *   Timestamp of last update
  */
case class Asset(
    name: String,
    description: Option[Text],
    file: Asset.File,
    override val created: Option[Instant],
    override val updated: Option[Instant]
) extends Temporal

object Asset {

  /** A file stored on the server.
    *
    * @param path
    *   The technical file name, including the extension
    * @param base64Data
    *   The contents of the file, encoded in base64 as an UTF-8 String; may be `None` if not loaded
    * @param size
    *   Size of the file in bytes
    */
  case class File(path: String, base64Data: Option[String], size: Long) {

    /** Base64-Decoded data */
    lazy val data: Array[Byte] = base64Data.map(Base64.getDecoder.decode).getOrElse(Array.empty)

  }

  object File {
    def apply(path: String, data: Array[Byte]): File =
      File(path, Base64.getEncoder.encodeToString(data).some, data.length.toLong)
  }

  implicit val show: Show[Asset] = a => s"${a.name} (${a.file.path}, ${a.file.size / 1000}KB)"

}
