package ch.randm.playsit.core.model

import cats.Show
import cats.data.ValidatedNel
import cats.implicits.catsSyntaxTuple3Semigroupal
import ch.randm.playsit.core.error.ValidationError

import java.time.{Instant, LocalDate}
import ch.randm.playsit.core.model.Engagement.{TimeType, Times}
import ch.randm.playsit.core.model.common.{Persisted, Temporal, Text, Validated}
import ch.randm.playsit.core.model.common.Validated.IsEmpty._

/** Any performance given by an artist is an Engagement.
  *
  * @param name
  *   Name or description
  * @param description
  *   Further description of the event
  * @param venue
  *   Venue this Engagement is played at
  * @param artists
  *   A list of Artists that perform at this event
  * @param dates
  *   The date this Engagement takes place (can be multiple, e.g. for festivals)
  * @param times
  *   A list of times that are relevant for the Engagement
  * @param ticketSales
  *   Ways for the fans to get tickets for the event
  * @param created
  *   Timestamp of creation
  * @param updated
  *   Timestamp of last update
  */
case class Engagement(
    name: String,
    description: Option[Text],
    venue: Persisted[Venue],
    artists: List[Persisted[Artist]],
    dates: List[LocalDate],
    times: Times,
    ticketSales: List[Persisted[TicketSale]],
    flyer: Option[Persisted[Asset]],
    override val created: Option[Instant] = None,
    override val updated: Option[Instant] = None
) extends Temporal with Validated[Engagement] {

  override def validate: ValidatedNel[ValidationError, Engagement] =
    (notEmpty(name, "name"), notEmpty(dates, "dates"), contains(times.keys, TimeType.StageTime, "times.StageTime"))
      .mapN((_, _, _) => this)

}

object Engagement {

  /** An [[Engagement]] can be linked to multiple Times. Each of these represents a specific point in time relevant for
    * the Engagement, e.g. the moment the doors of the [[Venue]] open for the public or the time the artist starts its
    * performance.
    */
  type Times = Map[TimeType, Instant]

  /** Enum that is used to define the type of a [[Times]].
    */
  sealed trait TimeType

  object TimeType {
    case object GetIn      extends TimeType
    case object SoundCheck extends TimeType
    case object Catering   extends TimeType
    case object DoorsOpen  extends TimeType
    case object StageTime  extends TimeType
    case object GetOut     extends TimeType
    case object End        extends TimeType

    val values: Seq[TimeType] =
      Seq(GetIn, SoundCheck, Catering, DoorsOpen, StageTime, GetOut, End)

    def byName(s: String): Option[TimeType] = values.find(_.toString == s)
  }

  implicit val show: Show[Engagement] = e => s"${e.name}, ${e.venue.print}"

}
