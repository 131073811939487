package ch.randm.playsit.frontend.component

import cats.implicits.catsSyntaxOptionId
import ch.randm.playsit.core.model.{ElectronicAddress, Subscriber}
import ch.randm.playsit.core.model.ElectronicAddress.ElectronicAddressType
import ch.randm.playsit.core.model.common._
import ch.randm.playsit.json._
import ch.randm.playsit.frontend.ajax.ProxyFetchStream
import ch.randm.playsit.frontend.util.{AjaxUtil, FormUtil}
import ch.randm.playsit.routing.CRUDApi
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement
import io.circe.generic.auto._
import org.scalajs.dom.{document, html, Event}
import typings.bootstrap.anon.PartialOptionsBackdrop
import typings.bootstrap.mod.Modal

case object SubscribeComponent {

  private val $modal: Var[Option[NameModal]]    = Var(None)
  private val emailSubmitBus: EventBus[String]  = EventBus()
  private val nameSubmitBus: EventBus[NameForm] = EventBus()

  private case class NameForm(firstName: String, lastName: String)

  private case class NameModal(subscriber: Persisted[Subscriber]) {

    private val id         = subscriber.id.value.toString
    private val lbl        = s"label-$id"
    private lazy val modal = new Modal(s"#$id", PartialOptionsBackdrop())

    def render: ReactiveElement.Base =
      div(
        idAttr          := id,
        cls             := "modal fade",
        tabIndex        := -1,
        aria.hidden     := true,
        aria.labelledBy := lbl,
        div(
          cls  := "modal-dialog",
          role := "document",
          div(
            cls := "modal-content rounded-4 shadow",
            div(
              cls := "modal-header p-5 pb-4 border-bottom-0",
              h1(idAttr                := lbl, cls := "fw-bold mb-0 fs-2", i(cls := "bi-stars"), nbsp, "Subscribed"),
              button(
                tpe                    := "button",
                cls                    := "btn-close",
                dataAttr("bs-dismiss") := "modal",
                dataAttr("aria-label") := "Close"
              )
            ),
            div(
              cls := "modal-body p-5 pt-0",
              form(
                cls := "",
                div(
                  cls := "form-floating mb-3",
                  input(
                    tpe         := "text",
                    cls         := "form-control rounded-3",
                    nameAttr    := "firstName",
                    idAttr      := s"first-name-$lbl",
                    placeholder := ""
                  ),
                  label(forId   := s"first-name-$lbl", i(cls := "bi-1-circle"), nbsp, "First name")
                ),
                div(
                  cls := "form-floating mb-3",
                  input(
                    tpe         := "text",
                    cls         := "form-control rounded-3",
                    nameAttr    := "lastName",
                    idAttr      := s"last-name-$lbl",
                    placeholder := ""
                  ),
                  label(forId   := s"last-name-$lbl", i(cls := "bi-2-circle"), nbsp, "Last name")
                ),
                div(
                  cls := "d-grid gap-2",
                  button(
                    cls                    := "btn btn-lg rounded-3 btn-primary",
                    tpe                    := "submit",
                    i(cls := "bi-person-vcard"),
                    nbsp,
                    "Update"
                  ),
                  button(
                    cls                    := "btn btn-lg rounded-3 btn-secondary mb-2",
                    tpe                    := "button",
                    dataAttr("bs-dismiss") := "modal",
                    dataAttr("aria-label") := "Close",
                    "Close"
                  )
                ),
                small(
                  cls := "text-body-secondary",
                  s"You are already signed up for the newsletter with ",
                  strong(subscriber.get.email.mkString),
                  ", cheers for that. We'd love to know a bit more about you if you don't mind."
                ),
                inContext(thisNode =>
                  onSubmit.preventDefault
                    .mapTo(thisNode.ref)
                    .map(FormUtil.FormData[NameForm].parse)
                    .map(_.fold(throw _, identity)) --> nameSubmitBus
                ),
                AjaxUtil.recoverWithToast(
                  nameSubmitBus.events
                    .map(f => subscriber.map(_.copy(firstName = f.firstName, lastName = f.lastName)))
                    .flatMap(s =>
                      ProxyFetchStream[Subscriber, Persisted[Subscriber]](CRUDApi[Subscriber].Update(s.id), s.get)
                    )
                    .mapTo(Toast(
                      "success",
                      "Success!",
                      "Thank you for registering to our newsletter! We'll keep you posted."
                    ))
                ) --> { t => modal.hide(); Toast.observer.onNext(t) }
              )
            )
          )
        ),
        inContext(_.events(eventProp[Event]("hidden.bs.modal")).mapTo(None) --> $modal.writer),
        onMountUnmountCallback(
          _ => {
            modal.show()
            document.getElementById("subscribeEmail") match {
              case i: html.Input => i.value = ""
              case _             => ()
            }
          },
          _ => modal.dispose()
        )
      )

  }

  def render: ReactiveElement.Base =
    div(
      idAttr := "subscribe",
      cls    := "container",
      div(
        cls := "row align-items-center g-lg-5 py-5",
        div(
          cls := "col-lg-7 text-center text-lg-start",
          h2(
            cls := "display-5 fw-bold lh-1 text-body-emphasis mb-3",
            i(cls := "bi-envelope-paper-heart-fill"),
            nbsp,
            "Subscribe to our newsletter!"
          ),
          p(
            cls := "col-lg-10 fs-5",
            "To stay informed about all of our upcoming concerts, new releases and other updates, tell us how we can reach your inbox and we'll keep you posted. (But not too much, promise!)"
          )
        ),
        div(
          cls := "col-md-10 mx-auto col-lg-5",
          form(
            cls := "p-4 p-md-5 border rounded-3 bg-body-tertiary bg-opacity-75",
            div(
              cls     := "form-floating mb-3",
              input(idAttr := "subscribeEmail", typ := "email", cls := "form-control", placeholder := ""),
              label(forId  := "subscribeEmail", i(cls := "bi-at"), nbsp, "Email")
            ),
            button(
              cls     := "w-100 btn btn-lg btn-primary",
              typ     := "submit",
              i(cls := "bi-envelope-plus"),
              nbsp,
              "Subscribe"
            ),
            hr(cls    := "my-4"),
            small(cls := "text-body-secondary", "You will be able to unsubscribe anytime."),
            inContext(thisNode =>
              onSubmit.preventDefault
                .mapTo(thisNode.ref)
                .map(FormUtil.FormData[String].parse)
                .map(_.fold(throw _, identity)) --> emailSubmitBus
            ),
            emailSubmitBus.events
              .map(ElectronicAddress(ElectronicAddressType.Email, _))
              .flatMap(ProxyFetchStream[ElectronicAddress, Persisted[ElectronicAddress]](
                CRUDApi[ElectronicAddress].Create(),
                _
              ))
              .map(e => Subscriber("", "", None, e :: Nil))
              .flatMap(
                ProxyFetchStream[Subscriber, Persisted[Subscriber]](CRUDApi[Subscriber].Create(), _)
              )
              .map(NameModal(_).some) --> $modal.writer
          )
        )
      ),
      child.maybe <-- $modal.signal.map(_.map(_.render))
    )

}
