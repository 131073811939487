package ch.randm.playsit.core.service

import cats.data.EitherT
import ch.randm.playsit.core.error.Error
import ch.randm.playsit.core.model.common.{Identifier, Persisted, Temporal}
import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.core.service.AuthenticationService.Token.TokenType
import ch.randm.playsit.core.service.AuthorizationService.User

import java.time.Instant

trait AuthenticationService[F[_]] {

  /** Logs in a `User` by generating an `TokenType.Auth` token, which needs to be stored both on the client-side and the
    * backend. On consecutive requests to the secure API, the generated token must be passed along.
    *
    * @param user
    *   The user identifier (usually name or email) used to authenticate
    * @param password
    *   The users secret password
    * @return
    *   The token which was generated and stored in the database
    */
  def login(user: String, password: String): EitherT[F, Error, Token]

  /** Removes all of a `User`'s `TokenType.Auth` tokens from the storage, effectively un-authorizing any subsequent
    * calls to the API.
    *
    * @param userId
    *   The id of the user to log out
    */
  def logout(userId: Identifier): EitherT[F, Error, Unit]

  /** @param password
    *   An unencrypted password
    * @return
    *   The encrypted password
    */
  def encryptPassword(password: String): String

}

object AuthenticationService {

  case class Token(
      token: String,
      tokenType: TokenType,
      user: Persisted[User],
      expires: Instant,
      created: Option[Instant] = None,
      updated: Option[Instant] = None
  ) extends Temporal

  object Token {

    sealed trait TokenType

    object TokenType {
      final case object Auth          extends TokenType
      final case object PasswordReset extends TokenType

      val values: Seq[TokenType] = Seq(Auth, PasswordReset)

      def byName(s: String): Option[TokenType] = values.find(_.toString == s)
    }

  }

}
