package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.Text

/** Used to display dynamic content.
  *
  * @param key
  *   Lookup key to find this element in the persistent storage
  * @param content
  *   The element's content
  */
case class DynamicContent(key: String, content: Text)

object DynamicContent {

  implicit val show: Show[DynamicContent] = _.key

}
