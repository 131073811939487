package ch.randm.playsit.routing

import ch.randm.playsit.core.model.Engagement
import routing._

object EngagementApi extends CRUDApi[Engagement] {

  val Future = Method.GET / Root / "future" :? optionalQueryParam[Long]("size") :? optionalQueryParam[Long]("page")
  val Past   = Method.GET / Root / "past" :? optionalQueryParam[Long]("size") :? optionalQueryParam[Long]("page")

}
