package ch.randm.playsit.frontend.component

import ch.randm.playsit.frontend.ajax.ProxyFetchStream
import ch.randm.playsit.frontend.util.{AjaxUtil, FormUtil}
import ch.randm.playsit.routing.ContactApi
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement
import io.circe.Json
import org.scalajs.dom

case object ContactComponent {

  private val submitBus = EventBus[dom.HTMLFormElement]()
  private val resetBus  = EventBus[Unit]()

  def render: ReactiveElement.Base =
    div(
      idAttr := "contact",
      cls    := "container",
      div(
        cls := "row align-items-center g-lg-5 py-5",
        div(
          cls := "col-lg-7 order-lg-2 text-center text-lg-start",
          h2(
            cls := "display-5 fw-bold lh-1 text-body-emphasis mb-3",
            i(cls := "bi-chat-heart-fill"),
            nbsp,
            "Send us a message"
          ),
          p(
            cls := "col-lg-10 fs-5",
            "We love hearing from you! Use the form for booking requests and general feedback."
          )
        ),
        div(
          cls := "col-md-10 order-lg-1 mx-auto col-lg-5",
          form(
            cls := "p-4 p-md-5 border rounded-3 bg-body-tertiary bg-opacity-75",
            div(
              cls := "form-floating mb-3",
              input(
                idAttr      := "contactName",
                typ         := "text",
                nameAttr    := "name",
                cls         := "form-control",
                placeholder := "",
                value <-- resetBus.events.mapTo("")
              ),
              label(forId   := "contactName", i(cls := "bi-person"), "Name")
            ),
            div(
              cls := "form-floating mb-3",
              input(
                idAttr      := "contactEmail",
                typ         := "email",
                nameAttr    := "email",
                cls         := "form-control",
                placeholder := "",
                value <-- resetBus.events.mapTo("")
              ),
              label(forId   := "contactEmail", i(cls := "bi-at"), "Email")
            ),
            div(
              cls := "form-floating mb-3",
              textArea(
                idAttr      := "contactMessage",
                nameAttr    := "message",
                cls         := "form-control",
                styleAttr   := "height: 150px;",
                placeholder := "",
                value <-- resetBus.events.mapTo("")
              ),
              label(forId   := "contactMessage", i(cls := "bi-chat"), nbsp, "Message")
            ),
            button(
              cls := "w-100 btn btn-lg btn-primary",
              tpe := "submit",
              i(cls := "bi-send"),
              nbsp,
              "Send"
            ),
            inContext(el => onSubmit.preventDefault.mapTo(el.ref) --> submitBus),
            AjaxUtil.recoverWithToast(
              submitBus.events
                .map(FormUtil.FormData[Json].parse)
                .map(_.fold(throw _, identity))
                .flatMap(ProxyFetchStream.send[Json](ContactApi.Submit(), _))
                .mapTo(resetBus.emit(()))
                .mapTo(Toast(
                  "success",
                  "Email sent!",
                  "We got your message and will handle it with great care. Thanks!"
                ))
            ) --> Toast.observer
          )
        )
      )
    )

}
