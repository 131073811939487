package ch.randm.playsit.routing

import routing._

object AuthenticationApi {

  val Login          = Method.POST / "auth" / "login"
  val Logout         = Method.POST / "auth" / "logout"
  val ForgotPassword = Method.POST / "auth" / "forgot-password"
  val ResetPassword  = Method.POST / "auth" / "reset-password"

}
