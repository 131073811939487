package ch.randm.playsit.frontend.ajax

import ch.randm.playsit.core.model.common.{Identifier, Persisted}
import ch.randm.playsit.core.service.AuthenticationService.Token
import ch.randm.playsit.json._
import ch.randm.playsit.routing.CRUDApi
import com.raquo.laminar.api.L._
import io.circe._

class CRUDProxyFetchStream[A: CRUDApi: Encoder: Decoder] {

  val api: CRUDApi[A] = CRUDApi[A]

  def find(
      size: Option[Long] = None,
      page: Option[Long] = None,
      token: Option[Token] = None
  ): EventStream[List[Persisted[A]]] = ProxyFetchStream.expect[List[Persisted[A]]](api.ReadAll(size, page), token)

  def findOne(id: Identifier, token: Option[Token] = None): EventStream[Persisted[A]] =
    ProxyFetchStream.expect[Persisted[A]](api.Read(id), token)

  def create(a: A, token: Option[Token] = None): EventStream[Persisted[A]] =
    ProxyFetchStream[A, Persisted[A]](api.Create(), a, token)

  def update(a: Persisted[A], token: Option[Token] = None): EventStream[Persisted[A]] =
    ProxyFetchStream[A, Persisted[A]](api.Update(a.id), a.get, token)

  def delete(id: Identifier, token: Option[Token] = None): EventStream[Unit] =
    ProxyFetchStream.run(api.Delete(id), token)

}

object CRUDProxyFetchStream {

  def apply[A](implicit ev: CRUDProxyFetchStream[A]): CRUDProxyFetchStream[A] = ev

  implicit def gen[A: CRUDApi: Encoder: Decoder]: CRUDProxyFetchStream[A] = new CRUDProxyFetchStream[A]

}
