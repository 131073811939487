package ch.randm.playsit.frontend.admin.page

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement.Base
import com.raquo.waypoint.Router

trait LinkedPage { self: Page =>

  val icon: String

  def link(router: Router[Page]): Base     = navigationEntry(router)("nav-link")
  def dropdown(router: Router[Page]): Base = navigationEntry(router)("dropdown-item")

  private def navigationEntry(router: Router[Page])(className: String) =
    li(
      cls := "nav-item",
      cls.toggle("active") <-- router.currentPageSignal.map(_.path == path),
      a(
        cls  := className,
        href := router.absoluteUrlForPage(self),
        i(cls := s"bi-$icon"),
        nbsp,
        title,
        onClick.preventDefault.mapTo(self) --> { (p: Page) => router.pushState(p) }
      )
    )

}
