package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.{Organization, Persisted}

/** A Venue is a place (e.g. club, concert hall, building, festival, ...) where an [[Engagement]] is performed or
  * [[TicketSale]]s are processed.
  *
  * @param name
  *   Name of the Venue
  * @param capacity
  *   Amount of allowed guests
  * @param address
  *   The Venue's geographical location
  * @param electronicAddresses
  *   Any number of electronic addresses
  * @param employees
  *   List of employees (relevant for an Artist) that work here
  */
case class Venue(
    name: String,
    capacity: Int,
    address: Persisted[Address],
    electronicAddresses: List[Persisted[ElectronicAddress]],
    employees: List[Persisted[Promoter]]
) extends Organization

object Venue {

  implicit val show: Show[Venue] = v => s"${v.name}, ${v.address.get.location}"

}
