package ch.randm.playsit.routing

import ch.randm.playsit.core.model.common.Identifier
import ch.randm.playsit.core.util.ClassName
import routing._
import implicits._

object AuthorizationApi {

  private val targetClass = pathVar[ClassName[_]]("targetClass")
  private val targetId    = optionalQueryParam[Identifier]("id")

  val Permissions = Method.GET / "authorization" / targetClass :? targetId
  val Grant       = Method.POST / "authorization" / "grant"
  val Deny        = Method.POST / "authorization" / "deny"

}
