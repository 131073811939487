package ch.randm.playsit.frontend.element

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement

case class CarouselElement(image: String, description: String, isActive: Boolean = false) {

  private val classes = "carousel-item" :: (if (isActive) List("active") else Nil)

  def render: ReactiveElement.Base =
    div(
      className := classes.mkString(" "),
      img(src := image, alt := description)
    )

}

object CarouselElement {

  private val active = scala.util.Random.nextInt(3)

  val carouselElements: List[CarouselElement] =
    List(
      CarouselElement("/proxy/asset/download/carousel01.jpg", "...", active == 0),
      CarouselElement("/proxy/asset/download/carousel02.jpg", "...", active == 1),
      CarouselElement("/proxy/asset/download/carousel03.jpg", "...", active == 2)
    )

}
