package ch.randm.playsit.core.model

import cats.Show
import ch.randm.playsit.core.model.common.{Persisted, Text}

/** An Artist is a single person or a group of people coming together to perform music. Each member of the artist group
  * is called a [[BandMember]].
  *
  * @param name
  *   The name of the Artist
  * @param bandMembers
  *   Members of the collective
  * @param electronicAddresses
  *   Electronic addresses of the Artist (email, websites, social media links, ...)
  * @param biography
  *   A few paragraphs describing the Artist and his career
  */
case class Artist(
    name: String,
    bandMembers: List[Persisted[BandMember]],
    electronicAddresses: List[Persisted[ElectronicAddress]],
    biography: Option[Text]
)

object Artist {

  implicit val show: Show[Artist] = _.name

}
