package ch.randm.playsit.core.util

import com.hypertino.inflector.English
import com.hypertino.inflector.naming._

import scala.reflect.ClassTag
import scala.util.matching.Regex

trait ClassName[A] {

  private val WordFinder: Regex      = "(([A-Z]?[a-z]+)|([A-Z]))".r
  private val PackageSeparator: Char = '.'
  private val ObjectSeparator: Char  = '$'

  def fullClassName: String

  def packageName: String = fullClassName.split(PackageSeparator).dropRight(1).mkString(PackageSeparator.toString)
  def className: String   = fullClassName.split(PackageSeparator).lastOption.getOrElse("")
  def objectName: String  = className.split(ObjectSeparator).lastOption.getOrElse("")

  def dashed: String = CamelCaseToDashCaseConverter.convert(objectName)

  def singular: String =
    WordFinder.findAllIn(objectName).map(_.toList).map {
      case h :: t => s"${h.toUpper}${t.mkString}"
      case Nil    => ""
    }.mkString(" ")

  def plural: String = English.plural(singular)

  override def toString: String = fullClassName

  override def equals(obj: Any): Boolean =
    obj match {
      case cn: ClassName[_] => cn.fullClassName == fullClassName
      case _                => false
    }

  override def hashCode: Int = toString.hashCode

}

object ClassName {

  def apply[A](implicit cn: ClassName[A]): ClassName[A] = cn

  def apply[A](myClassName: String): ClassName[A] =
    new ClassName[A] {
      override def fullClassName: String = myClassName
    }

  implicit def classNameFromClassTag[A](implicit ct: ClassTag[A]): ClassName[A] = apply[A](ct.runtimeClass.getName)

}
