package ch.randm.playsit.frontend.component

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.DomApi
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement
import org.scalajs.dom
import typings.bootstrap.mod.{Toast => BToast}

import scala.util.Random
import com.raquo.laminar.nodes.ReactiveHtmlElement

case class Toast(clazz: String, title: String, message: String) {

  private val $tick  = EventStream.periodic(1000)
  private val random = Random.alphanumeric.take(8).mkString

  private val icon = clazz match {
    case "success" => "check-circle"
    case "danger"  => "exclamation-circle"
    case _         => "info-circle"
  }

  def render: ReactiveElement.Base =
    div(
      idAttr      := s"toast-$random",
      cls         := s"toast border-0 text-bg-$clazz",
      role        := "alert",
      aria.live   := "assertive",
      aria.atomic := true,
      div(
        cls   := s"toast-header",
        strong(cls               := "me-auto", i(cls := s"bi-$icon"), nbsp, title),
        small(child.text <-- $tick.startWith(0).map(t => s"$t seconds ago")),
        button(
          typ                    := "button",
          cls                    := "btn-close",
          dataAttr("bs-dismiss") := "toast",
          aria.label             := "Close"
        )
      ),
      div(cls := "toast-body", foreignHtmlElement(span, DomApi.unsafeParseHtmlString(s"<span>$message</span>")))
    )

}

object Toast {

  val observer: Observer[Toast] = Observer[Toast] { t =>
    dom.document.getElementsByClassName("toast-container").toList.flatMap {
      case tc: dom.html.Div => foreignHtmlElement(div, tc).some
      case _                => None
    }.foreach { tc: ReactiveHtmlElement.Base =>
      val el    = t.render
      tc.amend(el)
      val toast = new BToast(s"#${el.ref.id}")
      toast.show()
    }
  }

}
